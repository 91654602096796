import React from "react";
// import banner from "../../assets/technologies/rpa/rpa-page-banner.png";
import banner from "../../assets/domain/healthCareImg.webp";
import TechnologyBanner from "../../components/technologies/TechnologyBanner";
import ProcessComponent2 from "../../components/technologies/ProcessComponent2";
import process_image from "../../assets/domain/healthCareImg2.webp";
import { Helmet } from "react-helmet-async";

const HealthCare = () => {
  const process = [
    {
      title: "Improving Patient Outcomes:",
      description:
        "<b>Data analytics </b> enables healthcare providers to derive insights from vast amounts of patient data. By analyzing this data, healthcare professionals can identify patterns, trends, and correlations that help in early disease detection, personalized treatment plans, and predicting patient outcomes. For example, predictive analytics models can assess patient risks and suggest proactive interventions, thereby improving overall patient care and outcomes.",
    },
    {
      title: "Optimizing Hospital Operations:",
      description:
        "Hospitals and healthcare facilities generate massive amounts of operational data daily, including patient flow, resource utilization, and staff efficiency. <b>Data analytics</b> helps in optimizing these operations by identifying inefficiencies, streamlining workflows, and allocating resources more effectively. This optimization leads to reduced wait times, improved bed management, and enhanced operational efficiency, ultimately benefiting both patients and healthcare providers.",
    },
    {
      title: "Enhancing Decision-Making Processes:",
      description:
        "In healthcare, decisions often have significant consequences for patient health and safety. <b>Data analytics</b> empowers healthcare professionals with evidence-based insights to make informed decisions. Whether it's clinical decisions based on treatment effectiveness data or strategic decisions related to resource allocation and budget planning, analytics-driven insights enable healthcare leaders to navigate complexities and achieve better outcomes.",
    },
    {
      title: "Advancing Research and Development:",
      description:
        "Research in healthcare relies heavily on data-driven insights to drive innovations and discoveries. <b>Data analytics</b> facilitates large-scale analysis of clinical trials, genomic data, and population health trends. This capability accelerates research processes, identifies new drug targets, enhances understanding of disease mechanisms, and supports the development of personalized medicine approaches tailored to individual patient profiles.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | Health Care</title>
      </Helmet>
      <TechnologyBanner
        image={banner}
        alt={"healthcare-img"}
        title={"Healthcare"}
        description={
          "Boost operational efficiency and simplify healthcare processes to deliver exceptional patient care."
        }
      />

      <ProcessComponent2
        title={"The Transformative Power of Data Analytics in Healthcare"}
        image={process_image}
        alt={"healthcare-img2"}
        process={process}
        style={{ maxWidth: '100%', height: 'auto' }}
      />
      {/* <div className="flex items-center justify-center w-full mx-auto mt-2 p-4">
        <p className="text-center">
          Leverage our expertise in banking system integration to transform your
          financial operations. Contact our team of experienced system
          integrators today to discover how our innovative solutions can enhance
          efficiency and drive growth for your institution
        </p>
      </div> */}
    </>
  );
};

export default HealthCare;
