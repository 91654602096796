import {
    HiOutlineViewGrid,
    HiOutlineCube,
    HiOutlineQuestionMarkCircle,
    HiOutlineCog,
} from 'react-icons/hi';

export const DASHBOARD_SIDEBAR_LINKS = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/admin/dashboard',
        icons: <HiOutlineViewGrid />
    },
    {
        key: 'applications',
        label: 'Applications',
        path: '/admin/applications',
        icons: <HiOutlineCube />
    },
    {
        key: 'assigned',
        label: 'Assigned Application',
        path: '/admin/assigned',
        icons: <HiOutlineViewGrid />
    },
    {
        key: 'Blogs and Images',
        label: 'Blogs and Images',
        path: '/admin/blogs',
        icons: <HiOutlineCube />
    },
]
export const DASHBOARD_MARKETING_SIDEBAR_LINKS = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/admin/dashboard',
        icons: <HiOutlineViewGrid />
    },
    {
        key: 'Blogs and Images',
        label: 'Blogs and Images',
        path: '/admin/blogs',
        icons: <HiOutlineCube />
    },
]
export const DASHBOARD_RECRUITER_SIDEBAR_LINKS = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/admin/dashboard',
        icons: <HiOutlineViewGrid />
    },
    {
        key: 'applications',
        label: 'Applications',
        path: '/admin/applications',
        icons: <HiOutlineCube />
    },
    {
        key: 'assigned',
        label: 'Assigned Application',
        path: '/admin/assigned',
        icons: <HiOutlineViewGrid />
    },
    {
        key: 'Blogs and Images',
        label: 'Blogs and Images',
        path: '/admin/blogs',
        icons: <HiOutlineCube />
    },
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    {
        key: 'settings',
        label: 'Settings',
        path: '/settings',
        icons: <HiOutlineCog />
    },
    {
        key: 'support',
        label: 'Help & Support',
        path: '/support',
        icons: <HiOutlineQuestionMarkCircle />
    },
]