import React from "react";
import TechnologyBanner from "../../components/technologies/TechnologyBanner";
import banner from "../../assets/technologies/rpa/rpa-page-banner.png";
import ProcessComponent2 from "../../components/technologies/ProcessComponent2";
import img3 from "../../assets/technologies/rpa-healthcare.png";
import imgbanner from "../../assets/technologies/data-analytics-page-banner.jpg";
import { Helmet } from "react-helmet-async";

const DataAnalytics = () => {
  const process = [
    {
      title: "Assessment",
      description:
        "In the <b>Assessment</b> phase, we evaluate your current data landscape and identify opportunities for improvement. Our experts analyze your existing data systems and infrastructure, focusing on how <b>Data Analytics</b> and <b>Big Data Analytics</b> can be leveraged to enhance your business processes. We delve into <b>Data Mining</b> techniques to uncover hidden patterns and relationships within your data, laying the groundwork for advanced <b>Machine Learning</b> models.",
    },
    {
      title: "Planning",
      description:
        "The <b>Planning</b> stage involves designing a comprehensive strategy to meet your business objectives through <b>Data Analytics</b>. We create a roadmap that integrates <b>Big Data Analytics</b> and <b>Data Mining</b> with <b>Machine Learning</b> and <b>Data Visualization</b> tools. Our detailed plan encompasses <b>Descriptive Analytics</b> to summarize historical data and provides insights into past performance, ensuring all analytical needs are addressed systematically.",
    },
    {
      title: "Implementation",
      description:
        "During the <b>Implementation</b> phase, we put the plan into action. Our team deploys <b>Data Analytics</b> solutions, including <b>Big Data Analytics</b> platforms and <b>Data Mining</b> algorithms. We develop and integrate <b>Machine Learning</b> models tailored to your specific requirements. Our robust <b>Data Visualization</b> tools transform raw data into intuitive, visual formats, making complex data more accessible and understandable. This phase also leverages <b>Descriptive Analytics</b> to offer a clear picture of trends and patterns from historical data.",
    },
    {
      title: "Monitoring",
      description:
        "The <b>Monitoring</b> stage ensures that your <b>Data Analytics</b> solutions are performing optimally. We continuously track the performance of <b>Big Data Analytics</b> systems, <b>Data Mining</b> processes, and <b>Machine Learning</b> models. Real-time <b>Data Visualization</b> dashboards provide ongoing insights, allowing for quick adjustments and refinements. <b>Descriptive Analytics</b> tools help in generating periodic reports to keep stakeholders informed about the progress and impact of implemented strategies.",
    },
    {
      title: "Training",
      description:
        "Finally, the <b>Training</b> phase focuses on empowering your team to effectively utilize <b>Data Analytics</b> tools. We provide comprehensive training sessions on <b>Big Data Analytics, Data Mining</b> and <b>Machine Learning</b>. Our hands-on training includes the use of advanced <b>Data Visualization</b> platforms and the application of <b>Descriptive Analytics</b> techniques. By enhancing your team's skills, we ensure that they can independently manage and extract insights from your data, driving sustained business growth.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | Data Analytics</title>
      </Helmet>
      <TechnologyBanner
        image={imgbanner}
        alt={"Cyber Security"}
        title={"Data Analytics"}
        description={
          "Transform raw data into actionable insights, empowering your organization to make informed decisions and drive business growth"
        }
      />
      <div>
        <ProcessComponent2
          title={"Transforming Data into Insights"}
          image={img3}
          process={process}
        />
      </div>
      <div className="flex items-center justify-center w-full mx-auto mt-3 p-4">
        <p className="text-center">
          By integrating <b>Data Analytics, Big Data Analytics, Data Mining,
          Machine Learning, Data Visualization,</b> and <b>Descriptive Analytics</b>, we
          transform your data into actionable insights, empowering your business
          to make informed decisions and achieve its strategic goals.
        </p>
      </div>
    </>
  );
};

export default DataAnalytics;