import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet, HelmetProvider } from "react-helmet-async";
import banner from "../../assets/about/advisoryimage.jpeg";
import about_image from "../../assets/about/about-image-1.png";
import { submitSubscriptionForm } from "../../services/operations/formAPI";
import Banner from "../common/Banner";
import Spinner from "../common/Spinner";

const Advisory = () => {
  const { name } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Reset the form after submission
  const resetForm = () => {
    setEmail("");
  };

  // Validate the form data
  const validateForm = () => {
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle form submission for newsletter
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = { email: email };
      setLoading(true);
      const result = await submitSubscriptionForm(formData);
      if (result) {
        resetForm();
      }
      setLoading(false);
    }
  };

  // Fetch advisory board member data by name from URL
  const fetchAdvisoryBoardByName = async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/advisoryBoard/${name}`
      );
      const boardData = response.data.data[0];
      if (!boardData) {
        navigate("/404"); 
      } else {
        setData(boardData);
      }
    } catch (error) {
      navigate("/404"); 
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchAdvisoryBoardByName();
  }, [name]);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>
            Mittal Software Labs | Advisory Board Member - {name}
          </title>
        </Helmet>
      </HelmetProvider>

      <Banner title={"Advisory Board"} banner={banner} />

      {loader ? (
        <Spinner />
      ) : (
        <div className="w-full max-w-7xl mx-auto justify-center px-4 sm:px-6 lg:px-8">
          <div className="w-full p-5">
            {data && (
              <div className="w-full lg:p-4 p-2 border border-gray-300 rounded-lg hover:shadow-xl cursor-pointer mb-4 overflow-hidden">
                <img
                  className="md:w-[30%] w-[80%] mx-5 lg:mx-20 my-5 lg:ml-40 h-[100%] lg:w-[25%] lg:h-auto object-cover rounded-lg float-left"
                  src={`${process.env.REACT_APP_BASE_URL}/uploads/images/${data.image_path}`}
                  alt={data.name}
                />
                <div className="w-full lg:w-full p-5 lg:mx-10 lg:pr-20">
                  <p className="lg:text-4xl md:block text-2xl font-bold">
                    {data.name}
                  </p>
                  <p className="lg:text-lg text-sm leading-7 mt-4 transition-all duration-200 px-4 py-2">
                    {data.long_description}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Join Our MSL Team Section */}
      <div className="bg-primary text-white mt-16 mb-4">
        <div className="w-11/12 mx-auto flex flex-col md:flex-row-reverse md:gap-16 justify-between items-center md:p-16 py-8">
          <div className="w-[40%] hidden md:block">
            <img src={about_image} alt="about_image" />
          </div>
          <div className="flex flex-col gap-8 md:w-[45%]">
            <h1 className="text-2xl lg:text-5xl font-bold text-center md:text-left">
              Join Our MSL Team
            </h1>
            <p className="md:text-lg text-center md:text-left">
              The company was started 6 years ago by providing RPA solutions to
              optimize business efficiency and maintenance services for web
              design and app development services. The company's timely delivery
              of solutions, and meticulous detailing caught the eye of our
              current overseas customer.
            </p>
            <div>
              <Link to="#">
                <div
                  className={`text-center text-[13px] sm:text-[16px] px-6 py-3 rounded-md font-bold shadow-[2px_2px_0px_0px_rgba(255,255,255,0.18)] hover:shadow-none hover:scale-95 transition-all duration-200 bg-white text-primary`}
                >
                  Apply Now
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Newsletter Subscription Section */}
      <div className="w-11/12 mx-auto py-12">
        <div className="flex flex-col md:gap-4 justify-center items-center">
          <h1 className="text-[1.4rem] md:text-[2.4rem] lg:text-[3.2rem] font-bold text-center">
            Subscribe to Our Newsletter
          </h1>
          <p className="md:text-xl text-center mb-4">
            Stay up to date with our latest news and products
          </p>

          <form onSubmit={handleSubmit}>
            <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
              <div className="relative w-full">
                <label
                  htmlFor="email"
                  className="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Email address
                </label>
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                  </svg>
                </div>
                <input
                  className="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary focus:border-primary"
                  placeholder="Enter your email"
                  type="email"
                  id="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <span className="text-red-500">{errors.email}</span>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-primary border-primary sm:rounded-none sm:rounded-r-lg hover:bg-primary focus:ring-4 focus:ring-primary"
                >
                  Subscribe
                </button>
              </div>
            </div>
            <div className="mx-auto max-w-screen-sm text-sm text-left text-gray-500">
              We care about the protection of your data.{" "}
              <a
                href="#"
                className=" cursor-pointer font-medium text-primary hover:underline"
              >
                Read our Privacy Policy
              </a>
              .
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Advisory;
