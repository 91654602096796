import React from "react";
import logo from "../../assets/logo/msl_logo.jpg";

const LeavePolicy = () => {
  return (
    <div className="container mx-auto px-14 py-14 max-w-screen-md border-4 border-blue-500">
      {/* Logo in the top-right corner */}
      <img
        src={logo}
        alt="Logo"
        className="absolute top-8 right-40 w-30 h-20"
      />

      {/* Center only the main heading */}
      <div className="text-center mt-16 mb-8">
        <h1 className="text-3xl font-bold pb-1  border-gray-300">
          Attendance & Leave Policy
        </h1>
      </div>

      {/* Align all other headings and content to the left with spaces on the side */}
      <div className="text-left font-san">
        <h2 className="text-2xl font-semibold mb-2">Objective</h2>
        <p className="mb-2">
          This policy aims to ensure all employees have a work-life balance and
          ensure business continuity and client delivery. Every employee/Manager
          can plan her/his deliverable based on the employee resource outage.
        </p>

        <h2 className="text-2xl font-semibold mb-2 ">Scope</h2>
        <p className="mb-2">
          This policy is applicable to all permanent active employees of the
          Organization.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">Purpose</h2>
        <p className="mb-4">
          The purpose of the policy is to ensure employees plan and keep a track
          of their leaves. Employees can have a balanced work-life and at the
          same time ensure that client delivery is not hampered.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">Responsibility</h2>
        <ul className="list-disc pl-5 mb-4">
          <li>Human Resource</li>
          <li>
            Monitoring of any policy violation and intimating
            Supervisors/Managers for appropriate action.
          </li>
          <li>
            Ensure that all leaves are applied and approved as per the leave
            policy guidelines.
          </li>
          <li>Escalation of any violation to HRD for appropriate action.</li>
          <li>
            Involve respective supervisors/managers for discussion on any
            violation of the policy and issue a warning as found appropriate.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          Personal Particulars
        </h2>
        <p className="mb-4">
          You will keep us informed of any change in your residential address,
          your family status, or any other relevant particulars. You should also
          let us know the name and address of your legal heir/nominee.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">Nature of Work</h2>
        <p className="mb-4">
          You will work with a high standard of initiative, creativeness,
          efficiency, and excellence in the organization. The nature of work and
          responsibilities will be assigned and explained to you by your senior
          from time to time.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">Working Hours</h2>
        <p className="mb-4">
          We encourage employees to have a better work-life balance and feel
          comfortable and happy so that they can give their best at work. The
          regular working hours of the company are from 10:00 AM to 7:00 PM - 5
          days. However, employees can see their needs due to business
          requirements during weekends and national holidays (Personal and
          Professional) and decide their working hours with the help of their
          immediate reporting manager.
        </p>

        <h2 className="text-2xl font-semibold mb-2 pb-1">Policy Guidelines</h2>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            All employees are eligible for employees are entitled to 6 paid
            leave days per year. These days are credited at a rate of 0.5 days
            per month. Unused PL can be carried forward up to a maximum of 25
            days.
          </li>
          <li className="mb-2">
            The company observes 2 optional holidays each year. The specific
            holidays will be communicated at the beginning of each year.
          </li>
          <li className="mb-2">
            The Indian Maternity Benefit Act 1961 states that Female Employee
            can take 6 months, or 26 weeks off, with every subsequent child, the
            mother can avail 3 months, or 12 weeks, off for her maternity leave,
            which is a paid leave
          </li>
          <li className="mb-2">
            Male employees are entitled to 5 days of paid paternity leave upon
            the birth or adoption of a child. Paternity Leave must be availed
            within 2 months after the birth/adoption of the child.
          </li>
          <li className="mb-2">
            Employees are entitled to 12 paid sick leave days per year (1 Sick
            Leave will be added per calendar month). A medical certificate is
            required if the sick leave exceeds 2 consecutive days.
          </li>
          <li className="mb-">
            Unplanned Leaves upporting documents need to be shared with the HR
            department.
          </li>
          <li className="mb-2">
            All leave would be granted monthly from the date of joining on a
            pro-rata basis.
          </li>
          <li className="mb-2">
            All leaves must be approved by the internal stakeholders and client
            stakeholders.
          </li>
          <li className="mb-">
            Leaves records will be maintained by the HR Team.
          </li>
          <li className="mb-2">
            All employees who are working at client premises and working from
            home must update their daily attendance login and log out
          </li>
          <li className="mb-2">
            Unused leaves accrued until the end of the current financial year
            would be carried forward for the next financial year. Strictly make
            a note that only one year carry forward of any unused leaves from
            earlier years would be accepted rest would elapse and no deviation
            in the process would be allowed without any failure.
          </li>
          <li className="mb-">
            All unused leaves can be encashed at the time of Exit in your Full &
            Final settlement (subject to clear exit) only for confirmed
            employees. It’s not applicable for employees who are on a probation
            period.
          </li>
          <li className="mb-2">
            Employee leaves must be approved by both stakeholders internal and
            external for those who are deputed at the client location; failure
            to adhere to the same would result in the leave being considered a
            Loss of Pay (LOP). Any leave availed more than the leave balance in
            a particular month would be considered a loss of pay.
          </li>
          <li className="mb-2">
            Attendance approval should take place on weekly basis by the project
            Lead or Reporting Manager
          </li>
          <li className="mb-2">
            Nonadherence to the above-defined policies would call for
            disciplinary action to be taken on the employee to the level of
            termination from service.
          </li>
          <li className="mb-2">
            All furloughs defined by the client would be considered as leave and
            adjusted against the employee’s leave balance; insufficiency of
            leaves during the furlough would lead to the leave being considered
            as leave with loss of pay.
          </li>
          <li className="mb-2">
            Employees serving their notice period are not encouraged to avail of
            any leave to ensure uninterrupted handover and knowledge transfer.
          </li>
          <li className="mb-2">
            Official travel during an employee's normal working hours, whether
            on regular workdays or regular days off, shall be counted as hours
            worked. Time spent traveling outside of the employee's normal
            working hours is not considered work time.
          </li>
          <li className="mb-2">
            Consult the reporting manager and HR for attendance and leave if
            there are any questions about which hours must be counted as work
            time during travel.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          Leave Approval Process
        </h2>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            Leave should be planned well in advance for both the categories of
            employees working at client premises and working from home so that
            it does not affect day-to-day work. However, prior <b>E-mail</b>{" "}
            approvals from Reporting Manager & HR are required before proceeding
            on leave.
          </li>
          <li className="mb-2">
            All leaves should have the PRIOR APPROVAL /post ratification of the
            HR Department. Any leave taken without <b>PRIOR APPROVAL</b> or is
            not regularized by the HR Department; post facto would be treated as{" "}
            <b>“absence without leave”</b> and be processed on a{" "}
            <b>“loss of pay”</b> basis.
          </li>
          <li className="mb-2">
            Absence for a continuous period without prior
            approval/sanction/information of the reporting manager and HR
            Department (including overstay on leave) for a period of 7 days or
            more, may result in immediate termination of your services.
          </li>
          <li className="mb-2">
            Any exceptional leave request needs to be approved by the Directors,
            Reporting Manager & HR over an email.
          </li>
          <li className="mb-2">
            <p className="font-bold">Extension of Leave: </p>
            <span>
              Any extension of Leave should be informed well before the expiry
              of the already sanctioned leave and such extension is valid only
              if such extension is approved by the reporting manager and the HR
              Department.
            </span>
          </li>
          <li className="mb-2">
            We observe the leave year pattern of the Calendar year i.e.,{" "}
            <b>January - December</b>.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-1 pb-1">Note</h2>
        <p className="mb-4">
          All Leave transitions must be approved by the manager and shared with
          the HR team. Any leaves of more than 3 days at a stretch would have to
          be applied for and approved by the manager before the leaves are
          taken. Apart from the leave granted, all employees would be titled to
          holidays as defined by the deputed company every financial year. The
          holiday list would be shared with all employees. Any changes in the
          holiday list and leave policy would be at the discretion of the
          management and would be reviewed from time to time.
        </p>
      </div>
    </div>
  );
};

export default LeavePolicy;
