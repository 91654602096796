import React from "react";
import logo from "../../assets/logo/msl_logo.jpg";

const CodeOfConduct = () => {
  return (
    <div className="container mx-auto px-14 py-14 max-w-screen-md border-4 border-blue-500">
      {/* Logo in the top-right corner */}
      <img
        src={logo}
        alt="Logo"
        className="absolute top-8 right-40 w-30 h-20"
      />

      {/* Center only the main heading */}
      <div className="text-center mt-16 mb-8">
        <h1 className="text-3xl font-bold pb-1  border-gray-300">
          Code Of Conduct
        </h1>
      </div>

      {/*Table of content*/}
      <div className="text-center mt-16 mb-8">
        <h2 className="text-3xl font-bold pb-1  border-gray-300">
          Table of Content
        </h2>
      </div>
      <ul className="pl-5 mb-4">
        <li>1. Objective</li>
        <li>2. Scope and Applicability</li>
        <li>3. Policy/Process</li>
        <ul>
          <li>3.1 National Interest</li>
          <li>3.2 Financial Reporting and Records</li>
          <li>3.3 Competition</li>
          <li>3.4 Gifts and Donations</li>
          <li>3.5 Government Agencies</li>
          <li>3.6 Political Non-alignment</li>
          <li>3.7 Health, Safety and Environment</li>
          <li>3.8 Quality of Products and Services</li>
          <li>3.9 Corporate Citizenship</li>
          <li>3.10 Public Representation of the Company</li>
          <li>3.11 Corporate Policies</li>
          <li>3.12 Shareholders</li>
          <li>3.13 Ethical Conduct</li>
          <li>3.14 Regulatory Compliance</li>
          <li>3.15 Concurrent Employment</li>
          <li>3.16 Conflict of Interest</li>
          <li>3.17 Securities Transactions and Confidential Information</li>
          <li>3.18 Confidentiality on Salary and Compensation</li>
          <li>3.19 Protecting Company Assets</li>
          <li>3.20 Citizenship</li>
          <li>3.21 Integrity of Data Furnished</li>
          <li>3.22 Reporting Concerns</li>
        </ul>
        <li>
          4. Special Circumstance and Exception / Non-compliance and Consequence
        </li>
      </ul>

      {/* Align all other headings and content to the left with spaces on the side */}
      <div className="text-left font-sans">
        <h2 className="text-2xl font-semibold mb-1 pb-1">1. Objective</h2>
        <p className="mb-4">
          Mittal Software Labs recognizes the need to establish a Code of
          Conduct policy for its employees and associates in order to define the
          way business relationships, official dealings, social behaviour and
          comradiere needs to be maintained within and outside of office
          premises.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          2. Scope Applicability
        </h2>
        <p className="mb-2">
          This policy covers all employees across levels and hierarchy in the
          organization.
        </p>
        <p className="mb-2">All Employees</p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-1">Permanent</li>
          <li className="mb-1">Part-time</li>
          <li className="mb-1">Contract</li>
          <li className="mb-1">Temporary</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4 pb-1">3. Policy/Process</h2>
        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.1</span> National Interest
        </h2>
        <p className="mb-4 pb-1">
          Mittal Software Labs and its employee will be committed in all its
          actions to benefit the economic development of our India development
          centre – Gurgaon and shall not engage in any activity that would
          adversely affect such objective. It shall not undertake any project or
          activity to the detriment of the India development centre- Gurgaon
          interests or those that will have any adverse impact on the social and
          cultural life patterns of its citizens. However, Patterns & Vendors
          doesn’t come under consideration. Mittal Software Labs shall conduct
          its business affairs in accordance with the economic development and
          foreign policies, objectives and priorities of the National or Local
          government and shall strive to make a positive contribution to the
          achievement of such goals at the international, national and regional
          level as appropriate
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.2</span>{" "}
          <span className="font-medium">Financial Reporting and Records</span>
        </h2>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            Mittal Software Labs shall prepare and maintain its accounts fairly
            and accurately in accordance with the accounting and financial
            reporting standards which represent the generally accepted
            guidelines, principles, standards, laws, and regulations of our
            India.
          </li>
          <li className="mb-2">
            Internal accounting and audit procedures shall fairly and accurately
            reflect all of the Company's business transactions and disposition
            of assets. All required information shall be accessible to company
            auditors and other authorized parties and government agencies. There
            shall be no willful omissions of any company transactions from the
            books and records, no advance income recognition, and no hidden bank
            account and funds.
          </li>
          <li className="mb-4">
            Any willful material misrepresentation of and/or misinformation on
            the financial accounts and reports shall be regarded as a violation
            of the Company’s Code of Conduct policy, leading to inviting
            appropriate civil or criminal action under the relevant laws.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.3</span>{" "}
          <span className="font-medium">Competition</span>
        </h2>
        <li className="mb-2">
          Mittal Software Labs shall fully strive for the establishment and
          support of a competitive open market economy in India and abroad and
          shall cooperate in the efforts to promote the progressive and
          judicious liberalization of trade and investment by those countries.
        </li>
        <li className="mb-2">
          Specifically, Mittal Software Labs shall not engage in activities
          which generate or support the formation of monopolies, cartels and
          similar unfair trade practices.
        </li>
        <li className="mb-4">
          Mittal Software Labs shall market its products and services on its own
          merits and shall not make unfair and misleading statements about
          competitors' products and services. Any collection of competitive
          information shall be made only in the normal course of business and
          shall be obtained only through legally permitted sources and means.
        </li>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.4</span>{" "}
          <span className="font-medium">Gifts and Donations</span>
        </h2>
        <p className="mb-4">
          Mittal Software Labs and its employees shall neither receive nor offer
          or make, directly or indirectly, any illegal payments, remuneration,
          gifts, donations or comparable benefits which are intended to or
          perceived to obtain business or uncompetitive favors for the conduct
          of its business. However, Mittal Software Labs and its employees may
          accept and offer nominal non-cash gifts, the value of which shall not
          exceed Rs.5000 per annum any such gift acceptance will also have to be
          declared to the organization, which are customarily given and are of
          commemorative nature for special events.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.5</span>{" "}
          <span className="font-medium">Government Agencies</span>
        </h2>
        <p className="mb-4">
          Mittal Software Labs and its employees shall not offer or give any
          company funds or property as donation to any government agencies or
          their representatives, directly or through intermediaries, in order to
          obtain any favourable performance of official duties.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.6</span>{" "}
          <span className="font-medium">Political Non-alignment</span>
        </h2>
        <p className="mb-4 pb-1">
          Mittal Software Labs shall be committed to and support a functioning
          democratic constitution and system with a transparent and fair
          electoral system in India. Mittal Software Labs shall not support
          directly or indirectly any specific political party or candidate for
          political office. The Company shall not offer or give any company
          funds or property as donations, directly or indirectly, to any
          specific political party, candidate or campaign.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.7</span>{" "}
          <span className="font-medium">Health, Safety and Environment</span>
        </h2>
        <p className="mb-4 pb-1 pb-1">
          Mittal Software Labs shall strive to provide a safe and healthy
          working environment and comply, in the conduct of its business
          affairs, with all regulations regarding the preservation of the
          environment of the territory it operates in.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.8</span>{" "}
          <span className="font-medium">Quality of Products and Services</span>
        </h2>
        <p className="mb-4 pb-1">
          Mittal Software Labs shall be committed to deliver services of the
          highest quality standards backed by efficient customer relationship
          management consistent with the requirements of the customers to ensure
          their total satisfaction.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.9</span>{" "}
          <span className="font-medium">Corporate Citizenship</span>
        </h2>
        <p className="mb-4 pb-1">
          Mittal Software Labs shall be committed to be a good corporate citizen
          not only in compliance with all relevant laws and regulations but also
          by actively assisting in the improvement of the quality of life of the
          people in the communities in which it operates..
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.10</span>{" "}
          <span className="font-medium">
            Public Representation of the Company
          </span>
        </h2>
        <p className="mb-4 pb-1">
          Mittal Software Labs honour’s the information requirements of the
          public and its stakeholders. In all its public appearances with
          respect to disclosing company and business information to public
          constituencies such as the media, the financial community, employees,
          and shareholders, only specifically authorized director/s and
          employees shall represent MSL Group. It will be the sole
          responsibility of these authorized representatives to disclose
          information of the Company.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.11</span>{" "}
          <span className="font-medium">Corporate Policies</span>
        </h2>
        <p className="mb-4 pb-1">
          Mittal Software Labs shall recommend to its Board of Directors the
          adoption of policies and guidelines periodically formulated.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.12</span>{" "}
          <span className="font-medium">Shareholders</span>
        </h2>
        <p className="mb-4 pb-1">
          Mittal Software Labs shall be committed to enhancing shareholder value
          and comply with all regulations and laws that govern shareholders'
          rights. The Board of Directors of Mittal Software Labs shall duly and
          fairly inform its shareholders about all relevant aspects of the
          Company's business and disclose such information in accordance with
          the respective regulations and agreements.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.13</span>{" "}
          <span className="font-medium">Ethical Conduct</span>
        </h2>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2 pb-1">
            Every employee of MSL Group, which shall include the head of the
            company, shall deal on behalf of the Company with professionalism,
            honesty, integrity as well as high moral and ethical standards. Such
            conduct shall be fair and transparent and be perceived to be as such
            by third parties.
          </li>
          <li className="mb-2 pb-1">
            Every employee shall be responsible for the implementation of and
            compliance with the Code in his professional environment. Failure to
            adhere to the Code of conduct could attract the most severe
            consequences including termination of employment.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.14</span>{" "}
          <span className="font-medium">Regulatory Compliance</span>
        </h2>
        <p className="mb-4 pb-1">
          Every employee of Mittal Software Labs shall in his business conduct,
          comply with all applicable laws and regulations, both in letter and in
          spirit, in all the territories in which it operates. If the ethical
          and professional standards set out in the applicable laws and
          regulations are below that of the Code, then the standards of the Code
          shall prevail..
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.15</span>{" "}
          <span className="font-medium">Concurrent Employment</span>
        </h2>
        <p className="mb-4 pb-1">
          An employee of Mittal Software Labs shall not, without the prior
          approval of the Board of Directors of the company, accept employment
          or a position of responsibility (such as a consultant or a director)
          with any other company, nor provide "free-lance" services to anyone.
          In the case of the head of the company, such prior approval must be
          obtained from the Board of Directors of the company.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.16</span>{" "}
          <span className="font-medium">Conflict of Interest</span>
        </h2>
        <p className="mb-2 pb-1">
          An employee of Mittal Software Labs shall not engage in any business,
          relationship, or activity, which might detrimentally conflict with the
          interest of MSL Group. A conflict of interest, actual or potential,
          may arise where directly or indirectly: (a) an employee of Mittal
          Software Labs engages in a business relationship or activity with
          anyone who is a party to a transaction with his Company, (b) an
          employee is in a position to derive a personal benefit or a benefit to
          any of his relatives by making or influencing decisions relating to
          any transaction, and (c) an independent judgment of the Company's best
          interest cannot be exercised.
        </p>
        <p className="mb-2 pb-1">
          The main areas of such actual or potential conflicts of interest would
          include the following.
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2 pb-1">
            Financial interest of an employee of Mittal Software Labs or his
            relatives including the holding of an investment in the subscribed
            share capital of any company or a share in any firm which is an
            actual or potential competitor, supplier, customer, distributor,
            joint venture, other than publicly traded or another alliance
            partner of Mittal Software Labs. .
          </li>
          <li className="mb-2 pb-1">
            An employee of Mittal Software Labs conducting business on behalf of
            the Company or being able to influence a decision about the
            Company's business with a supplier or customer of which his relative
            is a principal, officer, or representative, resulting in a benefit
            to him or his relative
          </li>
          <li className="mb-2 pb-1">
            Acceptance of gifts, donations, hospitality and/or entertainment
            beyond the customary level from existing or potential suppliers,
            customers or other third parties which have business dealings with
            the Company.
          </li>
        </ul>
        <p className="mb-4">
          Notwithstanding that such or other instances of conflict of interest
          exist due to any historical reasons, adequate and full disclosure by
          the interested employees should be made to the company's management.
          It is also incumbent upon every employee to make a full disclosure of
          any interest which the employee or the employee's immediate family,
          which would include parents, spouse and children, may have in a
          company or firm which is a supplier, customer, distributor of or has
          other business dealings with his Company.
        </p>
        <p className="mb-4">
          Every employee who is required to make a disclosure as mentioned above
          shall do so, in writing, to his immediate superior who shall forward
          the information along with his comments to the person designated for
          this purpose by the head of the company who in turn will place it
          before the head of the company and/or the Board of Directors and upon
          a decision being taken in the matter, the employee concerned will be
          required to take the necessary action as advised to resolve / avoid
          the conflict.
        </p>
        <p className="mb-4">
          If an employee fails to make a disclosure as required herein and the
          management of its own accord becomes aware of an instance of conflict
          of interest that ought to have been disclosed by the employee, the
          management will take a serious view of the matter and consider
          suitable disciplinary action against the employee, including
          termination of employment and recovery of any damages.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.17</span>{" "}
          <span className="font-medium">
            Securities Transactions and Confidential Information
          </span>
        </h2>
        <p className="mb-2 pb-2">
          An employee of Mittal Software Labs and his immediate family shall not
          derive any benefit or assist others to derive any benefit from the
          access to and possession of information about the Company, which is
          not in the public domain and thus constitutes insider information.
        </p>
        <p className="mb-2 pb-2">
          An employee of Mittal Software Labs shall not use or proliferate
          information which is not available to the investing public, and which
          therefore constitutes insider information for making or giving advice
          on investment decisions on the securities of Mittal Software Labs on
          which such insider information has been obtained.{" "}
        </p>
        <p className="mb-2 pb-1">
          Such insider information might include the following:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-1">
            Acquisition and divestiture of businesses or business units
          </li>
          <li className="mb-1">
            Financial information such as profits, earnings and dividends
          </li>
          <li className="mb-1">
            Announcement of new product introductions or developments
          </li>
          <li className="mb-1">Asset evaluations.</li>
          <li className="mb-1">Investment decisions/plan</li>
          <li className="mb-1">Restructuring plans</li>
          <li className="mb-1">Major supply and delivery agreement</li>
          <li className="mb-1">Raising finances</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.18</span>{" "}
          <span className="font-medium">
            Confidentiality on Salary and Compensation
          </span>
        </h2>
        <p className="mb-4">
          Employees of Mittal Software Labs shall not disclose their
          compensation package to anyone nor shall be permitted to discuss the
          compensation of any other employees to others. Failure to adhere to
          the Code could attract the most severe consequences including
          termination of employment
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.19</span>{" "}
          <span className="font-medium">Protecting Company Assets</span>
        </h2>
        <p className="mb-4">
          The assets of Mittal Software Labsshould not be misused but employed
          for the purpose of conducting the business for which they are duly
          authorized. These include tangible assets such as systems, facilities,
          materials, and resources as well as intangible assets such as
          proprietary information, relationships with customers and suppliers,
          etc.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.20</span>{" "}
          <span className="font-medium">Citizenship</span>
        </h2>
        <p className="mb-4">
          An employee of Mittal Software Labs shall in his private life be free
          to pursue an active role in civic or political affairs as long as it
          does not adversely affect the business or interests of the Company or
          the Group.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.21</span>{" "}
          <span className="font-medium">Integrity of Data Furnished</span>
        </h2>
        <p className="mb-2 pb-1">
          Every employee of Mittal Software Labs shall ensure at all times, the
          integrity of data or information furnished by him to the Company.
          <p className="mb-2 pb-1">
            Furnishing wrong information could attract the most severe
            consequences including termination of employment.
          </p>
        </p>
        <p className="mb-4">
          You will keep us informed of any change in your residential address,
          your family status, or any other relevant particulars. You should also
          let us know the name and address of your legal heir/nominee.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          <span className="font-medium">3.22</span>{" "}
          <span className="font-medium">Reporting Concerns</span>
        </h2>
        <p className="mb-4">
          Every employee of Mittal Software Labs shall promptly report to the
          management any actual or possible violation of the Code or an event,
          he becomes aware of that could affect the business or reputation of
          MSL Group.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          4. Special Circumstance and Exception / Non-compliance and Consequence
        </h2>
        <p className="mb-2 pb-1">
          Please find below the reasons which can lead to Termination of
          Employment. This list in non-Exhaustive
        </p>

        <ol className="pl-5 mb-4">
          <p className="font-semibold mb-2 pb-1">
            1. Gross misconduct or behaviour which may result in disrepute to
            the employer.
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li className="mb-2 pb-1">Misuse of office premises</li>
            <li className="mb-2 pb-1">
              Misuse of assets made available for work
            </li>
            <li className="mb-2 pb-1">
              Not disclosing to the management romantic relationship with a
              fellow staff member •
            </li>
            <li className="mb-2 pb-1">
              Willful insubordination or disobedience, whether alone or in
              conjunction with another or others, or of any lawful or reasonable
              order of a superior. The order of the superior should normally be
              in writing.
            </li>
            <li className="mb-2 pb-1">
              Going on illegal strike either singly or with other workers
              without giving 14 days previous notice.
            </li>
            <li className="mb-2 pb-1">
              Abetment of or attempt at abetment of any of the above acts of
              misconduct.
            </li>
            <li className="mb-2 pb-1">
              Threatening, abusing or assaulting any superior or co-worker
            </li>
            <li className="mb-2 pb-1">
              Drunkenness, fighting or riotous, disorderly or indecent
              behaviours while on duty at the place of work.
            </li>
            <li className="mb-2 pb-1">
              Causing willful damage to work in progress or to property of the
              employer.
            </li>
            <li className="mb-2 pb-1">
              Conviction in any Court of Law for any criminal offence involving
              moral turpitude
            </li>
            <li className="mb-2 pb-1">
              Betting, Gambling and Money lending business inside office
              premises
            </li>
            <li className="mb-2 pb-1">Preaching of or inciting to violence</li>
            <li className="mb-2 pb-1">
              Refusal to accept any charge-sheet or order or notice communicated
              in writing.
            </li>
          </ul>
        </ol>

        <ol className="pl-5 mb-4">
          <p className="font-semibold mb-2 pb-1">
            2. Any material or persistent breach of the terms contained in the
            contract.
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li className="mb-2 pb-1">
              Disclosing to any unauthorized person of any confidential
              information in regard to the working or process of the
              establishment which may come into the possession of the workman in
              the course of his work or even after exiting employment
            </li>
            <li className="mb-2 pb-1">
              Failure of Background check of Employment, Education
              qualifications
            </li>
            <li className="mb-2 pb-1">
              Habitual late attendance and habitual absence without leave or
              without sufficient cause.
            </li>
            <li className="mb-2 pb-1">
              Continuous absence without information and without satisfactory
              cause for more than two days as per the policy of Mittal Software
              Labs
            </li>
            <li className="mb-2 pb-1">Habitual neglect of work</li>
            <li className="mb-2 pb-1">Habitual indiscipline</li>
          </ul>
        </ol>

        <ol className="pl-5 mb-4">
          <p className="font-semibold mb-2 pb-1">
            3. Any other reasons depending on the position occupied by the
            employeet.
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li className="mb-2 pb-1">
              Failure to report any of the reasons mentioned above with respect
              to his / her reporting team members.
            </li>
          </ul>
        </ol>
      </div>
    </div>
  );
};

export default CodeOfConduct;
