import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { login, loginMicrosoft } from '../services/operations/authAPI';
import { useNavigate } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";
import Spinner from '../components/common/Spinner';

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    } else if (event.eventType === EventType.LOGIN_FAILURE) {
        console.error("Login failed:", event.error);
    }
});

async function initializeMsalInstance() {
    if (!msalInstance.getActiveAccount()) {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
        }
    }

    await msalInstance.initialize();
}

function AuthHandler() {
    const [user, setUser] = useState(null);
    const [interactionInProgress, setInteractionInProgress] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        let isMounted = true;
        (async () => {
            try {
                await initializeMsalInstance();

                if (interactionInProgress) {
                    return;
                }

                const response = await msalInstance.handleRedirectPromise();

                if (response && response.account && isMounted) {
                    setUser(response.account);
                    const account = response.account;
                    const email = account.idTokenClaims?.preferred_username;
                    dispatch(loginMicrosoft(email, navigate));
                } else if (isMounted) {
                    const account = msalInstance.getActiveAccount();
                    if (account) {
                        setUser(account);
                        const email = account.idTokenClaims?.preferred_username;
                        dispatch(loginMicrosoft(email, navigate));
                    } else {
                        setInteractionInProgress(true);
                        msalInstance.loginRedirect(loginRequest).catch(error => {
                            console.error("Redirect login failed:", error);
                            if (isMounted) setInteractionInProgress(false);
                        });
                    }
                }
            } catch (error) {
                console.error("Initialization or authentication failed:", error);
            }
        })();

        return () => {
            isMounted = false;
        };
    }, [navigate, dispatch, interactionInProgress]);

    return (
        <>
     <Spinner></Spinner>
        </>

    );
}

export default AuthHandler;
