import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null,
  user_id: localStorage.getItem("user_id")
    ? JSON.parse(localStorage.getItem("user_id"))
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setToken(state, value) {
      state.token = value.payload;
    },
    setUser(state, value) {
      state.user_id = value.payload;
    },
  },
});

export const { setLoading, setToken, setUser } = authSlice.actions;

export default authSlice.reducer;
