import { Carousel } from "flowbite-react";
import React from 'react'
import banner1 from '../../assets/home/banner/digital-transformation.jpg'
import banner2 from '../../assets/home/banner/less-time-web-banner.jpg'
import CTAButton from "../common/CTAButton";

const Caraousel = () => {
  return (
    <Carousel pauseOnHover className="w-full h-[30vh] lg:h-[60vh]">
      <div className="relative bg-cover h-full bg-center" style={{ backgroundImage: `url(${banner1})` }}>
        <div className="flex flex-col items-center justify-center bg-black h-full bg-opacity-50">
          <div className='w-11/12 mx-auto flex flex-col items-center justify-center gap-4'>
            <h3 className="text-white text-xl md:text-4xl lg:text-5xl font-bold text-center">Strategic Digital Transformation in Dynamic World</h3>
            <p className="text-white text-sm md:text-xl lg:text-2xl text-center">Empowering Protection and resilience Against Cyber Threats</p>
            <CTAButton linkto={'/contact'}>Know More</CTAButton>
          </div>
        </div>
      </div>
      <div className="relative bg-cover h-full bg-center" style={{ backgroundImage: `url(${banner2})` }}>
        <div className="flex flex-col items-center justify-center bg-black h-full bg-opacity-50">
          <div className='w-11/12 mx-auto flex flex-col items-center justify-center gap-4'>
            <h3 className="text-white text-xl md:text-4xl lg:text-5xl font-bold text-center">Less Time, More Work</h3>
            <p className="text-white text-sm md:text-xl lg:text-2xl text-center">Automate Repetitive Task and Reduce Human efforts by 40%</p>
            <CTAButton linkto={'/contact'}>Know More</CTAButton>
          </div>
        </div>
      </div>
    </Carousel>
  )
}

{/* <div className="relative bg-cover bg-center h-[30vh] lg:h-[90vh]" style={{ backgroundImage: `url(${banner})` }}>
                <div className="flex flex-col items-center justify-center bg-black h-full bg-opacity-50">
                    <div className='w-11/12 mx-auto flex flex-col items-center justify-center gap-4'>
                        <h3 className="text-white text-2xl md:text-4xl lg:text-5xl font-bold text-center">Robotic Process Automation</h3>
                        <p className="text-white lg:text-2xl text-center">We specialise in streamlining & automating repetitive tasks, freeing up valuable time for your team to focus on more strategic initiatives. Whether you're a small business looking to optimize workflows or a large enterprise aiming for digital transformation</p>
                    </div>
                </div>
            </div> */}

export default Caraousel