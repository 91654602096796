import React, { useState } from 'react'
import banner from '../assets/contact/contactus-page-banner.png'
import Banner from '../components/common/Banner';
import { submitContactForm } from '../services/operations/formAPI';
import { Helmet } from 'react-helmet-async';

const Contact = () => {

    // State variables to store form data
    const [fullName, setFullName] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const errors = {};

        if (!fullName.trim()) {
            errors.fullName = 'Full Name is required';
        }
        if (!contactNo.trim()) {
            errors.contactNo = 'Contact No is required';
        }
        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid';
        }
        if (!subject.trim()) {
            errors.subject = 'College Name is required';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const resetForm = () => {
        setFullName('');
        setContactNo('');
        setEmail('');
        setSubject('');
        setMessage('');
    }

    const handleSubmit = async (e) => {
        
        e.preventDefault();
        if (validateForm()) {
            const formData = {
                name: fullName,
                email: email,
                mobile: contactNo,
                subject: subject,
                message: message
            }

            
            // try {
            //     const response = await fetch('YOUR_API_ENDPOINT', {
            //         method: 'POST',
            //         body: formData,
            //     });
            //     if (response.ok) {
            //         
            //         resetForm();
            //     } else {
            //         console.error('Failed to submit form');
            //     }
            // } catch (error) {
            //     console.error('Error submitting form:', error);
            // } finally {
            //     resetForm();
            // }

            

            setLoading(true)
            const result = await submitContactForm(formData);
            if (result) {
                resetForm();
            }
            setLoading(false)
        }
    };

    return (
        <>
            <Helmet>
                <title>Mittal Software Labs | Contact</title>
            </Helmet>

            <Banner
                title={'Contact Us'}
                description={'We would love to hear from you'}
                banner={banner}
            />

            <div className='mx-auto w-11/12 my-16 gap-8 flex flex-col lg:flex-row justify-center'>
                <div className='flex-1'>
                    <div>
                        <h1 className='text-[1.8rem] md:text-[3.2rem] font-bold'>Request <span className='text-primary'>A Proposal?</span></h1>
                        <p className='text-lg font-bold'>Industries we have mastered</p>
                        <div className='h-1 w-14 bg-primary mt-2'></div>
                    </div>
                    <div className='my-8'>
                        <form onSubmit={handleSubmit}>
                            <div className='flex flex-col'>
                                {/* Full Name */}
                                <div className='flex flex-col gap-2'>
                                    <label htmlFor="fullName">Full Name *</label>
                                    <input type="text" id="fullName" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                                    {errors.fullName && <span className="text-red-500">{errors.fullName}</span>}
                                </div>

                                {/* Email */}
                                <div className='flex flex-col gap-2 mt-5'>
                                    <label htmlFor="email">Email Address *</label>
                                    <input type="email" id="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {errors.email && <span className="text-red-500">{errors.email}</span>}
                                </div>

                                {/* Contact No */}
                                <div className='flex flex-col gap-2 mt-5'>
                                    <label htmlFor="contactNo">Contact No *</label>
                                    <input type="number" id="contactNo" placeholder="Contact No" value={contactNo} onChange={(e) => setContactNo(e.target.value)} />
                                    {errors.contactNo && <span className="text-red-500">{errors.contactNo}</span>}
                                </div>

                                {/* Subject */}
                                <div className='flex flex-col gap-2 mt-5'>
                                    <label htmlFor="subject">Subject *</label>
                                    <input type="text" id="subject" maxLength={100} placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    {errors.subject && <span className="text-red-500">{errors.subject}</span>}
                                </div>

                                {/* Message */}
                                <div className='flex flex-col gap-2 mt-5'>
                                    <label htmlFor="message">Message</label>
                                    <textarea type="text" maxLength={200} id="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                                </div>

                                <div className='w-full flex justify-center items-center'>
                                    <button type="submit" className='mt-8 py-4 px-12 rounded-md text-lg bg-primary text-white'>
                                        {loading ? <span>Loading...</span> : <span>Submit</span>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='lg:w-[50%]'>
                    <iframe className='w-full h-full'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.936040781733!2d77.2935170754209!3d28.511571975731034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e0be04c813f%3A0x7077f30ccd13d6ba!2sMittal%20Software%20Labs%20LLP!5e0!3m2!1sen!2sin!4v1709710511497!5m2!1sen!2sin"
                        style={{ border: "0" }} allowFullScreen="" aria-hidden="false" tabIndex="0">
                    </iframe>
                </div>
            </div>
        </>
    )
}

export default Contact