import { toast } from "react-hot-toast";
import { setUser } from "../../redux/slices/authSlice";
import { apiConnector } from "../apiConnector";
import { setLoading, setToken, setRole } from "../../redux/slices/authSlice";
import { endpoints } from "../apis";
import { encryptText } from "../../utils/AESencryption";

const { LOGIN_API, LOGIN_MICROSOFT_API } = endpoints;

export function loginPassword(email, password, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const encryptedPassword = encryptText(password);

      const response = await apiConnector("POST", LOGIN_API, {
        email: email,
        password: encryptedPassword,
      });

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success("Login Successful");

      const userData = response.data.data[0];
      const userImage = userData.image
        ? userData.image
        : `https://api.dicebear.com/5.x/initials/svg?seed=${userData.firstName} ${userData.lastName}`;

      dispatch(setToken(response.data.token));
      dispatch(setUser({ ...userData, image: userImage }));

      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("user_id", userData.id);
      localStorage.setItem("role_id", JSON.stringify(userData.role));

      navigate("/admin/dashboard");
    } catch (error) {
      toast.error(`Login Failed: ${error.message}`);
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}

export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role_id");
    sessionStorage.clear();
    toast.success("Logged Out");
    navigate("/login");
  };
}

export function loginMicrosoft(email, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", LOGIN_MICROSOFT_API, {
        email,
      });

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      toast.success("Login Successful");

      const userData = response.data.data[0];
      const userImage = userData.image
        ? userData.image
        : `https://api.dicebear.com/5.x/initials/svg?seed=${userData.firstName} ${userData.lastName}`;

      dispatch(setToken(response.data.token));
      dispatch(setUser({ ...userData, image: userImage }));

      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("user_id", userData.id);
      localStorage.setItem("role_id", JSON.stringify(userData.role));

      navigate("/admin/dashboard");
    } catch (error) {
      toast.error(`Login Failed: ${error.message}`);
      // navigate("/login");
    } finally {
      dispatch(setLoading(false));
      toast.dismiss(toastId);
    }
  };
}
