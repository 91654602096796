import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NoProcessFound from "./NoProcessFound";
import {
  applicationEndPoints,
  processEndPoints,
  projectEndPoints,
  technologyEndPoints,
  transformationLeverEndPoints,
} from "../../services/apis";
import { MdEdit } from "react-icons/md";
// import MultiSelectDropdown from "../common/MultiSelectDropdown";
import ProcessModal from "./ProcessModal";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import checkRoleAccess from "../shared/CheckRoleAcess";

const ProcessDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [processData, setProcessData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [technologyData, setTechnologyData] = useState([]);
  const [transformationLeverData, setTransformationLeverDataData] = useState(
    []
  );
  const [applicationData,setApplicationData]=useState([]);
  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState({
    process_name: "",
    project_id: "",
    process_id: "",
    user_provided_process_id: "",
    process_owner: "",
    process_description: "",
    problem_statement: "",
    solution: "",
    annualBenefits: "",
    startDate: "",
    endDate: "",
    complexity: "",
    purpose: "",
    goals: "",
    risks: "",
    exceptions: "",
    application: "",
    technology_id: "",
    transformation_id: "",
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingProcess, setEditingProcess] = useState(null);

  // Optimized filtering logic
  const filteredProcesses = processData.filter((process) => {
    // const processId = process.process_id
    //   ? process.process_id.toLowerCase()
    //   : "";
    const processName = process.process_name
      ? process.process_name.toLowerCase()
      : "";
    const projectName = process.project_name
      ? process.project_name.toLowerCase()
      : "";

    return (
      // processId.includes(searchTerm.toLowerCase()) ||
      processName.includes(searchTerm.toLowerCase()) ||
      projectName.includes(searchTerm.toLowerCase())
    );
  });

  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingProcess(null);
  };

  const openAddEditModal = (process) => {
    setFormData({
      process_name: process.process_name || "",
      project_id: process.project_id || "",
      process_id: process.process_id || "",
      user_provided_process_id: process.user_provided_process_id || "",
      process_owner: process.process_owner || "",
      process_description: process.process_description || "",
      problem_statement: process.problem_statement || "",
      solution: process.solution || "",
      annualBenefits: process.annualBenefits || "",
      startDate: process.startDate || "",
      endDate: process.endDate || "",
      complexity: process.complexity || "",
      purpose: process.purpose || "",
      goals: process.goals || "",
      risks: process.risks || "",
      exceptions: process.exceptions || "",
      application: process.application || "",
      technology_id: process.technology_id || "",
      transformation_id: process.transformation_id || "",
    });
    setEditingProcess(process.process_id);
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    try {
      const payload = {
        process_name: formData.process_name,
        project_id: formData.project_id,
        user_provided_process_id: formData.user_provided_process_id,
        process_owner: formData.process_owner,
        process_description: formData.process_description,
        problem_statement: formData.problem_statement,
        solution: formData.solution,
        annualBenefits: formData.annualBenefits,
        startDate: formData.startDate,
        endDate: formData.endDate,
        complexity: formData.complexity,
        purpose: formData.purpose,
        goals: formData.goals,
        risks: formData.risks,
        exceptions: formData.exceptions,
        application: formData.application,
        technology_id: formData.technology_id,
        transformation_id: formData.transformation_id,
      };
      
      if (editingProcess) {
        await axios.post(processEndPoints.POST_UPDATE_PROCESS, {
          process_id: formData.process_id,
          ...payload,
        });
        toast.success("Process updated successfully");
      } else {
        await axios.post(processEndPoints.POST_CREATE_PROCESS, payload);
        toast.success("Process added successfully");
      }
      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      toast.error("Error processing request");
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchProcessData = async (url) => {
    try {
      const response = await axios.get(url);
      setProcessData(response.data?.data?.data || []);
      
    } catch (error) {
      console.error("Error fetching processes:", error);
    }
  };

  useEffect(() => {
    fetchProcessData(processEndPoints.GET_ALL_PROCESSES);
  }, [updateUI]);

  const useFetchData = (endpoint, setData, updateUI) => {
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(endpoint);
          setData(response.data?.data?.data || []);
          
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }, [endpoint, setData, updateUI]);
  };

  useFetchData(
    projectEndPoints.GET_ALL_ACTIVE_PROJECTS,
    setProjectData,
    updateUI
  );
  useFetchData(
    technologyEndPoints.GET_ALL_TECHNOLOGIES,
    setTechnologyData,
    updateUI
  );

  useFetchData(
    transformationLeverEndPoints.GET_ALL_ACTIVE_TRANSFORMATIONS,
    setTransformationLeverDataData,
    updateUI
  );

  useFetchData(
    applicationEndPoints.GET_ALL_ACTIVE_APPLICATIONS,
    setApplicationData,
    updateUI
  );

  console.log("===>Application",applicationData);

  const handleStatus = async (process_id, is_active) => {
    try {
      if (is_active === 1) {
        await axios.post(processEndPoints.POST_DEACTIVATE_PROCESS, {
          process_id,
        });
        toast.success("Process deactivated successfully");
      } else {
        await axios.post(processEndPoints.POST_ACTIVATE_PROCESS, {
          process_id,
        });
        toast.success("Process activated successfully");
      }
      setUpdateUI((prevState) => !prevState);
    } catch (error) {
      console.error("Error updating process status:", error);
      toast.error("Error updating process status");
    }
  };

  
  useEffect(() => {
    const role = [1, 2, 7,8];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>MSL- Processes</title>
        </Helmet>
      </HelmetProvider>
      <h1 className="text-4xl font-bold mb-4">Process Dashboard</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for a process"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a process"
        />

        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({ process_name: "" })}
            className="bg-blue-500 text-white px-8 py-2 font-bold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            aria-label="Add Process"
          >
            Add Process
          </button>
        </div>
      </div>

      {/* {filteredProcesses.length === 0 ? (
        <NoProcessFound />
      ) : (
        <div className="overflow-auto max-h-[750px]">
          <table className="w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Process Id
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Project Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Process Name
                </th>

                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Technology
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Function Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredProcesses.map((process, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {process.user_provided_process_id}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {process.project_name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {process.process_name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700"></td>
                  <td className="border border-gray-300 p-4 text-gray-700"></td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    <div className="flex gap-4">
                      <button
                        onClick={() => openAddEditModal(process)}
                        className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                      >
                        <MdEdit /> Edit
                      </button>

                      <button
                        className="text-sm px-3 py-1 rounded"
                        onClick={() =>
                          handleStatus(process.process_id, process.is_active)
                        }
                      >
                        {process.is_active === 1 ? "🟢  Active" : "🔴 Inactive"}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )} */}

      {showAddEditModal && (
        <ProcessModal
          editingProcess={editingProcess}
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleOutsideClick={handleOutsideClick}
          projectData={projectData}
          technologyData={technologyData}
          transformationLeverData={transformationLeverData}
          applicationData={applicationData}
        />
      )}
    </div>
  );
};

export default ProcessDashboard;
