import { useState } from "react";

const Dropdown = ({ handleAll, handleAssign, handleUnassign }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="bg-red-500 text-white px-4 py-2 font-bold rounded hover:bg-red-600 focus:outline-none focus:bg-red-600"
        aria-label="Toggle Dropdown"
      >
        Select Assets View
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg z-10">
          <button
            onClick={() => {
              handleAll();
              toggleDropdown();
            }}
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            aria-label="Show all Devices"
          >
            All Assets
          </button>
          <button
            onClick={() => {
              handleAssign();
              toggleDropdown();
            }}
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            aria-label="Show Assigned Devices"
          >
            Assigned Assets
          </button>
          <button
            onClick={() => {
              handleUnassign();
              toggleDropdown();
            }}
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            aria-label="Show Unassigned Devices"
          >
            Unassigned Assets
          </button>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
