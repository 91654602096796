import axios from "axios";
import React, { useState } from "react";
import {
  assetAcceptEndPoints,
  assetAssignmentEndPoints,
  assetRejectEndPoints,
} from "../../services/apis";
import toast from "react-hot-toast";

const AssetsCard = ({ asset, userId, device_serialnumber, refreshAssets }) => {
  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const fetchData = async (url, successMessage, setProcessing) => {
    try {
      setProcessing(true);

      const response = await axios.post(url, { userId, device_serialnumber });
      

      toast.success(successMessage);
      refreshAssets();
    } catch (error) {
      console.error("Error processing request:", error);
      toast.error("Failed to process the request. Please try again later.");
    } finally {
      setProcessing(false);
    }
  };

  const handleAccept = () => {
    fetchData(
      assetAcceptEndPoints.POST_ACCEPT_ASSET,
      "Asset accepted successfully!",
      setAccepting
    );
  };

  const handleReject = async () => {
    fetchData(
      assetRejectEndPoints.POST_REJECT_ASSET,
      "Asset rejected successfully!",
      setRejecting
    );

    // const response = await axios.post(
    //   assetAssignmentEndPoints.POST_UPDATE_ASIGNED_TO_UNASSIGN,
    //   {device_serialnumber}
    // );

    // 
  };

  return (
    <div className="flex justify-between bg-white shadow-md rounded-lg p-6 mb-4 transition transform hover:scale-105 hover:shadow-lg">
      <div className="flex space-x-8">
        <div>
          <div className="text-lg font-bold text-gray-700">Device Name</div>
          <p className="text-md text-gray-900">{asset.device_type || "--"}</p>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-700">Device Brand</div>
          <p className="text-md text-gray-900">{asset.device_brand || "--"}</p>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-700">Serial Number</div>
          <p className="text-md text-gray-900">
            {asset.device_serialnumber || "--"}
          </p>
        </div>
      </div>
      <div className="flex justify-end space-x-4">
        <button
          onClick={handleAccept}
          disabled={accepting || rejecting} // Disable if either action is processing
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
        >
          {accepting ? "Accepting..." : "Accept"}
        </button>
        <button
          onClick={handleReject}
          disabled={rejecting || accepting} // Disable if either action is processing
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
        >
          {rejecting ? "Rejecting..." : "Reject"}
        </button>
      </div>
    </div>
  );
};

export default AssetsCard;
