import React from "react";
import ProcessComponent2 from "../../components/technologies/ProcessComponent2";
import TechnologyBanner from "../../components/technologies/TechnologyBanner";
import process_image from "../../assets/technologies/cloud-computing-content-image.png";
import banner from "../../assets/technologies/cloud-technology-page-banner.jpg";
import { Helmet } from "react-helmet-async";

const CloudComputing = () => {
  const process = [
    {
      title: "Scalability:",
      description:
        "Embrace unparalleled <b>scalability</b> with <b>cloud computing</b>. Our cloud solutions allow businesses to effortlessly scale resources up or down based on demand. This flexibility ensures optimal performance without the need for significant capital investment in physical infrastructure. Utilizing <b>virtualization in cloud computing</b>, our services enable dynamic resource allocation and efficient workload management, making it easier for businesses to adapt to changing needs.",
    },
    {
      title: "Cost Savings",
      description:
        "Achieve substantial <b>cost savings</b> through our <b>cloud computing advantages</b>. By moving to the cloud, businesses can significantly reduce expenses related to hardware, maintenance, and energy consumption. The use of <b>hypervisor in cloud computing</b> further optimizes resource utilization, lowering operational costs. Pay-as-you-go models and reduced need for physical infrastructure translate into direct financial benefits for your organization.",
    },
    {
      title: "Enhanced Collaboration",
      description:
        "Boost productivity with <b>enhanced collaboration</b> capabilities offered by <b>applications of cloud computing</b>. Cloud-based tools and platforms facilitate real-time collaboration among team members, regardless of their geographical location. This interconnected environment supports seamless communication, file sharing, and project management, enabling teams to work together more efficiently and effectively.",
    },
    {
      title: "Improved Security and Reliability:",
      description:
        "Ensure <b>improved security and reliability</b> with <b>advanced security in cloud computing</b> measures. Our cloud solutions come with robust security protocols, including encryption, identity management, and regular security audits, to protect your sensitive data. Additionally, the inherent redundancy and disaster recovery features of cloud infrastructure guarantee higher reliability and business continuity",
    },
    {
      title: "Global Reach",
      description:
        "Expand your business footprint with the <b>global reach</b> of <b>cloud computing</b>. Cloud services offer unparalleled access to global markets, allowing businesses to deploy applications and services to customers around the world with minimal latency. The various <b>types of virtualization in cloud computing</b> support diverse deployment models, ensuring that your business can effectively cater to a worldwide audience.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | Cloud Computing</title>
      </Helmet>
      <div>
        <TechnologyBanner
          image={banner}
          alt={"Cyber Security"}
          title={"Cloud Computing"}
          description={
            "Cloud computing offers unparalleled flexibility, cost-effectiveness, and scalability, making it an indispensable tool for businesses of all sizes and industries"
          }
        />
      </div>

      <ProcessComponent2
        title={"Transformative Power of Cloud Computing"}
        image={process_image}
        alt={"cloud-computing-content-image"}
        process={process}
      />
      <div className="flex items-center justify-center w-full mx-auto mt-3 p-4">
        <p className="text-center">
          Harness the power of <b>cloud computing</b> to drive innovation and growth in
          your organization. Contact us to learn more about how our cloud
          solutions can provide you with the <b>scalability, cost savings</b> and
          <b>security</b> needed to thrive in today's competitive landscape.
        </p>
      </div>
    </>
  );
};

export default CloudComputing;