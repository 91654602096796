import React from 'react'
import ServiceCard from './ServiceCard'
import robotic_image from '../../assets/home/services/rpa.png';
import data_image from '../../assets/home/services/data-analytics.png';
import application_image from '../../assets/home/services/app-development.png';
import security_image from '../../assets/home/services/cyber-security.png';
import system_image from '../../assets/home/services/system-integration.png';
import cloud_image from '../../assets/home/services/cloud-technologies.png';

const ServicesSection = () => {
    return (
        <section className='w-11/12 mx-auto flex flex-col items-center space-y-6 py-4'>
            <div className='flex flex-col items-center space-y-2'>
                <h1 className='text-[3.2rem] font-bold text-center'>Our <span className='text-primary'>Services</span></h1>
                <p className='text-lg font-bold text-center'>Offering our expertize technologies to solve your digital challenges</p>
                <div className='h-1 w-14 bg-primary'></div>
            </div>

            <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-10 py-6 w-full'>
                <ServiceCard
                    image={robotic_image}
                    title="Robotic Process Automation"
                    description="Enables business to <b>automate repetitive tasks</b> and <b>decreases human efforts </b>."
                />
                <ServiceCard
                    image={data_image}
                    title="Data Analytics"
                    description="Improve <b>business decision-making</b> and faster <b>business growth</b> with the help of <b>raw data</b>."
                />
                <ServiceCard
                    image={application_image}
                    title="Application Development"
                    description="Get your application built on <b>disruptive technologies</b> and seize <b>maximum ROI</b>."
                />
                <ServiceCard
                    image={security_image}
                    title="Cyber Security"
                    description="We work on <b>Cybersecurity</b> to ensure your application protection from <b>cyber attacks</b>."
                />
                <ServiceCard
                    image={system_image}
                    title="System Integration"
                    description="Get your premises <b>data integrated</b> with data in the <b>cloud</b> and unlock the full potential of <b>technology ecosystem</b>."
                />
                <ServiceCard
                    image={cloud_image}
                    title="Cloud Technology"
                    description="With the flexibility of <b>cloud technology</b>, you get access to a <b>virtual office</b> according to your requirements."
                />
            </div>
        </section>
    )
}

export default ServicesSection