import React from "react";
// import banner from "../../assets/technologies/rpa/rpa-page-banner.png";
import banner from "../../assets/domain/InsuranceImg.avif";
import TechnologyBanner from "../../components/technologies/TechnologyBanner";
import ProcessComponent2 from "../../components/technologies/ProcessComponent2";
import process_image from "../../assets/domain/Designer2.png";
import { Helmet } from "react-helmet-async";

const Manufacturing = () => {
  const process = [
    {
      title: "Streamlined Production Processes:",
      description:
        "<b>RPA</b> streamlines production processes by automating routine tasks such as data entry, inventory management, and order processing. By executing these tasks swiftly and accurately round the clock, <b>RPA</b> minimizes human error and enhances overall operational <b>efficiency</b>.",
    },
    {
      title: "Enhanced Quality Control:",
      description:
        "<b>RPA</b> plays a crucial role in maintaining high standards of quality control in manufacturing. <b>Software robots</b> can perform consistent inspections and tests on products throughout the production cycle. This ensures that products meet specified standards, reducing defects and improving customer satisfaction.",
    },
    {
      title: "Optimized Inventory Management:",
      description:
        "<b>RPA</b> automates <b>inventory management</b> processes such as stock tracking, replenishment, and forecasting. By monitoring inventory levels in real time and initiating orders when supplies are low, <b>RPA</b> helps manufacturers minimize stockouts, reduce carrying costs, and improve supply chain efficiency.",
    },
    {
      title:"Accelerated Order Processing:",
      description:
      "<b>RPA</b> expedites <b>order processing</b> by automating tasks involved in order entry, verification, and fulfillment. This results in faster turnaround times, improved customer service, and increased responsiveness to market demands."
    },
    {
      title:"Cost Savings and Resource Optimization:",
      description:
      "By automating repetitive tasks, <b>RPA</b> reduces labor costs and allows human resources to focus on higher-value activities such as innovation and strategic planning. Additionally, <b>RPA</b> helps manufacturers optimize resource allocation and improve overall operational <b>efficiency</b>."
    },
    {
      title:"Scalability and Flexibility:",
      description:
      "<b>RPA</b> offers scalability to accommodate fluctuating production volumes and business growth. <b>Software robots</b> can be easily scaled up or down based on demand, ensuring manufacturing operations remain agile and responsive."
    }
  ];

  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | Manufacturing</title>
      </Helmet>
      <TechnologyBanner
        image={banner}
        alt={"insurance-img"}
        title={"Manufacturing"}
        description={
          "Manufacturing drives innovation and economic growth through diverse processes and emerging technologies like IoT and AI, enhancing productivity and sustainability globally."
        }
      />

      <ProcessComponent2
        title={"Robotic Process Automation (RPA) in Manufacturing"}
        image={process_image}
        alt={"healthcare-img2"}
        process={process}
        style={{ maxWidth: '100%', height: 'auto' }}
      />
      {/* <div className="flex items-center justify-center w-full mx-auto mt-2 p-4">
        <p className="text-center">
          Leverage our expertise in banking system integration to transform your
          financial operations. Contact our team of experienced system
          integrators today to discover how our innovative solutions can enhance
          efficiency and drive growth for your institution
        </p>
      </div> */}
    </>
  );
};

export default Manufacturing;
