import React from 'react';
import { Helmet } from 'react-helmet-async';

const StructuredData = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://www.mittalsoftwarelabs.com",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://www.mittalsoftwarelabs.com/search?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }
        `}
      </script>
    </Helmet>
  );
};

export default StructuredData;