import React from 'react'

const BlogComponent = ({ data }) => {

    return (
        <div className="w-full flex flex-col gap-6 items-center justify-center">
            <img className='w-full' src={`${process.env.REACT_APP_BASE_URL}/blogImage/${data.image}`} alt="" />
            <h1 className="font-bold text-3xl text-center">{data.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
        </div>
    )
}

export default BlogComponent