import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NoDeviceFound from "./NoDeviceFound";
import {
  assetAllocationEndPoints,
  assetAssignmentEndPoints,
  assetDeallocationEndPoints,
} from "../../services/apis";
import checkRoleAccess from "../shared/CheckRoleAcess";

const AssetsDeallocation = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [assetAssignData, setAssetAssignData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [returning, setReturning] = useState(false);
  const [cancellingAsset, setCancellingAsset] = useState(null);

  const filteredAssetAssignData = assetAssignData.filter((assetAssign) => {
    const normalizedSearchTerm = searchTerm.toLowerCase();
    return (
      (assetAssign.name || "").toLowerCase().includes(normalizedSearchTerm) ||
      (assetAssign.device_type || "")
        .toLowerCase()
        .includes(normalizedSearchTerm) ||
      (assetAssign.device_brand || "")
        .toLowerCase()
        .includes(normalizedSearchTerm) ||
      (assetAssign.device_serialnumber || "")
        .toLowerCase()
        .includes(normalizedSearchTerm)
    );
  });

  useEffect(() => {
    if (!checkRoleAccess(8)) {
      return;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const assetAssignRes = await axios.get(
          assetDeallocationEndPoints.GET_ALL_DEALLOCATE_ASSET
        );

        setAssetAssignData(assetAssignRes?.data?.data?.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        // toast.error("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchData = async (url, successMessage, setProcessing, payload) => {
    try {
      setProcessing(true);

      const response = await axios.post(url, payload);

      toast.success(successMessage);
      refreshAssets();
    } catch (error) {
      console.error("Error cancelling asset assignment:", error);
      toast.error("Failed to cancel asset assignment. Please try again later.");
    } finally {
      setProcessing(false);
      setCancellingAsset(null);
    }
  };

  const cancelAssetAssignment = async (device_serialnumber) => {
    setCancellingAsset(device_serialnumber);
    await fetchData(
      assetDeallocationEndPoints.POST_DEALLOCATE_ASSET,
      "Asset assignment cancelled successfully.",
      setReturning,
      { device_serialnumber }
    );

    const response = await axios.post(
      assetAssignmentEndPoints.POST_UPDATE_ASIGNED_TO_UNASSIGN,
      { device_serialnumber }
    );
    window.location.reload();
  };

  const refreshAssets = async () => {
    try {
      const assetAssignRes = await axios.get(
        assetAllocationEndPoints.GET_ALL_ASSIGN_ASSETS
      );
      setAssetAssignData(assetAssignRes?.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const role = [1,6];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-4">Assets De-allocation</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search"
        />
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : filteredAssetAssignData.length === 0 ? (
        <NoDeviceFound />
      ) : (
        <div className="overflow-auto max-h-[800px]">
          <table className="w-full table-auto border-collapse border-2 border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Employee Name
                </th>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Asset Type
                </th>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Asset Brand
                </th>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Asset Serial Number
                </th>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Assign Date
                </th>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredAssetAssignData.map((assetAssign, index) => (
                <tr
                  key={index}
                  className={`bg-white ${
                    index % 2 === 0 ? "bg-gray-50" : ""
                  } hover:bg-gray-200`}
                >
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {assetAssign.name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {assetAssign.device_type}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {assetAssign.device_brand}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {assetAssign.device_serialnumber}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {new Date(assetAssign.assign_date).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    <button
                      className="bg-red-500 text-white px-3 py-1 rounded"
                      aria-label={`Cancel asset assignment for ${assetAssign.name}`}
                      onClick={() =>
                        cancelAssetAssignment(assetAssign.device_serialnumber)
                      }
                      disabled={
                        cancellingAsset === assetAssign.device_serialnumber
                      }
                    >
                      {cancellingAsset === assetAssign.device_serialnumber
                        ? "Canceling..."
                        : "Cancel"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AssetsDeallocation;
