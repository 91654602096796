import React from 'react'

const ProcessComponent2 = ({ title, image, process, alt }) => {
    return (

        <div className='bg-white w-full flex flex-col justify-center items-center gap-4 lg:my-16 pb-1'>
            <h1 className='text-[1.8rem] md:text-[2.4rem] lg:text-[3.2rem] text-center font-bold text-primary'>{title}    </h1>
            <div className='flex flex-col lg:flex-row justify-between gap-8 w-11/12 mx-auto mt-8'>
                <div className='lg:w-[50%] flex justify-center items-center '>
                    <img src={image} alt={alt} className='h-full' />
                </div>
                <div className='lg:w-[50%] space-y-8 h-full flex flex-col justify-between items-center'>
                    {
                        process.map(({ title, description }, index) => {
                            return (
                                <div className='text-black space-y-1 items flex flex-col justify-between'>
                                    <h1 className='font-bold text-xl text-primary'>
                                        <span>{index + 1}. </span> 
                                        {title}
                                    </h1>
                                    <p dangerouslySetInnerHTML={{__html: description}}></p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ProcessComponent2