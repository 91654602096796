// src/StarRating.js
import React, { useState } from 'react';

const StarRating = ({ totalStars = 5 ,rating,onRate}) => {

  return (
    <div className="star-rating">
      {[...Array(totalStars)].map((star, index) => {
        const value = index + 1;
        return (
          <span
            key={index}
            className={value <= rating ? 'star filled' : 'star'}
            onClick={() => onRate(index + 1 === rating ? 0 : index + 1)}
          >
            ★
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
