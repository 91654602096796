import React from 'react';

const Unauthorized = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-red-100 text-red-700 text-center">
      <h1 className="text-6xl font-bold mb-6">403 - Unauthorized</h1>
      <p className="text-2xl mb-8">You do not have permission to view this page.</p>
     
    </div>
  );
};

export default Unauthorized;
