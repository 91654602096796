import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../components/common/Spinner';
import banner from '../assets/blogs/blog-page-banner.png'
import { IoIosSearch, IoMdBookmarks } from "react-icons/io";
import { FaArrowRightLong } from "react-icons/fa6";
import { RxCross1 } from "react-icons/rx";
import { IoPerson } from "react-icons/io5";
import { Pagination } from "flowbite-react";
import Banner from '../components/common/Banner';
import { Helmet } from 'react-helmet-async';

const host = process.env.REACT_APP_BASE_URL;

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const Blogs = () => {
    
    const [blogList, setBlogList] = useState([]);
    const [currentBlogList, setCurrentBlogList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [tagsList, setTagsList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [currentCategory, setCurrentCategory] = useState('');
    const [currentTags, setCurrentTags] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [activeBlog, setActiveBlog] = useState(false);
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [readMoreTags, setReadMoreTags] = useState(false);

    useEffect(() => {
        const fetchBlogList = async () => {
            setLoading(true);
            try {
                let response = await axios.get(`${host}/blog/getAllBlogs`);
                const sortedBlogs = response.data.data.sort((a, b) => new Date(b.blog_date) - new Date(a.blog_date));
                setBlogList(sortedBlogs);
                setCurrentBlogList(sortedBlogs.slice(0, 10));
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        const fetchCategoriesList = async () => {
            try {
                const res = await axios.get(`${host}/blog/getDropdown`);
                setCategoriesList(res.data.data.categoryMaster.map(category => category.category_name));
                setTagsList(res.data.data.tagMaster.map(tag => tag.tag_name));
            } catch (error) {
                console.error(error);
            }
        };

        fetchBlogList();
        fetchCategoriesList();
    }, []);

    useEffect(() => {
        const filteredPosts = blogList.filter(post => {
            const postTags = post.tag ? post.tag.split(',') : [];
            const tagMatch = currentTags.length === 0 || currentTags.some(tag => postTags.includes(tag));
            const categoryMatch = currentCategory === '' || post.category === currentCategory;
            return tagMatch && categoryMatch;
        });
        setCurrentBlogList(filteredPosts.slice(10 * (currentPage - 1), 10 * currentPage));
    }, [currentTags, currentCategory, currentPage, blogList]);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const handleCategoryButtonClick = e => {
        const category = e.target.innerText;
        setCurrentCategory(category === currentCategory ? '' : category);
    };

    const handleTagsButtonClick = tag => {
        setCurrentTags(currentTags.includes(tag) ? currentTags.filter(item => item !== tag) : [...currentTags, tag]);
    };

    const handleSearchButton = () => {
        if (searchInput) {
            const filteredBlogList = blogList.filter(blog => blog.title.toLowerCase().includes(searchInput.toLowerCase()));
            setCurrentBlogList(filteredBlogList);
        }
    };

    const handleCardClick = blogId => {
        setId(blogId);
        setActiveBlog(true);
        setCurrentCategory('');
        setCurrentTags([]);
    };

    const handleBackButton = () => {
        setActiveBlog(false);
    };

    const handleReadMore = () => {
        setReadMoreTags(!readMoreTags);
    };

    return (
        <div>
            <Helmet>
                <title>Mittal Software Labs | Blogs</title>
            </Helmet>

            <Banner
                title='Blogs'
                description='Explore, Evolve, Engage'
                banner={banner}
            />
            <div className="py-6 px-5 w-11/12 mx-auto">
                <div className='flex justify-between'>
                    {!activeBlog ? (
                        <div className="w-full md:w-[65%] py-8">
                            {loading ? (<Loader />) : (
                                currentBlogList.length > 0 ? (
                                    <div className="grid lg:grid-cols-2 gap-16">
                                        {currentBlogList.map((blog, index) => (
                                            <div key={index} className='cursor-pointer hover:scale-105 transition-all duration-300'>
                                                <Link to={`/blogdetail/${blog.url_key}`}>
                                                    <div className="relative overflow-hidden">
                                                        <img src={`${process.env.REACT_APP_BASE_URL}/blogImage/${blog.image}`} alt={blog.image} />
                                                    </div>
                                                    <div className="flex">
                                                        <div className="p-4 flex-shrink-0 flex flex-col justify-center text-center bg-primary text-white">
                                                            <span>{blog.blog_date ? blog.blog_date.split('-')[2].split('T')[0] : ''}</span>
                                                            <h5 className="text-uppercase m-0">{blog.blog_date ? months[parseInt(blog.blog_date.split('-')[1]) - 1] : ''}</h5>
                                                            <span>{blog.blog_date ? blog.blog_date.split('-')[0] : ''}</span>
                                                        </div>
                                                        <div className="flex bg-gray-200 flex-col justify-center p-4 flex-1">
                                                            <div className="flex flex-row justify-evenly items-start gap-2 mb-2">
                                                                <div className="text-uppercase flex justify-center items-center gap-2 flex-wrap text-center">
                                                                    <IoPerson />
                                                                    {blog.author_name}
                                                                </div>
                                                                <div className="text-uppercase flex justify-center items-center gap-2 flex-wrap text-center">
                                                                    <IoMdBookmarks />
                                                                    {blog.category}
                                                                </div>
                                                            </div>
                                                            <div className="text-xl font-bold text-center">{blog.title}</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="w-full h-[40vh] flex justify-center items-center">
                                        <div className="text-red-600 text-center">No Blogs Found</div>
                                    </div>
                                )
                            )}
                            {(currentCategory || currentTags.length > 0 || searchInput) && (
                                <div className="w-full">
                                    <div className="flex overflow-x-auto sm:justify-center">
                                        <Pagination currentPage={currentPage} totalPages={Math.ceil(blogList.length / 10)} onPageChange={onPageChange} />
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="flex-1">
                            <div>
                                <button className="btn btn-primary mt-2 mb-5 px-4 text-capitalise fw-bold fs-4" onClick={handleBackButton}>Back</button>
                            </div>
                            <div className="row g-5">
                                {/* <DetailBlog id={id} /> */}
                            </div>
                        </div>
                    )}
                    <div className="w-[25vw] py-8 border-gray-300 hidden md:block">
                        <div className="mb-10">
                            <div className="w-full relative">
                                <input value={searchInput} onChange={e => setSearchInput(e.target.value)} type="text" className="w-full p-3" placeholder="Keyword" />
                                <button onClick={handleSearchButton} className="absolute right-2 top-1/2 -translate-y-1/2 text-xl"><IoIosSearch /></button>
                            </div>
                        </div>
                        <div className="mb-10 flex gap-4 flex-col">
                            <h2 className="font-bold text-3xl">Categories</h2>
                            <div className="flex flex-col justify-start p-4 bg-gray-50">
                                {categoriesList.map(category => (
                                    <div key={category} onClick={handleCategoryButtonClick} className="flex w-full h-full py-1 justify-between items-center">
                                        <div className={`flex my-auto cursor-pointer gap-4 text-lg items-center ${currentCategory === category ? 'text-primary' : null}`}>
                                            <FaArrowRightLong />
                                            {category}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-10 flex gap-4 flex-col">
                            <h2 className="font-bold text-3xl">Recent Post</h2>
                            {
                                blogList.length > 0 ?
                                    blogList.slice(0, 3).map(blog =>
                                        <Link to={`/blogdetail/${blog.url_key}`} className="flex w-full mb-3 cursor-pointer hover:scale-105">
                                            <img src={`${process.env.REACT_APP_BASE_URL}/blogImage/${blog.image}`} width={120} height={120} className=' object-cover' alt={blog.image} />
                                            <div className="font-bold text-xl w-full px-3 py-4 flex items-center mb-0">{blog.title.length > 30 ? blog.title.slice(0, 20) + '...' : blog.title}
                                            </div>
                                        </Link>
                                    )
                                    :
                                    <div className="text-red-500 w-full text-center">No Recent Post Found</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blogs;
