import React, { useState } from 'react'
import banner from '../assets/career/contact-page-banner.jpg'
import InternForm from '../components/career/InternForm';
import JobForm from '../components/career/JobForm';
import JobListing from '../components/career/JobListing';
import Banner from '../components/common/Banner';
import { Helmet } from 'react-helmet-async';

const Career = () => {

    const [intern, setIntern] = useState(true);

    return (
        <div>
            <Helmet>
                <title>Mittal Software Labs | Career</title>
            </Helmet>
            
            <Banner 
                title={intern ? 'Internship' : 'Career'} 
                description={intern ? 'Come and Learn With Us' : 'Come and Join Us'} 
                banner={banner} 
            />

            <div className='w-11/12 mx-auto flex justify-evenly items-center my-12'>
                <button disabled={intern} onClick={() => setIntern(!intern)} className={`py-4 px-8 md:px-16 md:text-xl rounded-lg text-white font-semibold ${intern ? 'bg-gray-500' : 'bg-primary'}`}>
                    APPLY AS FRESHER / INTERNSHIP
                </button>
                <button disabled={!intern} onClick={() => setIntern(!intern)} className={`py-4 px-8 md:px-16 md:text-xl rounded-lg text-white font-semibold ${!intern ? 'bg-gray-500' : 'bg-primary'}`}>
                    APPLY FOR JOB
                </button>
            </div>

            {
                intern ?
                    <InternForm/> :
                    <div className='flex flex-col md:flex-row gap-10 w-11/12 mx-auto justify-between my-16'>
                        <JobListing />
                        <JobForm />
                    </div>

            }
        </div>
    )
}

export default Career