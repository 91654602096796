import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  projectEndPoints,
  processEndPoints,
  taskEndPoints,
  userEndPoints,
  taskAssignmentEndPoints,
  projectuserroleEndPoints,
} from "../../services/apis";
import { MdEdit } from "react-icons/md";
import NoTaskFound from "../taskMaster/NoTaskFound";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import checkRoleAccess from "../shared/CheckRoleAcess";

const TaskAssignmentDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [projectData, setProjectData] = useState([]);
  const [processData, setProcessData] = useState([]);
  const [filteredProcessData, setFilteredProcessData] = useState([]);
  const [TaskData, setTaskData] = useState([]);
  const [filteredTaskData, setFilteredTaskData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [taskAssignedData, setTaskAssignedData] = useState([]);
  const [projectUserRoleData, setProjectUserRoleData] = useState([]);
  const [filteredprojectUserRoleData, setFilteredProjectUserRoleData] =
    useState([]);

  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState({
    task_id: "",
    project_id: "",
    process_id: "",
    employee_id: "",
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [userId, setUserId] = useState("");

  // Optimized filtering logic
  const filteredTasksAssign = taskAssignedData.filter(
    (task) =>
      task.project_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.process_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.task_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingTask(null);
  };

  const openAddEditModal = (task) => {
    setFormData({
      task_id: task.task_id || "",
      project_id: task.project_id || "",
      process_id: task.process_id || "",
      employee_id: task.employee_id || "", // Initialize employee_id
    });
    setEditingTask(task.task_id ? true : false);

    if (task.project_id) {
      const filteredProcesses = processData.filter(
        (process) => process.project_id === task.project_id
      );
      setFilteredProcessData(filteredProcesses);
    }

    if (task.process_id) {
      const filteredTasks = TaskData.filter(
        (t) => t.process_id === task.process_id
      );
      setFilteredTaskData(filteredTasks);
    }

    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "project_id") {
      const filteredProcesses = processData.filter(
        (process) => process.project_id === value
      );
      setFilteredProcessData(filteredProcesses);

      let filteredUser = projectUserRoleData.filter(
        (user) => user.project_id === value
      );
      console.log("filteredUser1==>", filteredUser);

      console.log("user id is : ", userId);

      // Check if there's a user with role_id "8" and the given user_id
      const hasRole8User = filteredUser.some(
        (user) => user.role_id === "8" && user.user_id === userId
      );

      // Further filter based on role_id and user_id
      filteredUser = filteredUser.filter((user) => {
        console.log("Users", user);

        if (user.role_id === "9" && user.user_id === userId) {
          // If role_id is 9 and user_id matches, filter this user
          return true;
        }
        if (hasRole8User) {
          // If role_id is 8 and user_id matches, do not filter (include user)
          return true;
        }
        return false; // Filter out other cases
      });
      console.log("filteredUser2==>", filteredUser);

      setFilteredProjectUserRoleData(filteredUser);
    }

    if (name === "process_id") {
      const filteredTasks = TaskData.filter(
        (task) => task.process_id === value
      );
      setFilteredTaskData(filteredTasks);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingTask) {
        const response = await axios.post(
          taskAssignmentEndPoints.POST_UPDATE_TASK,
          formData
        );
        toast.success(response.data.data.messageCode);
      } else {
        const response = await axios.post(
          taskAssignmentEndPoints.POST_TASK_ASSIGN_DETAILS,
          formData
        );
        toast.success(response.data.data.messageCode);
      }
      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      toast.error(error.response.data.data.messageCode);
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(
        projectEndPoints.GET_ALL_ACTIVE_PROJECTS
      );
      setProjectData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchProcessData = async () => {
    try {
      const response = await axios.get(
        processEndPoints.GET_ALL_ACTIVE_PROCESSES
      );
      setProcessData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching processes:", error);
    }
  };

  const fetchTaskData = async () => {
    try {
      const response = await axios.get(taskEndPoints.GET_ALL_TASKS);
      setTaskData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(userEndPoints.GET_ALL_USERS);
      setEmployeeData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const fetchTaskAssignedData = async () => {
    try {
      const response = await axios.get(
        taskAssignmentEndPoints.GET_TASK_ASSIGN_ASSIGNMENT
      );
      setTaskAssignedData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const fetchProjectUserRoleData = async () => {
    try {
      const response = await axios.get(
        projectuserroleEndPoints.GET_ALL_PROJECT_USER_ROLE_DATA
      );
      setProjectUserRoleData(response.data?.data?.data || []);
      // console.log("Dataaaaaa==>", response.data?.data?.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  useEffect(() => {
    console.log("Here 1", filteredProcessData);
    console.log("Here 2", filteredprojectUserRoleData);

    console.log("processData ==>", processData);
    console.log("projectUserRoleData==>", projectUserRoleData);

    fetchProjectData();
    fetchProcessData();
    fetchTaskData();
    fetchEmployeeData();
    fetchTaskAssignedData();
    fetchProjectUserRoleData();
  }, [updateUI]);

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id") || "";
    setUserId(storedUserId);
  }, []);

  useEffect(() => {
    const role = [1, 7];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>MSL- Task Assignment</title>
        </Helmet>
      </HelmetProvider>
      <h1 className="text-4xl font-bold mb-4">Task Assignment</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for a Task Assign"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a project"
        />
        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({})} // Changed to open an empty modal
            className="bg-blue-500 text-white px-8 py-2 font-bold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            aria-label="Add Project"
          >
            Assign Task
          </button>
        </div>
      </div>

      {filteredTasksAssign.length === 0 ? (
        <NoTaskFound />
      ) : (
        <div className="overflow-auto max-h-[800px]">
          <table className="w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Project Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Process Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Task Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Employee Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredTasksAssign.map((task, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.project_name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.process_name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.task_name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    <div className="flex gap-4">
                      <button
                        onClick={() => openAddEditModal(task)}
                        className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                      >
                        <MdEdit /> Edit
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showAddEditModal && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-center m-4">
              <h1 className="text-xl text-gray-700 font-bold">
                {editingTask ? "Edit Assign Task" : "Assign Task"}
              </h1>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Project Name
                </label>
                <select
                  name="project_id"
                  value={formData.project_id}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="">Select Project</option>
                  {projectData.map((project) => (
                    <option key={project.project_id} value={project.project_id}>
                      {project.project_name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Process Name
                </label>
                <select
                  name="process_id"
                  value={formData.process_id}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="">Select Process</option>
                  {filteredProcessData.map((process) => (
                    <option key={process.process_id} value={process.process_id}>
                      {process.process_name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Task Name
                </label>
                <select
                  name="task_id"
                  value={formData.task_id}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="">Select Task</option>
                  {filteredTaskData.map((task) => (
                    <option key={task.task_id} value={task.task_id}>
                      {task.task_name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Employee Name
                </label>
                <select
                  name="employee_id"
                  value={formData.employee_id}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="">Select Employee</option>
                  {filteredprojectUserRoleData.map((employee) => (
                    <option key={employee.user_id} value={employee.user_id}>
                      {employee.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="m-20">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {editingTask ? "Update Task" : "Add Task"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskAssignmentDashboard;
