import React from "react";

const NoApplicationFound = () => {
  return (
    <div className="flex p-10 m-10 justify-center h-full ">
      <div className="text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400 "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 4a2 2 0 012-2h14a2 2 0 012 2v16a2 2 0 01-2 2H5a2 2 0 01-2-2V4z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 10h8m-8 4h8m-8 4h8"
          />
        </svg>
        <h3 className="mt-2 text-xl font-bold text-gray-900">No Data Found</h3>
      </div>
    </div>
  );
};

export default NoApplicationFound;
