import React, { useState, useEffect } from 'react'
import { Accordion } from "flowbite-react";
import axios from 'axios';
import Spinner from '../common/Spinner';

const JobListing = () => {

    const [data, setData] = useState([]);
    const [loader,setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/apply/jobOpenings`
            )
            .then(function (res) {
                setLoader(false);
                setData(res.data.data)
            })
            .catch(function (e) {
                setLoader(false);
            });
    }, [])

    return (
        <div className='md:w-[50%]'>
            <Accordion>
                {
                    loader?<Spinner></Spinner>:data.map(({ id, code, title, experience, salary, description }) => {
                        const job_title = `(Job-ID : ${code})  ${title}`;
                        return (
                            <Accordion.Panel>
                                <Accordion.Title>{job_title}</Accordion.Title>
                                <Accordion.Content>
                                    <p className="mb-2 text-gray-500 dark:text-gray-400 font-bold">
                                        {title}
                                    </p>
                                    <div className="mb-1 text-gray-500 dark:text-gray-400" dangerouslySetInnerHTML={{ __html: description }}></div>
                                    <p className='mb-1 text-gray-500 dark:text-gray-400'>
                                        <b>Experiance:</b> {experience}
                                    </p>
                                </Accordion.Content>
                            </Accordion.Panel>
                        )
                    })
                }
            </Accordion>
        </div>
    )
}

export default JobListing