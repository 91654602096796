import React from 'react'
import banner from '../../assets/technologies/rpa/rpa-page-banner.png'
import TechnologyBanner from '../../components/technologies/TechnologyBanner'
import banking_image from '../../assets/technologies/rpa/rpa-banking.png';
import healthcare_image from '../../assets/technologies/rpa/rpa-healthcare.png';
import insurance_image from '../../assets/technologies/rpa/rpa-insurance.png';
import manufacturing_image from '../../assets/technologies/rpa/rpa-manufacturing.png';
import supplyChain_image from '../../assets/technologies/rpa/rpa-supply_chain.png';
import implementation_image from '../../assets/technologies/rpa/rpa-implementation-process.png';
import ProcessComponent from '../../components/technologies/ProcessComponent';
import { Helmet } from 'react-helmet-async';

const IndustrySubSection = ({ title, image, content, flexDirectionReverse, alt }) => {
    return (
        <div className={`flex ${flexDirectionReverse ? 'flex-col-reverse md:flex-row-reverse' : 'flex-col-reverse md:flex-row'} justify-between items-center lg:w-4/5 mx-auto my-4 md:my-12 gap-4 md:gap-0`}>
            <div className='flex flex-col gap-4 md:w-[40%]'>
                <h1 className='text-2xl text-primary font-bold'>{title}</h1>
                <p dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className='md:w-[50%] h-full'>
                <img src={image} alt={alt} />
            </div>
        </div>
    )
}

const Rpa = () => {
    const techdata = [
        {
            title: "Banking and Finance",
            content: 'In the <b>banking and finance</b> sector, <b>Robotics Process Automation solutions</b> streamline operations by automating repetitive tasks such as data entry, transaction processing, and compliance reporting. <b>RPA tools</b> reduce errors and enhance efficiency, allowing financial institutions to focus on strategic initiatives. By handling <b>unstructured data</b> from various sources, RPA enables quicker and more accurate decision-making processes.',
            image: banking_image,
            alt: 'rpa_banking',
            flexDirectionReverse: false
        },
        {
            title: 'Healthcare',
            content: 'Healthcare organisations leverage robotics and automation to improve patient care and operational efficiency. <b>Automation processes</b> handle administrative tasks like <b>appointment scheduling</b>, <b>billing</b>, and <b>claims processing</b>. <b>Customer service automation</b> ensures patients receive timely responses to inquiries, enhancing overall service quality. Additionally, <b>RPA</b> can manage manual testing procedures, ensuring compliance with health regulations.',
            image: healthcare_image,
            alt: 'rpa_healthcare',
            flexDirectionReverse: true
        },
        {
            title: 'Insurance ',
            content: 'In the insurance industry, <b>Robotics Process Automation solutions</b> automate claims processing, underwriting, and policy management. By utilizing <b>RPA tools</b>, insurers can quickly process vast amounts of unstructured data, reducing the time needed for claims approval and enhancing customer satisfaction. <b>Automation processes</b> also mitigate risks and improve compliance with regulatory requirements.',
            image: insurance_image,
            alt: 'rpa_insurance',
            flexDirectionReverse: false
        },
        {
            title: 'Manufacturing',
            content: 'Manufacturing companies adopt robotics and automation to <b>optimise production lines</b>, manage inventory, and streamline supply chain operations. <b>Process automation solutions</b> reduce manual intervention, increasing accuracy and production speed. By automating <b>manual testing</b> and <b>quality checks</b>, manufacturers ensure product consistency and reduce downtime.',
            image: manufacturing_image,
            alt: 'rpa_manufacturing',
            flexDirectionReverse: true
        },
        {
            title: 'Supply Chain and Logistics:',
            content: 'In the supply chain and logistics sector, <b>RPA tools</b> automate tasks such as <b>order processing</b>, <b>shipment tracking</b>, and <b>inventory management</b>. <b>Robotics Process Automation solutions</b> enhance operational efficiency by processing unstructured data from various sources, improving decision-making and forecasting. <b>Customer service automation</b> provides real-time updates to clients, ensuring transparency and satisfaction.',
            image: supplyChain_image,
            alt: 'rpa_supply_chain',
            flexDirectionReverse: false
        }
    ];

    const process = [
        {
            title: 'Discovery',
            description: 'In the discovery phase, we identify and assess processes suitable for automation. We analyze the workflows, document requirements, and prioritize tasks based on criteria such as volume, complexity, and ROI potential.'
        },
        {
            title: 'Design',
            description: 'During the design phase, we create detailed automation workflows and develop bots tailored to your specific business needs. We define inputs, outputs, decision points, and error-handling procedures to ensure seamless automation.'
        },
        {
            title: 'UAT',
            description: 'In the UAT phase, we conduct thorough testing of the automation scripts and bots in a controlled environment. We validate results, address any issues or discrepancies, and seek feedback from users to ensure the solution meets expectations.'
        },
        {
            title: 'Hypercare',
            description: 'In During the hypercare phase, we closely monitor the performance of deployed bots in the production environment. We provide immediate support, resolve any issues or challenges, and ensure a smooth transition to full-scale production.'
        },
        {
            title: 'Production',
            description: 'Finally, in the production phase, the automated processes are fully operational and integrated into your business operations. We monitor bot performance, optimize processes, and provide ongoing support to maximize the benefits of RPA implementation.'
        },
    ]


    return (

        <div>
            <Helmet>
                <title>Mittal Software Labs | Robotic Process Automation</title>
            </Helmet>
            <TechnologyBanner
                image={banner}
                alt={"digital-transformation"}
                title={"Robotic Process Automation"}
                description={"We specialise in streamlining & automating repetitive tasks, freeing up valuable time for your team to focus on more strategic initiatives. Whether you're a small business looking to optimize workflows or a large enterprise aiming for digital transformation."}
            />

            <div className='w-11/12 mx-auto my-16 flex flex-col gap-4'>
                <h1 className='text-[1.8rem] md:text-[3.2rem] text-primary font-bold text-center'>Industries Uses RPA</h1>
                {
                    techdata.map(({ title, content, image, alt, flexDirectionReverse }, index) => {
                        return (
                            <IndustrySubSection
                                key={index}
                                title={title}
                                content={content}
                                image={image}
                                flexDirectionReverse={flexDirectionReverse}
                                alt={alt}
                            />
                        )
                    })
                }
            </div>

            <ProcessComponent
                title={'RPA Implementation Process'}
                image={implementation_image}
                process={process}
            />

        </div>
    )
}

export default Rpa;
