/**
 * Configuration object to be passed to MSAL instance on creation.
 */
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AD_CLIENT_ID,
        authority:`https://login.microsoftonline.com/${process.env.REACT_APP_AD_TENANT_ID}`,
        redirectUri: process.env.REACT_APP_AD_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_AD_POST_LOGOUT_URI,
        navigateToLoginRequestUrl: false,
        
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: ["User.read"],
};

export const silentRequest = {
    scopes: ["openid", "profile","email"],
    loginHint: "example@domain.net",
};