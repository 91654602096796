import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginMicrosoft, loginPassword } from "../services/operations/authAPI";
import logo from "../assets/logo/msl-logo.png";
import { Helmet } from "react-helmet-async";
import { handleSignInWithMicrosoft } from '../AzureAuth/MicrosoftPopup';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const { email, password } = formData;

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(loginPassword(email,password,navigate));
  };

  const handleSignInWithMicrosoftClick = async () => {
    try {      
      const account = await handleSignInWithMicrosoft();
      if (account) {
        const email = account.username; 
        dispatch(loginMicrosoft(email, navigate));
      }
    } catch (error) {
      console.error("Microsoft sign-in failed:", error);
    }
  };

  return (
    <>
      <Helmet>
                <title>Mittal Software Labs | Login</title>
            </Helmet>

      <section className="bg-gray-50">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-4xl font-semibold text-gray-900 "
          >
            <img className="w-8 h-8 mr-2" src={logo} alt="logo" />
            MSL Admin
          </a>
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Sign in to your account
              </h1>
              <form
                className="space-y-4 md:space-y-6 "
                action="post"
                onSubmit={handleOnSubmit}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                    placeholder="name@company.com"
                    required={true}
                    onChange={handleOnChange}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                  <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5" required={true} onChange={handleOnChange} />
                </div>
                {/* <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary"
                        required=""
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="remember" className="text-gray-500">
                        Remember me
                      </label>
                    </div>
                  </div>
                  <span className="text-sm font-medium text-primary hover:underline cursor-pointer">
                    Forgot password?
                  </span>
                </div> */}
                <button
                  type="submit"
                  className="w-full text-white bg-primary hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Sign in
                </button>

                <div className="flex justify-center text-sm font-medium text-gray-900">- Or -</div>

                <button
                  type="button"
                  onClick={handleSignInWithMicrosoftClick}
                  className="w-full text-primary bg-light border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Sign in with Microsoft
                </button>
{/* 
                <p className="text-sm font-light text-gray-500">
                  Don’t have an account yet?{" "}
                  <span className="font-medium text-primary hover:underline cursor-pointer">
                    Sign up
                  </span>
                </p> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
