import React from "react";
import logo from "../../assets/logo/msl_logo.jpg";

const PoshPolicy = () => {
  return (
    <div className="container mx-auto font-sans px-14 py-14 max-w-screen-md border-4 border-blue-500">
      {/* Logo in the top-right corner */}
      <img
        src={logo}
        alt="Logo"
        className="absolute top-8 right-40 w-30 h-20"
      />

      {/* Center only the main heading */}
      <div className="text-center mt-16 mb-8">
        <h1 className="text-3xl font-bold pb-1  border-gray-300">
          POLICY ON PREVENTION OF SEXUAL
        </h1>
      </div>

      {/*Table of content*/}
      <div className="text-center mt-16 mb-8">
        <h2 className="text-3xl font-bold pb-1  border-gray-300">
          HARASSMENT (POSH) AT WORKPLACE
        </h2>
      </div>

      <div className="text-center mt-16 mb-8">
        <h2 className="text-3xl font-semibold pb-1  border-gray-300">
          Table of Contents
        </h2>
      </div>

      <p className="">Version history</p>
      <ul className="pl-5 mb-4">
        <li className="mb-1">1. Definitions</li>
        <li className="mb-1">2. Prevention of Sexual Harassmen</li>
        <li className="mb-1">
          3. Constitution of the Mittal Software Labs Internal Complaints
          Committee
        </li>
        <li className="mb-1">4. Complaint of Sexual Harassment</li>
        <li className="mb-1">5. Inquiry into Complaint</li>
        <li className="mb-1">6. Action during the pendency of inquiry</li>
        <li className="mb-1">7. Inquiry report</li>
        <li className="mb-1">
          8. Punishment for false or malicious complaints and false evidence
        </li>
        <li className="mb-1">9. Determination of compensation</li>
        <li className="mb-1">
          10. Prohibition of publication or making known contents of complaint
          and inquiry proceedings
        </li>
        <li className="mb-1">
          11. Penalty for publication or making known contents of complaint and
          inquiry proceedings
        </li>
        <li className="mb-1">12. Appeal</li>
        <li className="mb-1">
          13. Duties of the Directors of the Mittal Software Labs
        </li>
        <li className="mb-1">14. Committee to submit annual report</li>
        <li className="mb-1">
          15. Directors of the Company to include information in annual report
        </li>
        <p className="mb-1">ANNEXURE I</p>
        <li className="mb-1">1. Complaint of Sexual Harassment</li>
        <li className="mb-1">2. Manner of inquiry into the Complaint</li>
        <li className="mb-1">
          3. Other relief to complainant during pendency of inquiry
        </li>
        <li className="mb-1">
          4. Manner of taking action for sexual harassment
        </li>
        <li className="mb-1">
          5. Action for false or malicious complaint or false evidence
        </li>
        <li className="mb-1">6. Preparation of Annual Report</li>
        <p className="mb-1">ANNEXURE II</p>
        <p className="mb-1">ANNEXURE III </p>
        <p className="mb-1">ANNEXURE IV</p>
      </ul>

      {/* Align all other headings and content to the left with spaces on the side */}
      <div className="text-left">
        <h2 className="text-2xl font-medium mb-2">
          MITTAL SOFTWARE LABS POLICY FOR PROTECTION AGAINST SEXUAL HARASSMENT
          AT WORKPLACE
        </h2>
        <p className="mb-3 pb-1">
          The Sexual Harassment of Women at Workplace (Prevention, Prohibition
          and Redressal) Act, 2013 (the <b>“Sexual Harassment Act”</b>) is an
          act to provide protection against sexual harassment of women at
          workplace and for the prevention and redressal of complaints of sexual
          harassment and for matters connected therewith or incidental thereto.
        </p>
        <p className="mb-4 pb-1">
          Mittal Software Labs (the <b>“Company”</b>) supports each individual
          employee’s right to work in a professional atmosphere that promotes
          equal opportunities and prohibits discriminatory practices, including
          sexual and other forms of harassment. At the Company, sexual and other
          forms of harassment, whether verbal, non-verbal or physical, is
          unacceptable and will not be tolerated. The Company has implemented
          this policy (the{" "}
          <b>“Mittal Software Labs Anti-Sexual Harassment Policy”</b>) to give
          effect to sexual harassment laws in India, including the Sexual
          Harassment Act for the protection of women, as well as extend this
          policy for the protection of men against sexual harassment at the
          Company.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">1. Definitions</h2>
        <p className="mb-2 pb-1">
          In the Mittal Software Labs Policy, unless the context otherwise
          requires, or unless otherwise defined, the following terms shall have
          the following meanings:
        </p>
        <ul className="pl-5 mb-4">
          <li className="mb-2">
            <b>(a)</b> <b>"Aggrieved Person”</b> means, in relation to the
            Company, any Personnel (whether male or female) of any age who
            alleges to have been subjected to any act of Sexual Harassment by
            any other person
          </li>
          <li className="mb-2">
            <b>(b)</b> <b>“District Officer”</b> means an officer notified under
            Section 5 of the Sexual Harassment Act
          </li>
          <li className="mb-2">
            <b>(c)</b> <b>"Personnel”</b> means a person working at any of the
            branches of the Company and includes staff, whether employed,
            working on a regular, temporary, ad hoc or daily wage basis, either
            directly or through an agent, including a contractor, whether for
            remuneration or not, or working on a voluntary basis or otherwise,
            whether the terms of employment are express or implied and includes
            a consultant, co-worker, probationer, trainee, apprentice, intern or
            any person called by any other such name, and the term{" "}
            <b>“employment”</b> shall be construed accordingly;
          </li>
          <li className="mb-2">
            <b>(d)</b> <b>“Internal Complaints Committee”</b> or{" "}
            <b>“Committee”</b> means the Internal Complaints Committee of the
            Company constituted under Section 3 of the Mittal Software Labs
            Anti- Sexual Harassment Policy.
          </li>
          <li className="mb-2">
            <b>(e)</b> <b>"Member”</b> means a Member of the Internal Complaints
            Committee
          </li>
          <li className="mb-2">
            <b>(f)</b> <b>“Prescribed”</b> means prescribed by the rules
            provided under <b>Annexure 1</b> of this Mittal Software Labs Policy
            or under the Sexual Harassment Act, as the case maybe
          </li>
          <li className="mb-2">
            <b>(g)</b> <b>“Presiding Officer”</b> means the presiding officer of
            the Internal Complaints Committee nominated under Section 3 of the
            Mittal Software Labs Policy.
          </li>
          <li className="mb-2">
            <b>(h)</b> <b>“Respondent”</b> means a person, being Personnel,
            against whom the Aggrieved Person has made a complaint under Section
            4 of the Mittal Software Labs Policy.
          </li>
          <li className="mb-2">
            <b>(i)</b> <b>“Sexual Harassment”</b> includes any one or more of
            the following unwelcome acts or behavior (whether directly or by
            implication)namely:
          </li>
        </ul>

        <p className="mb-4 pb-1 text-2xl">
          Document for Internal Circulation Only
        </p>
        <ul className="mb-2 pb-1">
          <li className="mb-2 pb-1">
            <b>(i)</b> Physical contact and advances
          </li>
          <li className="mb-2 pb-1">
            <b>(ii)</b> A demand or request for sexual favors
          </li>
          <li className="mb-2 pb-1">
            <b>(iii)</b> Making sexually coloured remarks
          </li>
          <li className="mb-2 pb-1">
            <b>(iv)</b> Showing pornography; or
          </li>
          <li className="mb-2 pb-1">
            <b>(v)</b> Any other unwelcome physical, verbal, or non-verbal
            conduct of sexual nature; and for the purposes of further clarity
            includes but is not limited to:
          </li>
          <li className="mb-2 pb-1">
            <b>(vi)</b> Sexually suggestive comments about a person's clothing,
            body, and/or sexual activities
          </li>
          <li className="mb-2 pb-1">
            <b>(vii)</b>Sexually provocative compliments about a person's
            clothes or the way their clothes fit
          </li>
          <li className="mb-2 pb-1">
            <b>(viii)</b> Comments of a sexual nature about weight, body shape,
            size, or figure; comments or questions about the sensuality of a
            person, or his or her spouse or significant other
          </li>
          <li className="mb-2 pb-1">
            <b>(ix)</b> Repeated unwanted proposals for dates and / or sexual
            linter course
          </li>
          <li className="mb-2 pb-1">
            <b>(x)</b>Continuous idle chatter of a sexual nature and graphic
            sexual descriptions; telephone calls of a sexual nature
          </li>
          <li className="mb-2 pb-1">
            <b>(xi)</b> Derogatory comments or slurs
          </li>
          <li className="mb-2 pb-1">
            <b>(xii)</b> Verbal abuse or threats, sexual jokes, suggestive or
            insulting sounds such as whistling, wolf-calls, or kissing sounds
          </li>
          <li className="mb-2 pb-1">
            <b>(xiii)</b> Sexual gestures such as provocatively licking lips or
            teeth, holding or eating food
          </li>
          <li className="mb-2 pb-1">
            <b>(xiv)</b> Lewd gestures such as hand or sign language to indicate
            sexual activity
          </li>
          <li className="mb-2 pb-1">
            <b>(xv)</b> Sexual looks such as leering and ogling with suggestive
            overtones
          </li>
          <li className="mb-2 pb-1">
            <b>(xvi)</b> Sexual innuendoes
          </li>
          <li className="mb-2 pb-1">
            <b>(xvii)</b> Cornering, impeding, or blocking movement, or any
            physical interference with normal work or movement
          </li>
          <li className="mb-2 pb-1">
            <b>(xviii)</b> Touching that is inappropriate in the workplace such
            as patting, pinching, stroking, or brushing up against the body,
            mauling, attempted or actual kissing or fondling
          </li>
          <li className="mb-2 pb-1">
            <b>(xix)</b> Assault, coerced sexual intercourse, attempted rape, or
            rape.
          </li>
          <li className="mb-2 pb-1">
            <b>(xx)</b> Gratuitous display of sexually explicit written or
            audio-visual materials like pornographic posters, cartoons,
            drawings, books, or magazines.
          </li>
          <li className="mb-2 pb-1">
            <b>(xxi)</b>Transmission or display of offensive email, screen
            savers or pornographic computer images.
          </li>
          <li className="mb-2 pb-1">
            <b>(xxii)</b> Offensive gestures or actions of a sexual nature
            including indecent exposure or “flashing”.
          </li>
          <li className="mb-2 pb-1">
            <b>(xxiii)</b> Persistent unwanted attention: following or stalking
            behavior; or
          </li>
          <li className="mb-2 pb-1">
            <b>(xxiv)</b> Un-wanted sexual advances that condition an employment
            benefit upon an exchange of sexual favors.
          </li>
        </ul>
        <p className="mb-4 pb-1">
          Further, the following circumstances, among other circumstances, if
          they occur or are present in relation to or connected with any act or
          behavior of Sexual Harassment may amount to Sexual Harassment:{" "}
        </p>
        <ul className="mb-3 pb-1">
          <li className="mb-2 pb-1">
            <b>(i)</b> Implied or explicit promise of preferential treatment in
            her or his employment
          </li>
          <li className="mb-2 pb-1">
            <b>(ii)</b> Implied or explicit threat of detriment a treatment in
            her or his employment
          </li>
          <li className="mb-2 pb-1">
            <b>(iii)</b> Implied or explicit threat about her or his present or
            future employment status
          </li>
          <li className="mb-2 pb-1">
            <b>(iv)</b> Interference with her or his work or creating an
            intimidating or offensive or hostile work environment for her or
            him; or
          </li>
          <li className="mb-2 pb-1">
            <b>(v)</b> Humiliating treatment likely to affect her or his health
            or safety.
          </li>
        </ul>
        <ul className="mb-2 pb-1">
          <p className="mb-2 pb-1">
            <b>(j)</b>
            <b>“Workplace”</b> includes:
          </p>
          <li className="mb-2 pb-1">
            <b>(i)</b>The office of the Company at New Delhi and located at 211,
            Ground Floor, Okhla Industrial Area Phase III any other branch set
            up by the Company;
          </li>
          <li className="mb-2 pb-1">
            <b>(ii)</b> Any place visited by the Personnel arising out of or
            during the course of employment including
            <li className="mb-2">
              <b>(a)</b> transportation provided by the Company for undertaking
              such journey;
            </li>
            <li className="mb-2 pb-1">
              <b>(b)</b> place of accommodation provided by the Company to the
              Personnel, if any, to facilitate the Personnel’s undertaking of
              activities related to his / her employment during such visit.
            </li>
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          2. Prevention of Sexual Harassment
        </h2>
        <p className="mb-4 pb-1">
          No Personnel shall be subjected to Sexual Harassment at any Workplace.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          3. Constitution of the Mittal Software Labs Internal Complaints
          Committee
        </h2>

        <ol className="mb-2 pb-1">
          <li className="mb-2 pb-1">
            <b>(a)</b> The Company shall always provide for the constitution of
            the Mittal Software Labs Internal Complaints Committee which shall
            supervise the provisions of the Mittal Software Labs Policy at all
            the offices of the Company.
          </li>
          <li className="mb-2 pb-1">
            <b>(b)</b> The Mittal Software Labs Internal Complaints Committee
            shall consist of the Presiding Officer and members nominated by the
            Company in accordance with the requirements prescribed under the
            Sexual Harassment Act.
          </li>
          <li className="mb-2 pb-1">
            <b>(c)</b> The Internal Complaints Committee as constituted is
            listed to Annexure III
          </li>
          <li className="mb-2 pb-1">
            <b>(d)</b> The Presiding Officer and the members of the Internal
            Complaints Committee shall hold office for a maximum period of three
            (3) years from the respective date of their nomination.
          </li>
          <li className="mb-2 pb-1">
            <b>(e)</b> Any member may resign at any time after giving the other
            members of the Internal Complaints Committee and the Directors of
            the Company a prior two (2) week’s written notice.
          </li>
          <li className="mb-2 pb-1">
            <b>(f)</b> Any replacement or addition of any member of the Internal
            Complaints Committee shall require the prior consent of the
            Director(s) of the Company.
          </li>
          <li className="mb-2 pb-2">
            <b>(g)</b> The external member(s) appointed on the Internal
            Complaints Committee shall be paid fees or allowances for attending
            any meetings of the Internal Complaints Committee as permitted by
            law.
          </li>
          <li className="mb-2 pb-2">
            <b>(h)</b> Where the Presiding Officer or any member of the Internal
            Complaints Committee:
            <ol className="mb-2 pb-1">
              <li className="mb-2 pb-1">
                <b>(i)</b> Contravenes the provisions of Section 10 of the
                Mittal Software Labs Anti-Sexual Harassment Policy;
              </li>
              <li className="mb-2 pb-1">
                <b>(ii)</b> Has been convicted for an offence or an inquiry into
                an offence under any law for the time being in force is pending
                against such person;
              </li>
              <li className="mb-2 pb-1">
                <b>(iii)</b> Has been found guilty in any disciplinary
                proceedings or a disciplinary proceeding is pending against such
                person;
              </li>
              <li className="mb-2 pb-1">
                <b>(iv)</b> Has so abused his or her position as to render his
                or her continuance in office prejudicial to the public interest
              </li>
            </ol>
          </li>
        </ol>
        <p className="mb-4 pb-1">
          Such Presiding Officer or member, as the case may be, shall be removed
          from the Internal Complaints Committee and the vacancy so created or
          any casual vacancy shall be filled by a fresh nomination by the Chief
          Executive Officer of the Company.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          4. Complaint of Sexual Harassment
        </h2>
        <ol className="mb-4 pb-2">
          <li className="mb-2 pb-1">
            Any Aggrieved Person may make, in writing, a complaint of Sexual
            Harassment at the Workplace to the Internal Complaints Committee to
            any of the members, within a period of three (3) months from the
            date of incident and incase of a series of incidents, within a
            period of three (3) Months from the date of the last incident:
          </li>
          <p className="mb-2 pb-1">
            Provided that where such complaint cannot be made in writing, the
            Presiding Officer or any member of the Internal Complaints Committee
            shall render all reasonable assistance to the Aggrieved Person for
            making the complaint in writing:
          </p>
          <p className="mb-2 pb-1">
            Provided further that the Internal Complaints Committee may, for
            reasons to be recorded in writing, extend the time limit if it is
            satisfied that the circumstances were such which prevented the
            Aggrieved
          </p>
          <p className="mb-2 pb-1">
            Provided further that the Internal Complaints Committee may, for
            reasons to be recorded in writing, extend the time limit if it is
            satisfied that the circumstances were such which prevented the
            Person from filing a complaint within such period.
          </p>
          <li className="mb-2 pb-1">
            <b>(a)</b> Where the Aggrieved Person is unable to make a complaint
            due to her or his physical or mental incapacity or death or
            otherwise, her or his legal heir or such other person as may be
            prescribed and recognized by the Internal Complaints Committee may
            make a complaint under this Section.
          </li>
          <li className="mb-2 pb-1">
            <b>(b)</b> The Internal Complaints Committee, may, before initiating
            an inquiry under Section 5 of this Mittal Software Labs Policy and
            at the request of the Aggrieved Person take steps to settle the
            matter between the Aggrieved Person and the Respondent through
            conciliation:
          </li>
          <p className="mb-2 pb-1">
            Provided that no monetary settlement shall be made as a basis of
            conciliation.``
          </p>
          <li className="mb-2 pb-1">
            <b>(c)</b> Where a settlement has been arrived at under sub-section
            (c), the Internal Complaints Committee shall record the settlement
            so arrived and forward the same to the Directors of the Company to
            take action as specified in there commendation.
          </li>
          <li className="mb-2 pb-1">
            <b>(d)</b> The Internal Complaints Committee shall provide the
            copies of the settlement as recorded under sub-section (d) above to
            the Aggrieved Person and the Respondent.
          </li>
          <li className="mb-2 pb-1">
            <b>(e)</b> Where a settlement is arrived at under sub-section (c),
            no further inquiry shall be conducted by the Internal Complaints
            Committee.
          </li>
        </ol>

        <h2 className="text-2xl font-semibold mb-4 pb-1">
          5.
          <span className="font-semibold"> Inquiry into Complaint</span>
        </h2>
        <ol className="mb-4 pb-2">
          <li className="mb-2 pb-1">
            <b>(a)</b> Subject to the provisions of Section 4 above, the
            Internal Complaints Committee shall proceed to make an inquiry into
            the complaint in such manner as may be prescribed.
          </li>
          <p className="mb-2 pb-1">
            {" "}
            Provided that where the Aggrieved Person informs the Internal
            Complaints Committee that any term or condition of any settlement
            arrived at under Sexual Harassment Act has not been complied with by
            the Respondent, the Internal Complaints Committee shall proceed to
            make an inquiry into the complaint.
          </p>
          <p className="mb-2 pb-1">
            The Aggrieved Person and the Respondent shall, during the course of
            inquiry, be given an opportunity to be heard and a copy of the
            findings of the inquiry shall be made available to both of them
            enabling them to make representation against the findings before the
            Internal Complaints Committee.
          </p>
          <li className="mb-4 pb-1">
            <b>(b)</b> For the purpose of making an inquiry under sub-section
            (a) of Section (5), the Internal Complaints Committee shall have the
            same powers as are vested in a civil court under the Code of Civil
            Procedure, 1908, as amended, when trying a suit in respect of the
            following matters, namely:
            <li className="mb-2 pb-1">
              <b>(i)</b> Summoning and enforcing the attendance of any person
              and examining him on oath
            </li>
            <li className="mb-2 pb-1">
              <b>(ii)</b> Requiring the discovery and production of documents;
            </li>
            <li className="mb-2 pb-1">
              <b>(iii)</b> Any other matter which may be prescribed under the
              Sexual Harassment Act.
            </li>
          </li>
          <li className="mb-4 pb-2">
            <b>(c)</b> The inquiry under sub-section (a) above shall be
            completed within a period of ninety (90) days.
          </li>
        </ol>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          6.
          <span className="font-semibold">
            {" "}
            Action during the pendency of inquiry
          </span>
        </h2>
        <ol className="mb-4 pb-1">
          <li className="mb-2 pb-1">
            <b>(a)</b> During the pendency of an inquiry, on a written request
            made by the Aggrieved Person, the Internal Complaints Committee may
            recommend to:
            <li className="mb-2 pb-1">
              <b>(i)</b> Transfer the Aggrieved Person or the Respondent to
              another office.
            </li>
            <li className="mb-2 pb-1">
              <b>(ii)</b> Grant leave to the Aggrieved Person upto a period of
              three (3) months; or{" "}
            </li>
            <li className="mb-2 pb-1">
              <b>(iii)</b> Grant such other relief to the Aggrieved Person as
              may be deemed fit.
            </li>
          </li>
          <li className="mb-2 pb-1">
            <b>(b)</b> The leave granted to the Aggrieved Person under this
            Section shall be in addition to the leave to which she or he would
            be otherwise entitled.
          </li>
          <li className="mb-2 pb-1">
            <b>(c)</b> On the recommendation of the Internal Complaints
            Committee made under sub-section (a) above, the Directors of the
            Company shall promptly implement such recommendations and send a
            written report of such implementation to the Internal Complaints
            Committee.
          </li>
        </ol>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          7.
          <span className="font-semibold"> Inquiry report</span>
        </h2>
        <ol className="mb-4 pb-2">
          <li className="mb-2 pb-1">
            <li className="mb-2 pb-1">
              <b>(a)</b> On the completion of the inquiry under Section 5(a),
              the Internal Complaints Committee shall provide a written report
              of its findings to the Directors of the Company, within a period
              often (10) Days from the date of completion of the inquiry and
              such report shall be made available to the concerned parties.
            </li>
            <li className="mb-2 pb-1">
              <b>(b)</b> Where the Internal Complaints Committee arrives at the
              conclusion that the allegation against the Respondent has not been
              proved, it shall recommend to the Chief Executive Officer, that no
              action is required to be taken in the matter.
            </li>
            <li className="mb-2 pb-2">
              <b>(c)</b> Where the Internal Complaints Committee arrives at the
              conclusion that the allegation against the Respondent has been
              proved, it shall recommend to the Chief Executive Officer any of
              the following:
              <li className="mb-2 pb-1">
                <b>(i)</b> To take action against the Respondent for Sexual
                Harassment as misconduct in such manner as may be prescribed.
              </li>
              <li className="mb-2 pb-1">
                <b>(ii)</b> To deduct from the compensation, salary, or wages of
                the Respondent such sum as it may consider appropriate to be
                paid to the Aggrieved Person or to her or his legal heirs, as it
                may determine, in accordance with the provisions of Section15 of
                the Sexual Harassment Act and Section 9 of the Mittal Software
                Labs Anti-Sexual Harassment Policy.
              </li>
            </li>
            <li className="mb-2 pb-1">
              <b>(d)</b> On the completion of the inquiry under Section 5(a),
              the Internal Complaints Committee shall provide a written report
              of its findings to the Directors of the Company, within a period
              often (10) Days from the date of completion of the inquiry and
              such report shall be made available to the concerned parties.
            </li>
            <li className="mb-2 pb-1">
              <b>(e)</b>Provided that in case the Chief Executive Officer is
              unable to make such deduction from the compensation, salary or
              wages of the Respondent due to his or her absence from work or
              cessation of employment or consultancy it may direct the
              Respondent to pay such sum to the person.
            </li>
            <li className="mb-2 pb-1">
              <b>(f)</b> Provided further that in case the Respondent fails to
              pay the sum referred to in sub- clause (ii), the Internal
              Complaints Committee may forward the order for recovery of the sum
              as an arrear of land revenue to the concerned District Officer.
            </li>
          </li>
          <li className="mb-2 pb-1">
            <b>(g)</b> The Chief Executive Officer shall consider the
            recommendations of the Internal Complaints Committee made under
            sub-section (c) above within sixty (60) days of its receipt and take
            such action as they may deem necessary and send a written report of
            such action to the Internal Complaints Committee.
          </li>
        </ol>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          8.
          <span className="font-semibold">
            Punishment for false or malicious complaints and false evidence
          </span>
        </h2>
        <p className="mb-2 pb-1">
          <b>(a)</b> Where the Internal Complaints Committee arrives at a
          conclusion that the allegation against the Respondent is malicious or
          the Aggrieved Person making the complaint against the Respondent has
          made the complaint knowing it to be false or the Aggrieved Person has
          produced any forged or misleading documents, it may recommend to the
          Directors of the Company to take such action against the person making
          the complaint under sub-section (a) or (b) of Section 4 of this Mittal
          Software Labs Anti-Sexual Harassment Policy, as it may deem fit.
        </p>
        <p className="mb-2 pb-1">
          Provided that a mere inability to substantiate a complaint or provide
          adequate proof need not attract action against the complainant under
          this Section.
        </p>
        <p className="mb-2 pb-2">
          Provided further that the malicious intent on part of the complainant
          shall be established after an inquiry in accordance with the procedure
          prescribed in this Mittal Software Labs Anti-Sexual Harassment Policy
          before any action is recommended.
        </p>
        <p className="mb-4 pb-2">
          <b>(b)</b> Where the Internal Complaints Committee arrives at a
          conclusion that during the inquiry any witness has given false
          evidence or produced any forged or misleading document, it may
          recommend to the Chief Executive Officer, to take such action as the
          Internal Complaints Committee may determine.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          9.
          <span className="font-semibold">Determination of compensation</span>
        </h2>
        <p className="mb-2 pb-1">
          For the purpose of determining the sums to be paid to the Aggrieved
          Person under clause (ii) of sub-section (c) of Section 7 of the Mittal
          Software Labs Anti-Sexual Harassment Policy, the Internal
        </p>
        <p className="mb-4 pb-2">Complaints Committee shall have regard to:</p>

        <ol className="mb-4 pb-2">
          <li className="mb-2 pb-1">
            <b>(i)</b> The mental trauma, pain, suffering and emotional distress
            caused to the Aggrieved Person.
          </li>
          <li className="mb-2 pb-1">
            <b>(ii)</b> The loss in the career opportunity due to the incident
            of Sexual Harassment
          </li>
          <li className="mb-2 pb-1">
            <b>(iii)</b> Medical expenses incurred by the Aggrieved Person for
            physical or psychiatric treatment.
          </li>
          <li className="mb-2 pb-1">
            <b>(iv)</b> The income and financial status of the Respondent; and
          </li>
          <li className="mb-2 pb-1">
            <b>(v)</b>Feasibility of such payment in lump sum or in
            installments.
          </li>
        </ol>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          10.
          <span className="font-semibold">
            Prohibition of publication or making known contents of complaint and
            inquiry proceedings
          </span>
        </h2>
        <p className="mb-2 pb-1">
          Not with standing anything contained in the Right to Information Act,
          2005, as amended, the contents of the complaint made under Section 4
          of the Mittal Software Labs Anti-Sexual Harassment Policy, the
          identity and addresses of the Aggrieved Person, the Respondent and
          witnesses, any information relating to conciliation and inquiry
          proceedings, recommendations of the Internal Complaints Committee, and
          the action taken by the Chief Executive Officer of the Company under
          the provisions of the Mittal Software Labs Anti-Sexual Harassment
          Policy shall not be published, communicated or made known to the
          public, press and media in any manner by any personnel.
        </p>
        <p className="mb-2 pb-1">
          Provided that information may be disseminated regarding the justice
          secured to any Aggrieved Person under the Mittal Software Labs
          Anti-Sexual Harassment Policy without disclosing the name, address,
          identity or any other particulars calculated to lead to the
          identification of the Aggrieved Person and witnesses, by authorized
          personnel as required under law.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          11.
          <span className="font-semibold">
            Penalty for publication or making known contents of complaint and
            inquiry proceedings{" "}
          </span>
        </h2>
        <p className="mb-4 pb-1">
          Where any person entrusted with the duty to handle or deal with the
          complaint, inquiry or any recommendations or action to be taken, or
          any personnel of company who has come across knowledge either in part
          or full inclusive of the Aggrieved Person and Respondent, contravenes
          the provisions of Section 10 of the Mittal Software Labs Anti-Sexual
          Harassment Policy, he or she shall be liable for penalty of Fifty
          Thousand rupees as penalty which the Chief Executive Officer shall
          recover from such person.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          12.
          <span className="font-semibold">Appeal </span>
        </h2>
        <p className="mb-3 pb-1">
          Any person aggrieved by the recommendations of the Internal Complaints
          Committee under Section 7(b) or Section 7(c) or Section 11 of the
          Mittal Software Labs Anti-Sexual Harassment Policy or
          non-implementation of such recommendations may without prejudice to
          the provisions contained in any other law for the time being in force,
          prefer an appeal to the Court or Tribunal in such manner as may be
          Prescribed and such appeal shall be preferred within a period of
          ninety (90) days of the recommendations.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          13.
          <span className="font-semibold">
            {" "}
            Duties of the Directors of the Mittal Software Labs{" "}
          </span>
        </h2>
        <ol className="mb-4 pb-2">
          <p className="mb-2 pb-1">
            <b>(a)</b> The Directors of the Company shall:
          </p>
          <ol className="mb-2 pb-1">
            <li className="mb-2 pb-1">
              <b>(i)</b> Provide a safe working environment at the Workplace
              which shall include safety (including safety from the persons
              coming into contact at the Workplace).
            </li>
            <li className="mb-2 pb-1">
              <b>(ii)</b> Notify at the Workplace the Mittal Software Labs
              Anti-Sexual Harassment Policy which includes.
            </li>
            <li className="mb-2 pb-1">
              <b>(iii)</b>Organize awareness programs at regular intervals for
              sensitizing the Personnel with the Provisions of the Sexual
              Harassment Act and organize orientation programs for the members
              of the Internal Complaints Committee in the manner as the
              Directors of the Company may deem fit
            </li>
            <li className="mb-2 pb-1">
              <b>(iv)</b> Conduct capacity building and skill building programs
              for the members of the Internal Complaints Committee
            </li>
            <li className="mb-2 pb-1">
              <b>(v)</b> Provide necessary facilities to the Internal Complaints
              Committee, for dealing with the complaint and conducting an
              inquiry
            </li>
            <li className="mb-2 pb-1">
              <b>(vi)</b> Assist in securing the attendance of the Respondent
              and witnesses before the Internal Complaints Committee
            </li>
            <li className="mb-2 pb-1">
              <b>(vii)</b> Make available such information to the Internal
              Complaints Committee as it may require
            </li>
            <li className="mb-2 pb-1">
              <b>(viii)</b>Provide assistance to the Aggrieved Person if she or
              he so chooses to file a complaint in relation to the offence under
              the Indian Penal Code or any other law for the time being in force
            </li>
            <li className="mb-2 pb-1">
              <b>(ix)</b> Cause to initiate action, under the Indian Penal Code
              or any other law for the time being in force, against the
              perpetrator, and/or if the Aggrieved Person so desires, where the
              perpetrator is not a Personnel, the organization at which the
              incident of Sexual Harassment took place or where the perpetrator
              is employed;
            </li>
            <li className="mb-2 pb-1">
              <b>(x)</b> Treat Sexual Harassment as a misconduct and initiate
              action for such misconduct; and
            </li>
            <li className="mb-2 pb-1">
              <b>(xi)</b> Monitor the timely submission of reports by the
              Internal Complaints Committee.
            </li>
          </ol>
        </ol>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          14.
          <span className="font-semibold">
            {" "}
            Committee to submit annual report
          </span>
        </h2>
        <p className="mb-2 pb-1">
          The Internal Complaints Committee shall in each calendar year prepare,
          in such form and at such time as may be prescribed, an annual report
          and submit the same to the Directors of the Company.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          15.
          <span className="font-semibold">
            {" "}
            Directors of the Company to include information in annual report
          </span>
        </h2>
        <p className="mb-4 pb-6">
          The Directors of the Company shall include the number of cases filed,
          if any, and their disposal under the Sexual Harassment Act and the
          Mittal Software Labs Anti-Sexual Harassment Policy in the annual
          report of the Company or where no such report is required to be
          prepared, inform such number of cases, if any, to the District
          Officer.{" "}
        </p>

        <div className="text-center mt-16 mb-8">
          <h2 className="text-2xl font-semibold pb-1  border-gray-300">
            ANNEXURE I
          </h2>
        </div>

        <div className="text-center mt-16 mb-8">
          <h2 className="text-2xl font-semibold pb-1  border-gray-300">
            Rules for the Implementation of the Mittal Software Labs Policy
          </h2>
        </div>

        <section className="mb-4 pb-2">
          <h2 className="text-2xl font-semibold mb-4 pb-1">
            <span className="font-medium">1. </span>{" "}
            <span className="font-medium"> Complaint of Sexual Harassment</span>
          </h2>
          <p className="mb-2 pb-1">
            For the purposes of sub-section (b) of Section 4 of the Mittal
            Software Labs Anti-Sexual Harassment Policy:
          </p>
          <p className="mb-2 pb-1">
            {" "}
            <b>(a)</b> Where the Aggrieved Person is unable to make a complaint
            on account of his or her physical incapacity, a compliant may be
            filed by:
          </p>
          <ul>
            <li className="pb-1 mb-2">
              <b>(i)</b> His / her relative or friend
            </li>
            <li className="pb-1 mb-2">
              <b>(ii)</b>His / her co-worker
            </li>
            <li className="pb-1 mb-2">
              <b>(iii)</b> Any person having knowledge of the incident, with the
              written consent of the Aggrieved Person
            </li>
          </ul>
          <p className="mb-2 pb-1">
            <b>(b)</b> Where the Aggrieved Person is dead, a complaint may be
            filed by any person who has knowledge of the incident, with the
            written consent of his / her legal heir.
          </p>
        </section>

        <section className="mb-4 pb-2">
          <h2 className="text-2xl font-semibold mb-4 pb-1">
            <span className="font-medium">1. </span>{" "}
            <span className="font-medium">
              {" "}
              Manner of inquiry into the Complaint:
            </span>
          </h2>
          <ul className="mb-4 pb-2">
            <li className="mb-2 pb-1">
              <b>(a)</b> Subject to the provisions of Section 4 of this Mittal
              Software Labs Anti-Sexual Harassment Policy, at the time of filing
              of the complaint, the complainant shall submit to the Internal
              Complaints Committee, six (6) copies of the complaint along with
              supporting documents and the names and addresses of witnesses{" "}
            </li>
            <li className="mb-2 pb-1">
              <b>(b)</b> On receipt of the complaint, the Internal Complaints
              Committee shall send one of the copies received from the Aggrieved
              Person under sub-rule (a) above to the Respondent within a period
              of seven (7) working days.{" "}
            </li>
            <li className="mb-2 pb-1">
              <b>(c)</b> The Respondent shall file his reply to the complaint
              along with his list of documents, and names and addresses of
              witnesses, within a period not exceeding ten (10) working days
              from the date of receipt of the documents specified under sub-rule
              (a).
            </li>
            <li className="mb-2 pb-1">
              <b>(d)</b> The Internal Complaints Committee shall make inquiry
              into the complaint in accordance with the principles of natural
              justice.
            </li>
            <li className="mb-2 pb-1">
              <b>(e)</b> The Internal Complaints Committee shall have the right
              to terminate the inquiry proceedings or to give an ex-parte
              decision on the complaint, if the complainant or the Respondent
              fails, without sufficient cause, to present himself/herself for
              three (3) consecutive hearings convened by the Presiding Officer.
            </li>
            <li className="mb-2 pb-1">
              <b>(f)</b> Provided that such termination or ex-parte order may
              not be passed without giving a notice in writing, fifteen (15)
              days in advance, to the party concerned.
            </li>
            <li className="mb-2 pb-1">
              <b>(g)</b> The parties shall not be allowed to bring in any legal
              practitioner to represent them in their case at any stage of the
              proceedings before the Internal Complaints Committee.
            </li>
          </ul>
          <p className="mb-2 pb-1">
            In conducting the inquiry, a minimum of three (3) members of the
            Internal Complaints Committee including the Presiding Officer shall
            be present.
          </p>
        </section>

        <section className="mb-4 pb-2">
          <h2 className="text-2xl font-semibold mb-4 pb-1">
            <span className="font-medium">3. </span>{" "}
            <span className="font-medium">
              {" "}
              Other relief to complainant during pendency of inquiry
            </span>
          </h2>
          <p className="mb-2 pb-1">
            The Internal Complaints Committee at the written request of the
            Aggrieved Person may recommend to the Chief Executive Officer to
            restrain the Respondent from reporting on the work performance of
            the Aggrieved Person or writing his / her confidential report and
            assign the same to another employee.
          </p>
        </section>

        <section className="mb-4 pb-2">
          <h2 className="text-2xl font-semibold mb-4 pb-1">
            <span className="font-medium">4. </span>{" "}
            <span className="font-medium">
              {" "}
              Manner of taking action for sexual harassment
            </span>
          </h2>
          <p className="mb-2 pb-1">
            Except in cases where this Mittal Software Labs Anti-Sexual
            Harassment Policy provides, where the Internal Complaints Committee
            arrives at the conclusion that the allegation against the Respondent
            has been proved, it shall recommend to the Chief Executive Officer,
            to take any action including a written apology, warning, reprimand
            or censure, with holding of promotion, withholding of pay rise or
            increments, terminating the Respondent from service or undergoing a
            counselling session.
          </p>
        </section>

        <section className="mb-4 pb-2">
          <h2 className="text-2xl font-semibold mb-4 pb-1">
            <span className="font-medium">5. </span>{" "}
            <span className="font-medium">
              {" "}
              Action for false or malicious complaint or false evidence
            </span>
          </h2>
          <p className="mb-2 pb-1">
            Except in cases where this Mittal Software Labs Anti-Sexual
            Harassment Policy provides, where the Internal Complaints Committee
            arrives at the conclusion that the allegation against the Respondent
            is malicious or the Aggrieved Person or any other person making the
            complaint has made the complaint knowing it to be false or the
            Aggrieved Person or any other person making the complaint has
            produced any forged or misleading document, it may recommend to the
            Chief Executive Officer to take action in accordance with the
            provisions of Rule 4 above.{" "}
          </p>
        </section>

        <section className="mb-4 pb-2">
          <h2 className="text-2xl font-semibold mb-4 pb-1">
            <span className="font-medium">6. </span>{" "}
            <span className="font-medium"> Preparation of Annual Report</span>
          </h2>
          <p className="mb-2 pb-1">
            The annual report which the Internal Complaints Committee shall
            prepare under Section 14 shall have the following details:
          </p>
          <ul className="mb-4 pb-2">
            <li className="mb-2 pb-1">
              <b>(a)</b> Number of complaints of Sexual Harassment received in
              the year
            </li>
            <li className="mb-2 pb-1">
              <b>(b)</b> Number of complaints disposed of during the year
            </li>
            <li>
              <b>(c)</b> Number of cases pending for more than ninety (90) days
            </li>
            <li className="mb-2 pb-1">
              <b>(d)</b> Number of workshops or awareness programs against
              Sexual Harassment carried out;
            </li>
            <li className="mb-2 pb-1">
              <b>(e)</b> Nature of action taken by the Company
            </li>
          </ul>
        </section>

        <div className="text-center mt-16 mb-8">
          <h2 className="text-2xl font-semibold pb-1  border-gray-300">
            <span className="">ANNEXURE II</span>
          </h2>
        </div>

        <div className="text-center mt-16 mb-8">
          <h2 className="text-2xl font-thin pb-1  border-gray-300">
            <span className="">
              Guidelines as to what constitutes Sexual Harassment under the
              Mittal Software Labs Policy{" "}
            </span>
          </h2>
        </div>
        <p className="mb-2 pb-2">
          While the below mentioned guidelines provide additional details as to
          what constitutes Sexual Harassment, all Personnel are hereby informed
          and notified that the below mentioned guidelines are subject to
          applicable laws in India (including but not limited to the Sexual
          Harassment Act):
        </p>

        <ul className="mb-4 pb-2">
          <li className="mb-2 pb-1">
            <b>(i)</b> It is to be noted by all Personnel that Sexual Harassment
            differs from healthy sexual attraction because it is unwelcome and
            unsolicited. Sexual conduct becomes unlawful only when it is
            unwelcome. The challenged conduct must be unwelcome in the sense
            that the Personnel did not solicit or incite it, and in the sense
            that the Personnel regarded the conduct as undesirable or offensive.
          </li>
          <li className="mb-2 pb-1">
            <b>(ii)</b> A Personnel who was previously involved in a mutual
            consenting intimate relationship with another person maintains his
            or her entitlement to protection from Sexual Harassment, but s/he
            should inform.
          </li>
          <li>
            <b>(iii)</b> It is impermissible to suggest, threaten or imply that
            failure to accept a request for a date or sexual. Intimacy will
            affect a Personnel’s career prospects. For example, it is forbidden
            either to imply or actually withhold support for an appointment,
            promotion or change of assignment or to offer benefits such as
            promotions, favorable performance evaluations, favorable assigned
            duties or shifts, recommendations or reclassifications in exchange
            for sexual favors.
          </li>
          <li className="mb-2 pb-1">
            <b>(iv)</b> Some forms of sexual conduct, which are considered
            innocent by some people, may be considered offensive by others. In
            addition, different social or cultural backgrounds may lead persons
            to perceive the same conduct differently. Therefore, all Personnel
            are expected to respect the sensitivities of others especially where
            there may be variations in the interpretation of acceptable behavior
            and accordingly cultural differences may not be used as an excuse or
            justification for Sexual Harassment.
          </li>
        </ul>

        <div className="text-center mt-16 mb-8">
          <h2 className="text-2xl font-semibold pb-1  border-gray-300">
            <span className="underline">ANNEXURE III</span>
          </h2>
        </div>

        <p className="mb-2 pb-1">
          In pursuance of Sexual Harassment of Women at Workplace (Prevention,
          Prohibition and Redressal) Act, 2013 Mittal Software Labs hereby
          constitutes an Internal Complaints Committee (ICC) with the following
          members:
        </p>

        <ul className="mb-8 pb-2">
          <li className="mb-2 pb-1">
            <span className="font-semibold">a. Presiding Officer</span>
            <ul className="ml-4">
              <li>
                <span className="font-semibold">Name:</span> Akanksha Garg
              </li>
              <li>
                <span className="font-semibold">Gender:</span> Female
              </li>
              <li>
                <span className="font-semibold">Email ID:</span>{" "}
                akanksha.garg@mittalsoftwarelabs.com
              </li>
              <li>
                <span className="font-semibold">Cell:</span> +91-9999901385
              </li>
            </ul>
          </li>

          <li className="mb-2 pb-1">
            <span className="font-semibold">b. Member No. 1</span>
            <ul className="ml-4">
              <li>
                <span className="font-semibold">Name:</span> Jyotsana Chaudhary
              </li>
              <li>
                <span className="font-semibold">Gender:</span> Female
              </li>
              <li>
                <span className="font-semibold">Email ID:</span>{" "}
                jyotsana@mittalsoftwarelabs.com
              </li>
              <li>
                <span className="font-semibold">Cell:</span> +91-8076130470
              </li>
            </ul>
          </li>

          <li className="mb-2 pb-1">
            <span className="font-semibold">c. Member No. 2</span>
            <ul className="ml-4">
              <li>
                <span className="font-semibold">Name:</span> Prosenjit Bose
              </li>
              <li>
                <span className="font-semibold">Gender:</span> Male
              </li>
              <li>
                <span className="font-semibold">Email ID:</span>{" "}
                Prosenjit.bose@mittalsoftwarelabs.com
              </li>
              <li>
                <span className="font-semibold">Cell:</span> +91-9674967474
              </li>
            </ul>
          </li>

          <li className="mb-2 pb-1">
            <span className="font-semibold">d. External Member - NGO</span>
            <ul className="ml-4">
              <li>
                <span className="font-semibold">Name:</span> Priyanka Arora
              </li>
              <li>
                <span className="font-semibold">Gender:</span> Female
              </li>
              <li>
                <span className="font-semibold">Email ID:</span>{" "}
                unwelfarefoundation@gmail.com
              </li>
              <li>
                <span className="font-semibold">Cell:</span> +91-9266699974
              </li>
            </ul>
          </li>

          <li className="mb-2 pb-1">
            <span className="font-semibold">e. External Member – Lawyer</span>
            <ul className="ml-4">
              <li>
                <span className="font-semibold">Name:</span> Sachin Gupta
              </li>
              <li>
                <span className="font-semibold">Gender:</span> Male
              </li>
              <li>
                <span className="font-semibold">Email ID:</span>{" "}
                Sachinetawah4@gmail.com
              </li>
              <li>
                <span className="font-semibold">Cell:</span> +91-9690966176
              </li>
            </ul>
          </li>
        </ul>

        <div className="text-center mt-20 mb-8 pb-2">
          <h2 className="text-2xl font-semibold pb-1  border-gray-300">
            <span className="underline">ANNEXURE IV</span>
          </h2>
        </div>

        <img src={logo} alt="policy" className="mx-auto" />
      </div>
    </div>
  );
};

export default PoshPolicy;
