// AzureAuth/AuthHandler.js
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);
let isLoginInProgress = false; // Flag to track login state

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    } else if (event.eventType === EventType.LOGIN_FAILURE) {
        console.error("Login failed:", event.error);
    }
});

export const initializeMsalInstance = async () => {
    try {
        await msalInstance.initialize();
    } catch (error) {
        console.error("Failed to initialize MSAL:", error);
    }
};

export const handleSignInWithMicrosoft = async () => {
    if (isLoginInProgress) {
        console.warn("Login attempt already in progress.");
        return; 
    }

    isLoginInProgress = true;

    try {
        await initializeMsalInstance(); 
        const loginResponse = await msalInstance.loginPopup(loginRequest);
        if (loginResponse && loginResponse.account) {
            msalInstance.setActiveAccount(loginResponse.account);
            return loginResponse.account; 
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    } finally {
        isLoginInProgress = false; 
    }
};

export const handleLogout = async () => {
    try {
        await msalInstance.logoutPopup();
    } catch (error) {
        console.error("Error during logout:", error);
    }
};  
