import React, { useState, useRef, useEffect } from 'react';
import { submitJobApplication } from '../../services/operations/formAPI';
import axios from 'axios';

const JobForm = () => {
    // State variables to store form data
    const [fullName, setFullName] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [email, setEmail] = useState('');
    const [jobProfile, setJobProfile] = useState('');
    const [resume, setResume] = useState(null);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const resumeInputRef = useRef(null);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/apply/getJobProfiles`
            )
            .then(function (res) {
                setProfiles(res.data.data)
            })
            .catch(function (e) {
                
            });
    }, [])

    const handleJobProfileChange = (e) => {
        setJobProfile(e.target.value);
        if (e.target.value === '') {
            setErrors({ ...errors, jobProfile: 'Job Profile is required.' });
        } else {
            setErrors({ ...errors, jobProfile: '' });
        }
    };

    const handleContactNoChange = (e) => {
        const value = e.target.value;
        if (value.length <= 10) {
            setContactNo(value);
            setErrors((prevErrors) => ({ ...prevErrors, contactNo: '' }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, contactNo: 'Contact number cannot exceed 10 digits.' }));
        }
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const errors = {};

        if (!fullName.trim()) {
            errors.fullName = 'Full Name is required';
        }
        if (!contactNo.trim()) {
            errors.contactNo = 'Contact No is required';
        }
        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid';
        }
        if (!jobProfile.trim()) {
            errors.jobProfile = 'Job Profile is required';
        }
        if (!resume) {
            errors.resume = 'Resume is required';
        } else if (resume.type !== 'application/pdf') {
            errors.resume = 'Resume must be a PDF file';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const resetForm = () => {
        setFullName('');
        setContactNo('');
        setEmail('');
        setJobProfile('');
        setResume(null);
        setMessage('');
        resumeInputRef.current.value = '';
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const formData = new FormData();
            formData.append('name', fullName);
            formData.append('mobile', contactNo);
            formData.append('email', email);
            formData.append('job_profile', jobProfile);
            formData.append('resume', resume);
            formData.append('message', message);

            

            setLoading(true)
            const result = await submitJobApplication(formData);
            if (result) {
                resetForm();
            }
            setLoading(false)
        }
    };

    return (
        <div className='md:flex-1'>
            <form onSubmit={handleSubmit}>
                <div className='flex flex-col'>
                    {/* Full Name */}
                    <div className='flex flex-col gap-2'>
                        <label htmlFor="fullName">Full Name*</label>
                        <input type="text" id="fullName" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                        {errors.fullName && <span className="text-red-500">{errors.fullName}</span>}
                    </div>

                    {/* Contact No */}
                    <div className='flex flex-col gap-2 mt-5'>
                        <label htmlFor="contactNo">Contact No*</label>
                        <input maxLength={10} type="number" id="contactNo" placeholder="Contact No" value={contactNo} onChange={handleContactNoChange} />
                        {errors.contactNo && <span className="text-red-500">{errors.contactNo}</span>}
                    </div>

                    {/* Email */}
                    <div className='flex flex-col gap-2 mt-5'>
                        <label htmlFor="email">Email Address*</label>
                        <input type="email" id="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                        {errors.email && <span className="text-red-500">{errors.email}</span>}
                    </div>

                    {/* Job Profile */}
                    <div className='flex flex-col gap-2 mt-5'>
                        <label htmlFor="jobProfile">Job Profile*</label>
                        <select id="jobProfile" value={jobProfile} onChange={handleJobProfileChange}>
                            <option value="" disabled>Select Job Profile</option>
                            {profiles.map((profile, index) => (
                                <option key={index} value={profile.code + ' ' + profile.title}>
                                    {profile.code + ' ' + profile.title}
                                </option>
                            ))}
                        </select>
                        {errors.jobProfile && <span className="text-red-500">{errors.jobProfile}</span>}
                    </div>

                    {/* Resume */}
                    <div className='flex flex-col gap-2 mt-5'>
                        <label htmlFor="resume">Resume*</label>
                        <input type="file" id="resume" ref={resumeInputRef} onChange={(e) => setResume(e.target.files[0])} />
                        {errors.resume && <span className="text-red-500">{errors.resume}</span>}
                    </div>

                    {/* Message */}
                    <div className='flex flex-col gap-2 mt-5'>
                        <label htmlFor="message">Message</label>
                        <textarea type="text" id="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                    </div>

                    <div className='w-full flex justify-center items-center'>
                        <button type="submit" disabled={loading} className='mt-8 py-4 px-12 rounded-md text-lg bg-primary text-white'>
                            {loading ? <span>Loading...</span> : <span>Submit</span>}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default JobForm;
