import React, { useEffect, useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import ReactSelect from 'react-select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import axios from 'axios'
import toast from 'react-hot-toast'
import ButtonSpinner from '../components/common/ButtonSpinner'

const tableHeading = [
    "title",
    "url_key",
    "category",
    "blog_date",
    "image"
]

const AddBlog = () => {
    const [tableData, setTableData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [addBlog, setAddBlog] = useState(false)
    const [editBlog, setEditBlog] = useState(false)
    const [modalData, setModalData] = useState({})
    const [multiSelect, setMultiSelect] = useState([])
    const [singleSelect, setSingleSelect] = useState({})
    const [singleSelectList, setSingleSelectList] = useState([])
    const [multiSelectList, setMultiSelectList] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchTableDate = async () => {
        setLoading(true);
        const bloglist = await axios.get(`${process.env.REACT_APP_BASE_URL}/blog/getBlogs`);
        // 
        setTableData(bloglist.data.data);
        setLoading(false);
    }

    const fetchMasterData = async () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/blog/getDropdown`)
            .then((res) => {
                // 
                setSingleSelectList(res.data.data['categoryMaster'].map(item => ({ value: item.category_name, label: item.category_name })))
                setMultiSelectList(res.data.data['tagMaster'].map(item => ({ value: item.tag_name, label: item.tag_name })))
            })
    }

    useEffect(() => {
        fetchTableDate()
        fetchMasterData()
    }, [])


    const fetchPreFillData = (index) => {
        // 
        const selectedTableData = tableData.find(item => item.id === index.id)
        setModalData(selectedTableData)
        setSingleSelect(selectedTableData['category'] ? { value: selectedTableData['category'], label: selectedTableData['category'] } : {})
        //  
        setMultiSelect(selectedTableData['tag']?.split(',').map(item => ({ value: item, label: item })) || [{}])
    }

    const handleEditButton = (e) => {
        setLoading(true);
        setAddBlog(false)
        setEditBlog(true);
        const index = tableData[parseInt(e.target.name)];
        // 
        fetchPreFillData(index)
        setShowModal(true)
        setLoading(false);
    }

    const handleActivate = (e) => {
        setLoading(true)
        const tempData = [...tableData];
        const index = tempData[parseInt(e.target.name)];
        // 
        axios.put(`${process.env.REACT_APP_BASE_URL}/blog/updateBlogStatus/${index.id}`, { is_active: !index.is_active })
            .then((res) => {
                toast.success('Blog Updated Successfully')
                index.is_active = !index.is_active;
                setTableData(tempData);
                setLoading(false)
            })
            .catch((err) => {
                toast.error('Something went wrong')
                setLoading(false)
            })
    }

    const handleAddBlog = () => {
        //  
        setEditBlog(false);
        setAddBlog(true)
        setShowModal(true)
        setModalData({})
        setSingleSelect({})
        setMultiSelect([])
    }

    const handleOnClose = (e) => {
        if (e.target.id === 'outside') {
            setShowModal(false)
            setAddBlog(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredFields = ['title', 'author_name', 'url_key', 'meta_title', 'meta_description', 'category', 'blog_date', 'image', 'content'];
        const emptyFields = requiredFields.filter(field => !modalData[field]);

        if (emptyFields.length > 0) {
            emptyFields.forEach(field => {
                toast.error(`Please enter ${field.split('_').join(" ")} field`);
            });
            return false;
        }

        setLoading(true);

        const formData = new FormData();
        for (const key in modalData) {
            formData.append(key, modalData[key]);
        }
        // Set isPublished based on the state
        // formData.append('isPublished', modalData.isPublished ? true : false);


        if (addBlog) {
            
            axios.post(`${process.env.REACT_APP_BASE_URL}/blog/addBlog`, formData)
                .then((res) => {
                    setLoading(false);
                    setShowModal(false);
                    toast.success('Blog Added Successfully');
                    // fetchTableDate();
                    setAddBlog(false);
                })
                .catch((err) => {
                    toast.error('Something went wrong');
                    
                    setLoading(false);
                });
        } else {
            
            axios.put(`${process.env.REACT_APP_BASE_URL}/blog/updateBlog`, formData)
                .then((res) => {
                    setLoading(false);
                    setShowModal(false);
                    toast.success('Blog Updated Successfully');
                    // fetchTableDate();
                    setAddBlog(false);
                    setEditBlog(false);
                })
                .catch((err) => {
                    
                    toast.error('Something went wrong');
                    setLoading(false);
                });
        }
        //return true;
    };

    const handleAddFormChange = (e) => {
        const tempData = { ...modalData }
        if (e.target.name === 'publishPostToggle') {
            tempData["isPublished"] = e.target.checked;
        }
        else if (e.target.name === 'url_key') {
            tempData[e.target.name] = e.target.value;
        }
        else {
            tempData[e.target.name] = e.target.value;
        }
        setModalData(tempData)
    }

    const handleImage = (e) => {
        const tempData = { ...modalData }

        tempData["image"] = e.target.files[0]
        // 
        setModalData(tempData)
    }

    const handleContentChange = (e) => {
        const tempData = { ...modalData }
        tempData["content"] = e
        setModalData(tempData)
    }

    const handleMultiSelectChange = (e) => {
        // 
        const tempData = { ...modalData }
        setMultiSelect(e)
        tempData["tag"] = e.map(item => item.value)
        setModalData(tempData)
    }

    const handleSelectChange = (e) => {
        //  
        const tempData = { ...modalData }
        setSingleSelect(e)
        tempData["category"] = e?.["value"]
        setModalData(tempData)
    }

    const handleImageClick = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };

    return (
        <div className='w-full h-full'>
            <div className="flex justify-between p-3 w-full">
                <h1 className="uppercase text-xl">Blogs</h1>
                <button onClick={handleAddBlog} className="py-1 px-2 rounded-md bg-primary text-white/80 text-xl uppercase cursor-pointer">
                    Add
                </button>
            </div>

            <div className="relative mt-12 overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
                        <tr className='text-center'>
                            <th scope="col" className="px-6 py-3">S. No</th>
                            {
                                tableHeading.map((item, index) => {
                                    return (
                                        <th scope="col" className="px-6 py-3" key={index}>{item.split('_').join(" ")}</th>
                                    );
                                })
                            }
                            <th scope="col" className="px-6 py-3">Edit</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.map((item, index) => {
                                return (
                                    <tr className='bg-white border-b' key={index}>
                                        <td className="px-6 py-4 text-center w-2/12">{index + 1}.</td>
                                        {
                                            tableHeading.map((field, index) => {
                                                const url = process.env.REACT_APP_BASE_URL + '/blogImage/' + item[field]
                                                return (
                                                    <td className="px-6 py-4 text-center" key={index}>
                                                        <div className='whitespace-nowrap'>
                                                            {
                                                                field === "image" ?
                                                                    <>
                                                                        <img src={url} alt={item[field]} className="w-11/12 cursor-pointer" onClick={() => { handleImageClick(url) }} />
                                                                    </>
                                                                    : field === "blog_date" ? item[field].split('T')[0] :
                                                                        item[field] && item[field].length > 30 ?
                                                                            item[field].slice(0, 27) + '...'
                                                                            : item[field] || ''
                                                            }
                                                        </div>
                                                    </td>
                                                );
                                            })
                                        }
                                        <td className="px-6 py-4 text-center">
                                            <button onClick={handleEditButton} name={index} className='py-1 px-2 rounded-md bg-primary text-white/80 cursor-pointer'>Edit</button>
                                        </td>
                                        <td className="px-6 py-4 text-center">
                                            <button onClick={handleActivate} value={item.is_active} name={index} className={`py-1 px-2 rounded-md text-white/80 cursor-pointer ${item.is_active ? "bg-red-900" : "bg-primary"}`}>{item.is_active ? "Deactivate" : "Activate"}</button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>

            {showModal && <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-[40] flex justify-center items-center' id='outside' onClick={handleOnClose}>
                <div className='shadow-lg h-4/5 w-6/12 mx-auto bg-white rounded-xl p-8 overflow-y-scroll z-[40000]'>
                    <div className='text-center text-3xl font-bold mb-8'>{addBlog ? 'Add' : 'Edit'} Blog</div>

                    <form onSubmit={handleSubmit}>
                        <div className='flex flex-col'>
                            <div className='flex justify-between flex-wrap'>
                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Title</label>
                                    <input
                                        type="text"
                                        name='title'
                                        required
                                        placeholder={`Enter title ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["title"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>

                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Author Name</label>
                                    <input
                                        type="text"
                                        name='author_name'
                                        required
                                        placeholder={`Enter author name ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["author_name"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between flex-wrap mt-5'>
                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Url Key</label>
                                    <input
                                        type="text"
                                        name='url_key'
                                        required
                                        placeholder={`Enter url Key ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["url_key"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>

                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Blog Date</label>
                                    <input
                                        type="date"
                                        name='blog_date'
                                        required
                                        placeholder={`Enter Blog Date ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["blog_date"]?.split("T")[0] || ""}
                                        className="form-control w-full"
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between flex-wrap mt-5'>
                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Meta Title</label>
                                    <input
                                        type="text"
                                        name='meta_title'
                                        required
                                        placeholder={`Enter meta title ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["meta_title"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>

                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Meta Description</label>
                                    <input
                                        type="text"
                                        name='meta_description'
                                        required
                                        placeholder={`Enter meta description ...`}
                                        onChange={handleAddFormChange}
                                        value={modalData["meta_description"] || ""}
                                        className="form-control w-full"
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between flex-wrap mt-5'>
                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <div htmlFor='multiSelect'>Category</div>
                                    <ReactSelect
                                        className="basic-single"
                                        classNamePrefix="category"
                                        value={singleSelect}
                                        isDisabled={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        name="category"
                                        options={singleSelectList}
                                        onChange={handleSelectChange}
                                    />
                                </div>

                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <div htmlFor='multiSelect'>Tags</div>
                                    <MultiSelect
                                        options={multiSelectList}
                                        value={multiSelect}
                                        onChange={handleMultiSelectChange}
                                        labelledBy="multiSelect"
                                        id="multiSelect"
                                        placeholder="Tags"
                                    />
                                </div>
                            </div>

                            <div className='flex justify-between items-center flex-wrap mt-5'>
                                <div className='flex flex-col gap-2 w-[49%]'>
                                    <label htmlFor='title'>Image</label>
                                    <input
                                        type="file"
                                        name='image'
                                        required={editBlog ? false : true}
                                        onChange={handleImage}
                                        className="form-control w-full"
                                    />
                                </div>

                                {!editBlog && <div className='flex flex-col gap-2 w-[49%]'>
                                    <div className='flex justify-start items-center gap-4'>
                                        <label htmlFor="publishPostToggle" className="form-check-label">
                                            Publish this Post
                                        </label>
                                        <input
                                            type="checkbox"
                                            id="publishPostToggle"
                                            name="publishPostToggle"
                                            onChange={handleAddFormChange}
                                            checked={modalData["isPublished"] || false}
                                            className="form-check-input me-2"
                                        />
                                    </div>
                                </div>}
                            </div>

                            <div className='flex flex-col gap-2 mt-5'>
                                <ReactQuill
                                    className="h-[90%]"
                                    theme="snow"
                                    value={modalData["content"]}
                                    onChange={handleContentChange}
                                    modules={{
                                        toolbar: [
                                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                            [{ size: [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                            { 'indent': '-1' }, { 'indent': '+1' }],
                                            ['link', 'image', 'video'],
                                            ['clean']
                                        ],
                                        clipboard: {
                                            matchVisual: false,
                                        }
                                    }}
                                    formats={[
                                        'header', 'font', 'size',
                                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                                        'list', 'bullet', 'indent',
                                        'link', 'image', 'video'
                                    ]}
                                    placeholder='Write something awesome ...'
                                />
                            </div>

                            <div className='w-full flex justify-center items-center'>
                                <button type="submit" className='mt-8 py-4 px-12 rounded-md text-xl bg-primary text-white w-full'>
                                    {loading ? <span>Loading...</span> : <span>Submit</span>}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
            {loading && <ButtonSpinner />}
        </div>
    )
}

export default AddBlog