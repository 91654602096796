import React from "react";
// import banner from "../../assets/technologies/rpa/rpa-page-banner.png";
import banner from "../../assets/domain/InsuranceImg.avif";
import TechnologyBanner from "../../components/technologies/TechnologyBanner";
import ProcessComponent2 from "../../components/technologies/ProcessComponent2";
import process_image from "../../assets/domain/InsuranceImg2.webp";
import { Helmet } from "react-helmet-async";

const Insurance = () => {
  const process = [
    {
      title: "Protection Against Cyber Attacks:",
      description:
        "<b>Insurance</b> companies handle vast amounts of personal and financial data, making them prime targets for <b>cyber attacks</b>. Implementing robust <b>cyber security</b> measures such as firewalls, encryption, and intrusion detection systems (IDS) is essential to defend against threats like data breaches, ransomware, and phishing attempts. This <b>protection</b> not only safeguards sensitive information but also ensures compliance with stringent data protection regulations.",
    },
    {
      title: "Enhanced Data Privacy:",
      description:
        "Maintaining customer trust is paramount in the <b>insurance</b> sector. Effective <b>cyber security</b> practices ensure that customer data remains confidential and secure. By implementing access controls, data encryption, and regular security audits, insurers can mitigate the risk of unauthorized access and data leaks, thereby <b>enhancing</b> overall data <b>privacy</b>.",
    },
    {
      title: "Prevention of Fraudulent Activities:",
      description:
        "<b>Cyber security</b> technologies such as advanced analytics and machine learning algorithms enable insurers to detect and prevent fraudulent activities effectively. These tools analyze patterns in claims data and customer behavior to identify anomalies indicative of fraudulent claims. By <b>preventing</b> fraudulent <b>activities</b>, insurers can minimize financial losses and maintain the integrity of their operations.",
    },
    {
      title: "Business Continuity and Resilience:",
      description:
        "Ensuring <b>business continuity</b> is critical for insurers, especially in the face of <b>cyber threats</b> and disruptions. Robust <b>cyber security</b> strategies encompass disaster recovery plans, backup systems, and continuous monitoring to mitigate risks and minimize downtime. This <b>resilience</b> enables insurers to uphold service levels and meet customer expectations even during <b>cybersecurity</b> incidents.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | Insurance</title>
      </Helmet>
      <TechnologyBanner
        image={banner}
        alt={"insurance-img"}
        title={"Insurance"}
        description={
          "Insurance is crucial for managing healthcare costs and accessing necessary medical care, providing financial protection against expenses that could otherwise lead to financial hardship."
        }
      />

      <ProcessComponent2
        title={"Cyber Security Strategies in the Insurance Sector"}
        image={process_image}
        alt={"healthcare-img2"}
        process={process}
        style={{ maxWidth: '100%', height: 'auto' }}
      />
      {/* <div className="flex items-center justify-center w-full mx-auto mt-2 p-4">
        <p className="text-center">
          Leverage our expertise in banking system integration to transform your
          financial operations. Contact our team of experienced system
          integrators today to discover how our innovative solutions can enhance
          efficiency and drive growth for your institution
        </p>
      </div> */}
    </>
  );
};

export default Insurance;
