import React from "react";
import TechnologyBanner from "../../components/technologies/TechnologyBanner";
import banner from "../../assets/domain/SupplyChain1.png";
import imgbanner from "../../assets/technologies/cybersecurity_banner.jpeg"
import process_image from "../../assets/technologies/cyber-security-content-image.jpeg"
import { Helmet } from 'react-helmet-async';

const Cyber = () => {
  const process = [
    {
      title: 'Forecasting and Demand Planning:',
      description: '<b>Data analytics</b> enables supply chain professionals to analyze historical sales data, market trends, and customer behavior to forecast demand accurately. By identifying patterns and trends, organizations can adjust inventory levels, production schedules, and distribution strategies proactively, minimizing stockouts and excess inventory.'
    },
    {
      title: 'Inventory Optimization:',
      description: 'Efficient <b>supply chain</b> management hinges on maintaining optimal inventory levels. <b>Data analytics</b> helps organizations analyze demand variability, lead times, and supply chain disruptions to optimize inventory levels. By leveraging predictive analytics, companies can reduce carrying costs while ensuring product availability.'
    },
    {
      title: 'Enhanced Operational Efficiency',
      description: '<b>Data analytics</b> enables organizations to streamline <b>supply chain</b> operations by identifying inefficiencies and bottlenecks. Analyzing key performance indicators (KPIs) such as cycle time, order fulfillment rates, and transportation costs allows for continuous improvement initiatives. Insights gained from <b>data analytics</b> facilitate better resource allocation and process optimization'
    },
    {
      title: 'Supplier Relationship Management',
      description: 'Effective <b>data analytics</b> strengthens supplier relationships by providing visibility into supplier performance, quality metrics, and delivery reliability. By analyzing supplier data, organizations can identify high-performing vendors, negotiate better terms, and mitigate risks associated with supply chain disruptions.'
    },
    {
      title: 'Risk Management',
      description: '<b>Data analytics</b> plays a crucial role in <b>supply chain</b> risk management by identifying potential risks and vulnerabilities. Analyzing external factors such as geopolitical events, economic trends, and natural disasters allows organizations to develop proactive strategies to mitigate risks, ensuring continuity of operations.'
    },
    {
      title: 'Real-time Decision Making',
      description: 'In a dynamic <b>supply chain</b> environment, real-time insights provided by <b>data analytics</b> enable agile decision-making. By monitoring key metrics and performance indicators in real-time, organizations can respond swiftly to disruptions, customer demands, and market changes, thereby improving responsiveness and customer satisfaction.'
    },
    {
      title: 'Sustainability and Environmental Impact',
      description: '<b>Data analytics</b> helps organizations optimize <b>supply chain</b> sustainability efforts by analyzing environmental impact metrics such as carbon footprint, energy consumption, and waste generation. By identifying areas for improvement and implementing data-driven strategies, companies can achieve sustainability goals while reducing costs.'
    },
    {
      title: 'Continuous Improvement',
      description: 'Leveraging <b>data analytics</b> facilitates a culture of continuous improvement within the <b>supply chain</b>. By analyzing performance metrics and conducting root cause analysis, organizations can identify opportunities for process refinement, cost reduction, and operational excellence.'
    },
    {
      title: 'Conclusion',
      description: '<b>data analytics</b> is not merely a tool but a strategic imperative for modern <b>supply chain</b> management. By harnessing the power of <b>data analytics</b>, organizations can optimize operations, mitigate risks, enhance decision-making capabilities, and achieve sustainable growth in today competitive business landscape. It enables businesses to stay agile and responsive to market demands, driving efficiency across the entire supply chain. Moreover, ongoing advancements in <b>data analytics</b> technologies offer opportunities for continuous innovation and improvement, ensuring that organizations can adapt swiftly to evolving industry trends and customer expectations. Embracing <b>data analytics</b> as a core strategic asset empowers companies to not only survive but thrive in an increasingly interconnected global economy, positioning them for long-term success and resilience.'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | Supply Chain</title>
      </Helmet>

      <TechnologyBanner
        image={imgbanner}
        alt={"Supply Chain"}
        title={"Supply Chain"}
        description={" A Supply Chain is the network of organizations, processes, and resources involved in producing and delivering goods and services from suppliers to consumers."}
      />

      <div className='w-full flex flex-col justify-center items-center gap-4 py-8'>
        {/* <h1 className='text-[1.8rem] md:text-[2.4rem] lg:text-[3rem] px-2 lg:px-0 text-center font-bold text-primary'>
        Why is it crucial to secure supply chain
        </h1>
        <p className='md:text-xl text-center '>
          In today's interconnected world, <b>cybersecurity</b> is essential for safeguarding your digital assets. A dedicated team ensures comprehensive protection against a variety of threats, providing expertise in <b>Information Security</b>, <b>Network Security</b>, <b>Data Protection</b>, and more.
        </p> */}
        <div className='flex justify-between gap-8 w-11/12 mx-auto mt-8'>
          <div className='w-[50%] justify-center items-center hidden md:flex'>
            <img src={banner} alt="rpa_implementation_process" className="w-full h-full bg-cover bg-center"/>
          </div>
          <div className='md:w-[50%] space-y-8 h-full flex flex-col justify-between items-center'>
            {
              process.slice(0, 5).map(({ title, description }, index) => {
                return (
                  <div className='space-y-1 flex flex-col justify-between'>
                    <h1 className='font-bold text-primary text-xl'>{title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='flex flex-row-reverse justify-between gap-8 w-11/12 mx-auto mt-8'>
          <div className='w-[50%] justify-center items-center hidden md:flex'>
            <img src={process_image} alt="rpa_implementation_process" className="w-full bg-cover bg-center"/>
          </div>
          <div className='md:w-[50%] space-y-8 h-full flex flex-col justify-between items-center'>
            {
              process.slice(6, 11).map(({ title, description }, index) => {
                return (
                  <div className='space-y-1 flex  flex-col justify-between'>
                    <h1 className='font-bold text-primary text-xl'>{title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )

}

export default Cyber;