import React, { useState, useRef, useEffect } from "react";
import { submitInternshipApplication } from "../../services/operations/formAPI";
import axios from "axios";

const InternForm = () => {
  // State variables to store form data
  const [fullName, setFullName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("India");
  const [collegeName, setCollegeName] = useState("");
  const [university, setUniversity] = useState("");
  const [collegeLocation, setCollegeLocation] = useState("");
  const [course, setCourse] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [batch, setBatch] = useState("");
  const [academicsAggregrate, setAcademicsAggregrate] = useState("");
  const [PANNumber, setPANNumber] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [resume, setResume] = useState(null);
  const [collegeIdProof, setCollegeIdProof] = useState(null);
  const [identityProof, setIdentityProof] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const resumeInputRef = useRef(null);
  const collegeIdInputRef = useRef(null);
  const identityInputRef = useRef(null);

  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const courses = [
    "BCA",
    "B.Tech",
    "B.Sc",
    "B.E.",
    "MCA",
    "M.Tech",
    "M.Sc",
    "MBA",
  ];

  const specializations = [
    "CSE",
    "ME",
    "ECE",
    "Data Science",
    "IT",
    "HR",
    "Finance",
    "Marketing",
    "Operations Management",
    "Business Analytics",
  ];
  const batches = ["2023", "2024", "2025", "2026", "2027", "2028"];
  const projectTechnologies = [
    "HTML",
    "CSS",
    "JS",
    "Node.js",
    "React.js",
    "PHP",
    "Flutter",
    "SQL Server",
    "My SQL",
    "Angular.js",
  ];
  // const cities = ['Delhi', 'Noida', 'Faridabad'];
  // const states = ['Delhi', 'Uttar Pradesh', 'Haryana', 'Rajasthan'];
  const Country = ["India"];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/master/getStateandCities")
      .then(function (res) {
        const data = res.data.data;
        const states = Object.keys(data);
        setData(data);
        setStates(states);
        setCities(data[states[0]]);
      })
      .catch(function (e) {});
  }, []);

  const handleStateChange = (state) => {
    setState(state);

    setCities(data[state]);
    setCity("");
  };

  const handleContactNoChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setContactNo(value);
      setErrors((prevErrors) => ({ ...prevErrors, contactNo: "" }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNo: "Contact number cannot exceed 10 digits.",
      }));
    }
  };

  const academicsAggregrateRegex = /^(100(\.00?)?|[0-9]?[0-9](\.[0-9]{1,2})?)$/;
  const PANNumberRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  const AadharNumberRegex = /^\d{12}$/;

  const validateForm = () => {
    const errors = {};

    if (!fullName.trim()) {
      errors.fullName = "Full Name is required";
    }
    if (!contactNo.trim()) {
      errors.contactNo = "Contact No is required";
    }
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }
    // if (!location.trim()) {
    //   errors.location = 'Location is required';
    // }
    if (!city.trim()) {
      errors.city = "City/District is required";
    }
    if (!state.trim()) {
      errors.state = "State/UT is required";
    }
    if (!country.trim()) {
      errors.country = "Country is required";
    }
    if (!collegeName.trim()) {
      errors.collegeName = "College Name is required";
    }
    if (!university.trim()) {
      errors.university = "University is required";
    }
    if (!collegeLocation.trim()) {
      errors.collegeLocation = "College Location is required";
    }
    if (!course.trim()) {
      errors.course = "Course is required";
    }
    if (!specialization.trim()) {
      errors.specialization = "Specialization is required";
    }
    if (!batch.trim()) {
      errors.batch = "Batch is required";
    }
    if (!linkedinProfile.trim()) {
      errors.linkedinProfile = "Linkedin Profile is required";
    }
    if (!academicsAggregrate.trim()) {
      errors.academicsAggregrate = "Academic aggregate is required";
    } else if (!academicsAggregrateRegex.test(academicsAggregrate)) {
      errors.academicsAggregrate =
        "Please enter a valid academic aggregate (e.g., 85.5%, 90%, 100%).";
    }
    if (PANNumber && !PANNumberRegex.test(PANNumber)) {
      errors.PANNumber = "Please enter a valid PAN Number.";
    }
    if (aadharNumber && !AadharNumberRegex.test(aadharNumber)) {
      errors.aadharNumber = "Please enter a valid Aadhar Number.";
    }
    if (!resume) {
      errors.resume = "Resume is required";
    } else if (resume.type !== "application/pdf") {
      errors.resume = "Resume must be a PDF file";
    }
    if (!collegeIdProof) {
      errors.collegeIdProof = "College ID Proof is required";
    } else if (collegeIdProof.type !== "application/pdf") {
      errors.collegeIdProof = "College ID Proof must be a PDF file";
    }
    if (!identityProof) {
      errors.identityProof = "Identity Proof is required";
    } else if (identityProof.type !== "application/pdf") {
      errors.identityProof = "Identity Proof must be a PDF file";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const resetForm = () => {
    setFullName("");
    setContactNo("");
    setEmail("");
    setCollegeName("");
    setCourse("");
    setSpecialization("");
    setBatch("");
    setResume(null);
    setCollegeIdProof(null);
    setIdentityProof(null);
    setMessage("");
    setState("");
    setCity("");
    setUniversity("");
    setCollegeLocation("");
    setAcademicsAggregrate("");
    setPANNumber("");
    setAadharNumber("");
    setLinkedinProfile("");
    resumeInputRef.current.value = "";
    collegeIdInputRef.current.value = "";
    identityInputRef.current.value = "";

    // Reset project 1 fields
    setProjectTitle1("");
    setProjectDescription1("");
    setProjectDuration1("");
    setProjectTools1("");

    // Reset project 2 fields
    setProjectTitle2("");
    setProjectDescription2("");
    setProjectDuration2("");
    setProjectTools2("");

    // Reset project 3 fields
    setProjectTitle3("");
    setProjectDescription3("");
    setProjectDuration3("");
    setProjectTools3("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append("name", fullName);
      formData.append("mobile", contactNo);
      formData.append("email", email);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("college_name", collegeName);
      formData.append("university", university);
      formData.append("collegeLocation", collegeLocation);
      formData.append("course", course);
      formData.append("specialization", specialization);
      formData.append("batch", batch);
      formData.append("academicsAggregrate", academicsAggregrate);
      formData.append("linkedinProfile", linkedinProfile);
      formData.append("PANNumber", PANNumber);
      formData.append("aadharNumber", aadharNumber);
      formData.append("resume", resume);
      formData.append("collegeId", collegeIdProof);
      formData.append("identityProof", identityProof);
      formData.append("message", message);

      // Append project 1 details
      formData.append("projectTitle1", projectTitle1);
      formData.append("projectDescription1", projectDescription1);
      formData.append("projectDuration1", projectDuration1);
      formData.append("projectTools1", projectTools1);

      // Append project 2 details
      formData.append("projectTitle2", projectTitle2);
      formData.append("projectDescription2", projectDescription2);
      formData.append("projectDuration2", projectDuration2);
      formData.append("projectTools2", projectTools2);

      // Append project 3 details
      formData.append("projectTitle3", projectTitle3);
      formData.append("projectDescription3", projectDescription3);
      formData.append("projectDuration3", projectDuration3);
      formData.append("projectTools3", projectTools3);

      // Log formData entries
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      setLoading(true);
      const result = await submitInternshipApplication(formData);
      if (result) {
        resetForm();
      }
      setLoading(false);
    } else {
    }
  };

  const [showProjectDetails1, setShowProjectDetails1] = useState(false);
  const [projectTitle1, setProjectTitle1] = useState("");
  const [projectDescription1, setProjectDescription1] = useState("");
  const [projectDuration1, setProjectDuration1] = useState("");
  const [projectTools1, setProjectTools1] = useState("");

  const [showProjectDetails2, setShowProjectDetails2] = useState(false);
  const [projectTitle2, setProjectTitle2] = useState("");
  const [projectDescription2, setProjectDescription2] = useState("");
  const [projectDuration2, setProjectDuration2] = useState("");
  const [projectTools2, setProjectTools2] = useState("");

  const [showProjectDetails3, setShowProjectDetails3] = useState(false);
  const [projectTitle3, setProjectTitle3] = useState("");
  const [projectDescription3, setProjectDescription3] = useState("");
  const [projectDuration3, setProjectDuration3] = useState("");
  const [projectTools3, setProjectTools3] = useState("");

  const [activeProject, setActiveProject] = useState(null);

  const toggleProject = (projectNumber) => {
    setActiveProject((prev) => (prev === projectNumber ? null : projectNumber));
  };

  return (
    <div className="my-16">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col w-11/12 lg:w-3/3 mx-auto">
          <div className="grid md:grid-cols-3 gap-5">
            {/* Full Name */}
            <div className="flex flex-col gap-2">
              <label htmlFor="fullName">Full Name*</label>
              <input
                type="text"
                id="fullName"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              {errors.fullName && (
                <span className="text-red-500">{errors.fullName}</span>
              )}
            </div>

            {/* Email */}
            <div className="flex flex-col gap-2">
              <label htmlFor="email">Email Address*</label>
              <input
                type="email"
                id="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <span className="text-red-500">{errors.email}</span>
              )}
            </div>

            {/* Contact No */}
            <div className="flex flex-col gap-2">
              <label htmlFor="contactNo">Contact No*</label>
              <input
                type="number"
                id="contactNo"
                placeholder="Contact No"
                value={contactNo}
                onChange={handleContactNoChange}
              />
              {errors.contactNo && (
                <span className="text-red-500">{errors.contactNo}</span>
              )}
            </div>
          </div>
          <div className="grid md:grid-cols-3 gap-5 mt-5">
            <div className="flex flex-col gap-2">
              <label htmlFor="Country">Country*</label>
              <select
                id="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="" disabled>
                  Select Country
                </option>
                {Country.map((Country, index) => (
                  <option key={index} value={Country}>
                    {Country}
                  </option>
                ))}
              </select>
              {errors.country && (
                <span className="text-red-500">{errors.country}</span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <label htmlFor="State">State/UT*</label>
              <select
                id="State"
                value={state}
                onChange={(e) => handleStateChange(e.target.value)}
              >
                <option value="" disabled>
                  Select State/UT
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              {errors.state && (
                <span className="text-red-500">{errors.state}</span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <label htmlFor="city">City/District*</label>
              <select
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              >
                <option value="" disabled>
                  Select City/District
                </option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              {errors.city && (
                <span className="text-red-500">{errors.city}</span>
              )}
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-5 mt-5">
            {/* College Name */}
            <div className="flex flex-col gap-2">
              <label htmlFor="collegeName">College Name*</label>
              <input
                type="text"
                id="collegeName"
                placeholder="College Name"
                value={collegeName}
                onChange={(e) => setCollegeName(e.target.value)}
              />
              {errors.collegeName && (
                <span className="text-red-500">{errors.collegeName}</span>
              )}
            </div>

            {/* University*/}
            <div className="flex flex-col gap-2">
              <label htmlFor="University">University*</label>
              <input
                type="text"
                id="University"
                placeholder="University"
                value={university}
                onChange={(e) => setUniversity(e.target.value)}
              />
              {errors.university && (
                <span className="text-red-500">{errors.university}</span>
              )}
            </div>

            {/*College Location*/}
            <div className="flex flex-col gap-2">
              <label htmlFor="College Location">College Location*</label>
              <input
                type="text"
                id="collegeLocation"
                placeholder="College Location"
                value={collegeLocation}
                onChange={(e) => setCollegeLocation(e.target.value)}
              />
              {errors.collegeLocation && (
                <span className="text-red-500">{errors.collegeLocation}</span>
              )}
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-5 mt-5">
            {/* Course */}
            <div className="flex flex-col gap-2">
              <label htmlFor="course">Course*</label>
              <select
                id="course"
                value={course}
                onChange={(e) => setCourse(e.target.value)}
              >
                <option value="" disabled>
                  Select Course
                </option>
                {courses.map((course, index) => (
                  <option key={index} value={course}>
                    {course}
                  </option>
                ))}
              </select>
              {errors.course && (
                <span className="text-red-500">{errors.course}</span>
              )}
            </div>

            {/* Specialization */}
            <div className="flex flex-col gap-2">
              <label htmlFor="specialization">Specialization*</label>
              <select
                id="specialization"
                value={specialization}
                onChange={(e) => setSpecialization(e.target.value)}
              >
                <option value="" disabled>
                  Select Specialization
                </option>
                {specializations.map((spec, index) => (
                  <option key={index} value={spec}>
                    {spec}
                  </option>
                ))}
              </select>
              {errors.specialization && (
                <span className="text-red-500">{errors.specialization}</span>
              )}
            </div>

            {/* Batch */}
            <div className="flex flex-col gap-2">
              <label htmlFor="batch">Batch*</label>
              <select
                id="batch"
                value={batch}
                onChange={(e) => setBatch(e.target.value)}
              >
                <option value="" disabled>
                  Select Batch
                </option>
                {batches.map((batch, index) => (
                  <option key={index} value={batch}>
                    {batch}
                  </option>
                ))}
              </select>
              {errors.batch && (
                <span className="text-red-500">{errors.batch}</span>
              )}
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-5 mt-5">
            {/*Academics Aggregrate*/}
            <div className="flex flex-col gap-2">
              <label htmlFor="Academics Aggregrate">
                Academics Aggregate (in %) *
              </label>
              <input
                type="text"
                id="academicsAggregrate"
                placeholder="Academics Aggregate"
                value={academicsAggregrate}
                onChange={(e) => setAcademicsAggregrate(e.target.value)}
              />
              {errors.academicsAggregrate && (
                <span className="text-red-500">
                  {errors.academicsAggregrate}
                </span>
              )}
            </div>

            {/*Linkedin Profile URL*/}
            <div className="flex flex-col gap-2">
              <label htmlFor="linkedinProfile">Linkedin Profile URL*</label>
              <input
                type="text"
                id="linkedinProfile"
                placeholder="Linkedin Profile URL"
                value={linkedinProfile}
                onChange={(e) => setLinkedinProfile(e.target.value)}
              />
              {errors.linkedinProfile && (
                <span className="text-red-500">{errors.linkedinProfile}</span>
              )}
            </div>

            {/*PAN Number*/}
            <div className="flex flex-col gap-2">
              <label htmlFor="PAN Number">PAN Number (Optional) </label>
              <input
                type="text"
                id="PANNumber"
                placeholder="PAN Number"
                value={PANNumber}
                onChange={(e) => setPANNumber(e.target.value)}
              />
              {errors.PANNumber && (
                <span className="text-red-500">{errors.PANNumber}</span>
              )}
            </div>

            {/*Aadhar Number*/}
            <div className="flex flex-col gap-2 pb-6">
              <label htmlFor="Aadhar Number">Aadhar Number (Optional) </label>
              <input
                type="text"
                id="AadharNumber"
                placeholder="Aadhar Number"
                value={aadharNumber}
                onChange={(e) => setAadharNumber(e.target.value)}
              />
              {errors.aadharNumber && (
                <span className="text-red-500">{errors.aadharNumber}</span>
              )}
            </div>
          </div>
          {/* Project Section Styles */}
          <div className="flex flex-col gap-4">
            <label htmlFor="Projects Details">
              Project Details (Optional){" "}
            </label>
            <div className="flex flex-wrap gap-10">
              {/* Project 1 Section */}
              <div className="mb-6 w-full sm:w-[300px]">
                <button
                  type="button"
                  className="w-full text-left p-4  border rounded-lg shadow-lg hover:shadow-xl active:scale-95 transition-transform transform duration-300 ease-in-out"
                  onClick={() => toggleProject(1)}
                >
                  Project 1
                </button>
                {activeProject === 1 && (
                  <div className="p-6 bg-gray-100 rounded-lg shadow-md mt-3 space-y-4">
                    <input
                      type="text"
                      placeholder="Project Title"
                      value={projectTitle1}
                      onChange={(e) => setProjectTitle1(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <textarea
                      placeholder="Project Description"
                      value={projectDescription1}
                      onChange={(e) => setProjectDescription1(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <input
                      type="text"
                      placeholder="Project Completed (in months)"
                      value={projectDuration1}
                      onChange={(e) => setProjectDuration1(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <input
                      type="text"
                      placeholder="Tools/Skills"
                      value={projectTools1}
                      onChange={(e) => setProjectTools1(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                  </div>
                )}
              </div>

              {/* Project 2 Section */}
              <div className="mb-6 w-full sm:w-[300px]">
                <button
                  type="button"
                  className="w-full text-left p-4  border rounded-lg shadow-lg hover:shadow-xl active:scale-95 transition-transform transform duration-300 ease-in-out"
                  onClick={() => toggleProject(2)}
                >
                  Project 2
                </button>
                {activeProject === 2 && (
                  <div className="p-6 bg-gray-100 rounded-lg shadow-md mt-3 space-y-4">
                    <input
                      type="text"
                      placeholder="Project Title"
                      value={projectTitle2}
                      onChange={(e) => setProjectTitle2(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <textarea
                      placeholder="Project Description"
                      value={projectDescription2}
                      onChange={(e) => setProjectDescription2(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <input
                      type="text"
                      placeholder="Project Completed (in months)"
                      value={projectDuration2}
                      onChange={(e) => setProjectDuration2(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <input
                      type="text"
                      placeholder="Tools/Skills"
                      value={projectTools2}
                      onChange={(e) => setProjectTools2(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                  </div>
                )}
              </div>

              {/* Project 3 Section */}
              <div className="mb-6 w-full sm:w-[300px]">
                <button
                  type="button"
                  className="w-full text-left p-4  border rounded-lg shadow-lg hover:shadow-xl active:scale-95 transition-transform transform duration-300 ease-in-out"
                  onClick={() => toggleProject(3)}
                >
                  Project 3
                </button>
                {activeProject === 3 && (
                  <div className="p-6 bg-gray-100 rounded-lg shadow-md mt-3 space-y-4">
                    <input
                      type="text"
                      placeholder="Project Title"
                      value={projectTitle3}
                      onChange={(e) => setProjectTitle3(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <textarea
                      placeholder="Project Description"
                      value={projectDescription3}
                      onChange={(e) => setProjectDescription3(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <input
                      type="text"
                      placeholder="Project Completed (in months)"
                      value={projectDuration3}
                      onChange={(e) => setProjectDuration3(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    <input
                      type="text"
                      placeholder="Tools/Skills"
                      value={projectTools3}
                      onChange={(e) => setProjectTools3(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-5 mt-5">
            {/* Resume */}
            <div className="flex flex-col gap-2">
              <label htmlFor="resume">Resume*</label>
              <input
                type="file"
                id="resume"
                ref={resumeInputRef}
                onChange={(e) => setResume(e.target.files[0])}
              />
              {errors.resume && (
                <span className="text-red-500">{errors.resume}</span>
              )}
            </div>
            {/* College ID Proof */}
            <div className="flex flex-col gap-2">
              <label htmlFor="collegeIdProof">College Id Proof*</label>
              <input
                type="file"
                id="collegeIdProof"
                ref={collegeIdInputRef}
                onChange={(e) => setCollegeIdProof(e.target.files[0])}
              />
              {errors.collegeIdProof && (
                <span className="text-red-500">{errors.collegeIdProof}</span>
              )}
            </div>

            {/* Identity Proof */}
            <div className="flex flex-col gap-2">
              <label htmlFor="identityProof">
                Identity Proof (Aadhar Card / Pan Card)*
              </label>
              <input
                type="file"
                id="identityProof"
                ref={identityInputRef}
                onChange={(e) => setIdentityProof(e.target.files[0])}
              />
              {errors.identityProof && (
                <span className="text-red-500">{errors.identityProof}</span>
              )}
            </div>
          </div>

          {/* Message */}
          <div className="flex flex-col gap-2 mt-5">
            <label htmlFor="message">Message</label>
            <textarea
              type="text"
              maxLength={100}
              id="message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          {/* Note */}

          <p className="mt-5">
            Note: Please reach out at{" "}
            <a href="tel:+918595653061" className="border-b border-gray-800">
              +91 8595653061
            </a>{" "}
            for any assistance or issues encountered during the form submission
            process.
          </p>

          <div className="w-full flex justify-center items-center">
            <button
              type="submit"
              // disabled={loading}
              className="mt-8 py-4 px-12 rounded-md text-lg bg-primary text-white"
            >
              {/* {loading ? <span>Loading...</span> : <span>Submit</span>} */}
              <span>Submit</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InternForm;
