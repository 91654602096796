import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../shared/Footer';
import { HelmetProvider } from 'react-helmet-async';
import Navbar2 from '../shared/Navbar2';
import PageTop from '../common/PageTop';
import { Helmet } from 'react-helmet-async';
import StructuredData from '../../StructuredData';

const OpenLayout = () => {
    const helmetContext = {};
    return (
        <HelmetProvider context={helmetContext}>
            <Helmet>
                <title>Mittal Software Labs | Digital Transformation Consulting</title>
                <meta name="description"
                    content="Mittal Software Labs (MSL) helps businesses digitally transform by providing consulting, staff augmentation, and managed services. They focus on improving a business's efficiency and productivity. MSL offers a variety of services including full-stack development, mobile app innovation, and chatbots"></meta>
                <link rel="canonical" href="https://www.mittalsoftwarelabs.com" />
            </Helmet>
            <StructuredData />
            <Navbar2 />
            <Outlet />
            <Footer />
            <PageTop />
        </HelmetProvider>
    )
}

export default OpenLayout