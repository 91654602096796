import React from 'react'

const ProcessComponent = ({title, image, process}) => {
    return (
        <div className='bg-primary w-full flex flex-col justify-center items-center gap-4 py-8'>
            <h1 className='text-[1.8rem] md:text-[2.4rem] lg:text-[3.2rem] px-2 lg:px-0 text-center font-bold text-white'>{title}
            </h1>
            <div className='flex justify-between gap-8 w-11/12 mx-auto mt-8'>
                <div className='w-[50%] justify-center items-center hidden md:flex'>
                    <img src={image} alt="rpa_implementation_process" />
                </div>
                <div className='md:w-[50%] space-y-8 h-full flex flex-col justify-between items-center'>
                    {
                        process.map(({ title, description }, index) => {
                            return (
                                <div className='text-white space-y-1 flex flex-col justify-between'>
                                    <h1 className='font-bold text-xl'><span>{index + 1}.</span> {title}</h1>
                                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ProcessComponent