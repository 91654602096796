import React from 'react'

const TechnologyBanner = ({ image, alt, title, description }) => {
  return (
    // <div className="relative lg:h-[50vh] h-[40vh] hidden md:block">
    //   <img
    //     className="w-full h-full"
    //     src={image}
    //     alt={alt}
    //   />
    //   <div className="absolute inset-0 flex flex-col items-start justify-start my-20 md:my-8 py-4 lg:py-12 md:w-4/5 lg:w-3/5 md:left-10 md:top-20 lg:top-14 w-11/12 mx-auto md:mx-0 ">
    //     <h3 className="text-white text-2xl md:text-4xl font-bold">{title}</h3>
    //     <p className="text-white text-lg mt-2 md:w-4/5 hidden md:block">{description}</p>
    //   </div>
    // </div>

    <div className="relative bg-cover bg-center h-[30vh] lg:h-[40vh]" style={{ backgroundImage: `url(${image})` }}>
      <div className="flex flex-col items-center justify-center bg-black h-full bg-opacity-50">
        <div className='flex flex-col w-11/12'>
        <h3 className="text-white text-center md:text-left text-2xl md:text-4xl lg:text-5xl font-bold">{title}</h3>
        <p className="text-white text-center md:text-left text-sm md:text-lg mt-2">{description}</p>
        </div>
      </div>
    </div>
  )
}

export default TechnologyBanner