import React from "react";
import TechnologyBanner from "../../components/technologies/TechnologyBanner";
import banner from "../../assets/technologies/rpa/rpa-page-banner.png";
import ProcessComponent2 from "../../components/technologies/ProcessComponent2";
import img3 from "../../assets/domain/telecom.png";
import imgbanner from "../../assets/technologies/data-analytics-page-banner.jpg";
import { Helmet } from "react-helmet-async";

const Telecom = () => {
  const process = [
    {
      title: "Streamlining Operations:",
      description:
        "Telecom companies manage a diverse range of systems and networks, from billing and customer management to network infrastructure and service delivery platforms. <b>System integration</b> enables these disparate systems to communicate and work together seamlessly. By integrating various components into a unified system, telecom operators can streamline operations, reduce redundancies, and improve overall efficiency.",
    },
    {
      title: "Enhancing Service Delivery:",
      description:
        "<b>System integration</b> enables telecom operators to offer integrated services more efficiently. For example, integrating customer relationship management (CRM) systems with billing and provisioning systems allows operators to provide personalized services, manage customer data more effectively, and streamline service activation and billing processes. This enhances customer satisfaction and loyalty.",
    },
    {
      title: "Optimizing Network Management",
      description:
        "Telecom networks are complex and require continuous monitoring, management, and optimization. <b>System integration</b> facilitates the integration of network management systems, allowing operators to monitor network performance, troubleshoot issues proactively, and optimize network resources. This ensures reliable service delivery and minimizes downtime, enhancing overall network efficiency.",
    },
    {
      title: "Supporting Innovation and Scalability",
      description:
        "In a rapidly evolving telecom landscape, <b>system integration</b> supports innovation by enabling the seamless deployment of new technologies and services. It allows telecom operators to integrate new applications, technologies, and platforms into existing infrastructure quickly and efficiently. This agility is crucial for staying competitive and meeting evolving customer expectations.",
    },
    {
      title: "Improving Data Management and Analytics",
      description:
        "Effective <b>system integration</b> facilitates centralized data management by integrating disparate data sources across the organization. This consolidated view of data enables telecom operators to perform advanced analytics, derive actionable insights, and make informed decisions. Improved data management enhances operational efficiency, enables targeted marketing campaigns, and supports strategic business initiatives.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | Telecom</title>
      </Helmet>
      <TechnologyBanner
        image={imgbanner}
        alt={"Cyber Security"}
        title={"Telecom"}
        description={
          "Telecom refers to the transmission of information over long distances using electronic devices and networks, facilitating global connectivity and communication."
        }
      />
    
      <div>
        <ProcessComponent2
          title={"How System Integration Helps Telecom"}
          image={img3}
          process={process}
        />
      </div>
      <div className="flex items-center justify-center w-full mx-auto mt-3 p-4">
        <p className="text-center">
      <b>Telecommunications</b> is about transmitting information over long distances using technologies like <b>phones, radios, and the internet</b>. It includes everything from phone calls and internet browsing to <b>TV broadcasts</b> and <b>data exchanges</b> between devices worldwide.
        </p>
      </div>
    </>
  );
};

export default Telecom;