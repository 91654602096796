import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { MdEdit } from "react-icons/md";
import NoTransformationFound from "./NoTransformationLeverFound.jsx"; // Updated import
import { transformationLeverEndPoints } from "../../services/apis.js";

const TransformationLeverDashboard = () => {
  // Updated component name
  const [searchTerm, setSearchTerm] = useState("");
  const [transformationData, setTransformationData] = useState([]); // Updated state variable
  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState({
    transformation_id: "", // Updated state variable
    transformation_name: "", // Updated state variable
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingTransformation, setEditingTransformation] = useState(null); // Updated state variable

  // Optimized filtering logic
  const filteredTransformations = transformationData.filter(
    // Updated variable name
    (
      transformation // Updated variable name
    ) =>
      transformation.transformation_id
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) || // Updated variable name
      transformation.transformation_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) // Updated variable name
  );

  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingTransformation(null); // Updated state variable
  };

  const openAddEditModal = (transformation) => {
    // Updated variable name
    setFormData({
      transformation_id: transformation.transformation_id || "", // Updated state variable
      transformation_name: transformation.transformation_name, // Updated state variable
    });
    setEditingTransformation(transformation.transformation_id ? true : false); // Updated state variable
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingTransformation) {
        // Updated state variable
        
        await axios.post(
          transformationLeverEndPoints.POST_UPDATE_TRANSFORMATION,
          {
            // Updated endpoint
            transformation_id: formData.transformation_id, // Updated state variable
            transformation_name: formData.transformation_name, // Updated state variable
          }
        );
        toast.success("Transformation updated successfully"); // Updated message
      } else {
        await axios.post(
          transformationLeverEndPoints.POST_CREATE_TRANSFORMATION,
          {
            // Updated endpoint
            transformation_name: formData.transformation_name, // Updated state variable
          }
        );
        toast.success("Transformation added successfully"); // Updated message
      }
      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      toast.error("Error processing request");
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchTransformationData = async (url) => {
    // Updated function name
    try {
      const response = await axios.get(url);
      setTransformationData(response.data?.data?.data || []); // Updated state variable
      
    } catch (error) {
      console.error("Error fetching transformations:", error); // Updated message
    }
  };

  useEffect(() => {
    fetchTransformationData(
      transformationLeverEndPoints.GET_ALL_TRANSFORMATIONS
    ); // Updated endpoint
  }, [updateUI]);

  const handleStatus = async (transformation_id, is_active) => {
    // Updated variable name
    try {
      if (is_active === true) {
        await axios.post(
          transformationLeverEndPoints.POST_DEACTIVATE_TRANSFORMATION,
          {
            // Updated endpoint
            transformation_id,
          }
        );
        toast.success("Transformation deactivated successfully"); // Updated message
      } else {
        await axios.post(
          transformationLeverEndPoints.POST_ACTIVATE_TRANSFORMATION,
          {
            // Updated endpoint
            transformation_id,
          }
        );
        toast.success("Transformation activated successfully"); // Updated message
      }
      setUpdateUI((prevState) => !prevState);
    } catch (error) {
      console.error("Error updating transformation status:", error); // Updated message
      toast.error("Error updating transformation status"); // Updated message
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-4">Transformation Lever Master</h1>{" "}
      {/* Updated heading */}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for a transformation" // Updated placeholder
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a transformation" // Updated aria-label
        />

        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({ transformation_name: "" })} // Updated variable name
            className="bg-blue-500 text-white px-8 py-2 font-bold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            aria-label="Add Transformation" // Updated aria-label
          >
            Add Transformation {/* Updated button text */}
          </button>
        </div>
      </div>
      {filteredTransformations.length === 0 ? ( // Updated variable name
        <NoTransformationFound /> // This component might need to be updated too
      ) : (
        <div className="overflow-auto max-h-[800px]">
          <table className="w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Serial Number
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Transformation Name {/* Updated header */}
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredTransformations.map(
                (
                  transformation,
                  index // Updated variable name
                ) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    } hover:bg-gray-100`}
                  >
                    <td className="border border-gray-300 p-4 text-gray-700">
                      {transformation.transformation_id}{" "}
                      {/* Updated variable name */}
                    </td>
                    <td className="border border-gray-300 p-4 text-gray-700">
                      {transformation.transformation_name}{" "}
                      {/* Updated variable name */}
                    </td>
                    <td className="border border-gray-300 p-4 text-gray-700">
                      <div className="flex gap-4">
                        <button
                          onClick={() => openAddEditModal(transformation)} // Updated variable name
                          className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                        >
                          <MdEdit /> Edit
                        </button>

                        <button
                          className="text-sm px-3 py-1 rounded"
                          onClick={
                            () =>
                              handleStatus(
                                transformation.transformation_id,
                                transformation.is_active
                              ) // Updated variable name
                          }
                        >
                          {transformation.is_active === true
                            ? "🟢  Active"
                            : "🔴 Inactive"}{" "}
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
      {showAddEditModal && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-center m-4">
              <h1 className="text-xl text-gray-700 font-bold">
                {editingTransformation
                  ? "Edit Transformation"
                  : "Add Transformation"}{" "}
                {/* Updated heading */}
              </h1>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Transformation Name {/* Updated label */}
                </label>
                <input
                  name="transformation_name" // Updated name attribute
                  type="text"
                  value={formData.transformation_name}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter Transformation Name"
                  required
                />
              </div>
              <div className="m-20">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {editingTransformation
                    ? "Update Transformation"
                    : "Add Transformation"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransformationLeverDashboard;
