import React from 'react'
import TechnologyBanner from '../../components/technologies/TechnologyBanner'
import ProcessComponent2 from '../../components/technologies/ProcessComponent2'
import process_image from "../../assets/technologies/app-development-content-image.png"
import banner from "../../assets/technologies/application-development-services-page-banner.jpg"
import { Helmet } from 'react-helmet-async';

const Applicationdev = () => {

  const process = [
    {
      "title": "Expertise Across Platforms:",
      "description": "Our application development team has extensive experience in web application development, cloud application development, and mobile application development projects. Whether you need a custom application development solution or a complex enterprise application development project, we have the skills to deliver high-quality applications across various platforms."
    },
    {
      "title": "User-Centric Design:",
      "description": "We prioritize user experience in every project. Our team focuses on creating intuitive and engaging interfaces, ensuring that your applications are user-friendly and effective. From iPhone application development company services to bespoke custom application development, we design with the end-user in mind."
    },
    {
      "title": "Agile Development Methodology:",
      "description": "Utilizing agile methodologies, we ensure that our application development process is flexible and adaptive. This approach allows us to efficiently manage mobile application development projects and deliver solutions that meet your evolving business requirements."
    },
    {
      "title": "Robust Backend Development:",
      "description": "A solid backend is crucial for application performance and scalability. Our team excels in building robust and secure backends for all types of applications, including web application development and cloud application development projects."
    },
    {
      "title": "Quality Assurance & Testing:",
      "description": "We implement rigorous quality assurance and testing protocols to ensure that your applications are reliable and bug-free. From <b>enterprise application development</b> to smaller custom application development projects, we guarantee top-notch quality through comprehensive testing."
    },
    {
      "title": "Seamless Integration:",
      "description": "Our experts ensure that your new applications integrate seamlessly with existing systems and workflows. Whether it's web application development or cloud application development, we focus on creating cohesive and efficient solutions that enhance your business processes."
    },
    {
      "title": "Ongoing Support & Maintenance:",
      "description": "We provide continuous support and maintenance to keep your applications running smoothly. Our application development services include regular updates, security patches, and performance optimizations to ensure your applications remain up-to-date and secure."
    }
  ]


  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | Application Development</title>
      </Helmet>
      <div>
        <TechnologyBanner
          image={banner}
          alt={"Cyber Security"}
          title={"Application Development"}
          description={"Technical expertise with creative flair to deliver high quality mobile apps that drive engagement and enhance user experience."} />
      </div>

      <ProcessComponent2
        title={'Application Development Services'}
        image={process_image}
        alt={'app-development-content'}
        process={process}
      />
    </>
  )
}

export default Applicationdev