import React from "react";
import banner from "../../assets/technologies/rpa/rpa-page-banner.png";
import TechnologyBanner from "../../components/technologies/TechnologyBanner";
import ProcessComponent2 from "../../components/technologies/ProcessComponent2";
import process_image from "../../assets/technologies/system-integration-content-image.png";
import { Helmet } from "react-helmet-async";

const SystemIntegration = () => {
  const process = [
    {
      title: "Efficiency and Productivity:",
      description:
        "Experience unparalleled <b>efficiency and productivity</b> with our comprehensive <b>system integration services</b>. By integrating various business processes and applications, our solutions ensure streamlined operations and enhanced workflow. Whether you need <b>ERP system integration, software integration </b> or <b> integration technology</b> solutions, our expert system integrators can develop a customized approach tailored to your needs. This holistic approach minimizes manual interventions, reduces errors, and accelerates business processes.",
    },
    {
      title: "Data Accuracy and Insights:",
      description:
        "Achieve superior <b>data accuracy and insights</b> through our advanced <b>business system integration</b> services. Our <b>system integration development</b> ensures that data flows seamlessly between different applications, providing a unified view of your business operations. With precise and real-time data at your fingertips, you can make informed decisions, identify trends, and uncover actionable insights. Our <b>integration technology</b> enhances data consistency across your enterprise, empowering you to stay ahead in the competitive market.",
    },
    {
      title: "Cost Savings",
      description:
        "Our <b>system integration solutions</b> deliver significant <b>cost savings</b> by optimizing resource utilization and reducing operational expenses. By consolidating disparate systems and eliminating redundant processes, our <b>system integrator</b> services help you cut costs while maintaining high performance. Investing in <b>business system integration</b> not only reduces IT overhead but also lowers maintenance costs, providing you with a greater return on investment. With our efficient <b>software integration</b> strategies, you can maximize profitability and ensure long-term business success.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | System Integration</title>
      </Helmet>
      <TechnologyBanner
        image={banner}
        alt={"cyber_security"}
        title={"System Integration"}
        description={
          "Enhance operational efficiency, streamlining processes, and fostering seamless communication across various departments "
        }
      />

      <ProcessComponent2
        title={"Key Benefits of System Integration"}
        image={process_image}
        alt={"system-integration-content-image"}
        process={process}
      />
      <div className="flex items-center justify-center w-full mx-auto mt-2 p-4">
        <p className="text-center">
          Leverage our expertise in <b>system integration</b> to transform your
          business operations. Contact our team of experienced <b>system
          integrators</b> today to discover how our innovative solutions can benefit
          your organization.
        </p>
      </div>
    </>
  );
};

export default SystemIntegration;