import React, { useEffect, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import StarRating from "../components/common/StarRating";
import axios from "axios";
import { toast } from "react-hot-toast";
import Spinner from "../components/common/Spinner";
import ButtonSpinner from "../components/common/ButtonSpinner";
import checkRoleAccess from "../components/shared/CheckRoleAcess";

function Applicants() {
  const [applicantsData, setApplicantsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [round1Status, setRound1Status] = useState("");
  const [round1Feedback, setRound1Feedback] = useState("");
  const [round1marksobtained, setRound1MarksObtained] = useState("");
  const [round1totalmarks, setRound1TotalMarks] = useState("");
  const [round1percentage, setRound1percentage] = useState("");
  const [round2Status, setRound2Status] = useState("");
  const [round2Feedback, setRound2Feedback] = useState("");
  const [round3Status, setRound3Status] = useState("");
  const [round3Feedback, setRound3Feedback] = useState("");
  const [isFeedbackInProgress, setIsFeedbackInProgress] = useState(false);
  const [round1Open, setRound1Open] = useState(true);
  const [round2Open, setRound2Open] = useState(false);
  const [round3Open, setRound3Open] = useState(false);
  const [technicalParameters, setTechnicalParameters] = useState([]);
  const [round3technicalParameters, setRound3TechnicalParameters] = useState(
    []
  );
  const [ratings, setRatings] = useState(0);
  const [round3ratings, setround3ratings] = useState(0);
  const [loading, setLoading] = useState(false);
  const [panelist, setPanelist] = useState([]);
  const [seniorpanelist, setSeniorPanelist] = useState([]);
  const [activeTab, setActiveTab] = useState("internship");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const status = ["Pending", "On Hold", "Selected", "Rejected", "In Progress"];

  const handleModalOpen = (applicant) => {
    setSelectedApplicant(applicant);
    setRound1Status(applicant.round1_status || "");
    setRound1Feedback(applicant.round1_feedback || "");
    setRound2Status(applicant.round2_status || "");
    setRound2Feedback(applicant.round2_feedback || "");
    setRound3Status(applicant.round3_status || "");
    setRound3Feedback(applicant.round3_feedback || "");

    const initialRound2Ratings = {};
    applicant.ratings.forEach((rating) => {
      initialRound2Ratings[rating.tpm_id] = rating.round2_ratings;
    });
    setRatings(initialRound2Ratings);

    const initialRound3Ratings = {};
    applicant.round3_ratings.forEach((rating) => {
      initialRound3Ratings[rating.round3_tpm_id] = rating.round3_ratings;
    });
    setround3ratings(initialRound3Ratings);

    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedApplicant(null);
    setRound1Status("");
    setRound1Feedback("");
    setRound2Status("");
    setRound2Feedback("");
    setRound3Status("");
    setRound3Feedback("");
    setIsModalOpen(false);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredApplicants = applicantsData
    .filter((applicant) =>
      applicant.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleClickOutside = (e) => {
    if (e.target.id === "modal-overlay") {
      handleModalClose();
    }
  };

  const handleFeedbackSubmit = () => {
    setLoading(true);

    const round2Data = {
      id: selectedApplicant.id,
      user_id: localStorage.getItem("user_id"),
      round2_status: round2Status,
      round2_feedback: round2Feedback,
      ratings: Object.entries(ratings).map(([tpm_id, rating]) => ({
        tpm_id: tpm_id,
        rating: rating,
      })),
    };

    const round3Data = {
      id: selectedApplicant.id,
      user_id: localStorage.getItem("user_id"),
      round3_status: round3Status,
      round3_feedback: round3Feedback,
      ratings: Object.entries(round3ratings).map(([round3_tpm_id, rating]) => ({
        round3_tpm_id: round3_tpm_id,
        rating: rating,
      })),
    };

    //
    // Set feedback in progress
    setIsFeedbackInProgress(true);

    if (selectedApplicant.application_status === "technical") {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/auth/${
            activeTab === "internship" ? "update/round2" : "update/job/round2"
          }`,
          round2Data
        )
        .then(function (res) {
          setLoading(false);

          handleModalClose();
          toast.success("Feedback Submitted Successfully!");
          window.location.reload();
        })
        .catch(function (e) {
          setLoading(false);

          toast.error(e.response.data.error);
        });
    } else if (selectedApplicant.application_status === "final") {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/auth/${
            activeTab === "internship" ? "update/round3" : "update/job/round3"
          }`,
          round3Data
        )
        .then(function (res) {
          setLoading(false);

          handleModalClose();
          toast.success("Feedback Submitted Successfully!");
          window.location.reload();
        })
        .catch(function (e) {
          setLoading(false);

          toast.error(e.response.data.error);
        });
    }
  };

  const handleRate = (id, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [id]: rating,
    }));
  };

  const handleround3Rate = (id, rating) => {
    setround3ratings((prevRatings) => ({
      ...prevRatings,
      [id]: rating,
    }));
  };

  useEffect(() => {
    const role = [1, 2, 3, 5, 7];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/auth/${
          activeTab === "internship" ? "internshipdata" : "jobData"
        }/${localStorage.getItem("user_id")}`
      )
      .then(function (res) {
        setApplicantsData(res.data.data);
      })
      .catch(function (e) {
        console.error(e);
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/master/getpanelist`)
      .then(function (res) {
        setPanelist(res.data.data.panelist);
        setSeniorPanelist(res.data.data.senior_panelist);
      })
      .catch(function (e) {});

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/auth/get/round2/technical/parameters`
      )
      .then(function (res) {
        setTechnicalParameters(res.data.data);
      })
      .catch(function (e) {
        console.error(e);
      });

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/auth/get/round3/technical/parameters`
      )
      .then(function (res) {
        setRound3TechnicalParameters(res.data.data);
      })
      .catch(function (e) {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/auth/${
          activeTab === "internship" ? "internshipdata" : "jobData"
        }/${localStorage.getItem("user_id")}`
      )
      .then(function (res) {
        setApplicantsData(res.data.data);
      })
      .catch(function (e) {
        console.error(e);
      });
  }, [activeTab]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Assigned Applications</h1>

      {/* Tabs */}
      <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
        <ul
          className="flex flex-wrap -mb-px text-sm font-medium text-center"
          id="default-tab"
          role="tablist"
        >
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "internship"
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("internship")}
              type="button"
              role="tab"
              aria-controls="internship"
              aria-selected={activeTab === "internship"}
            >
              Internship
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "job"
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("job")}
              type="button"
              role="tab"
              aria-controls="job"
              aria-selected={activeTab === "job"}
            >
              Job
            </button>
          </li>
        </ul>
      </div>

      <div>
        <input
          type="text"
          placeholder="Search by name..."
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="px-4 py-2 mb-4 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
        />
      </div>

      {activeTab === "internship" ? (
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  College Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Course
                </th>
                <th scope="col" className="px-6 py-3">
                  Batch
                </th>
                <th scope="col" className="px-6 py-3">
                  Specialization
                </th>
                <th scope="col" className="px-6 py-3">
                  Application Status
                </th>
                <th scope="col" className="px-6 py-3">
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredApplicants.length > 0 ? (
                filteredApplicants.map((applicant, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {applicant.name}
                    </td>
                    <td className="px-6 py-4">{applicant.college_name}</td>
                    <td className="px-6 py-4">{applicant.course}</td>
                    <td className="px-6 py-4">{applicant.batch}</td>
                    <td className="px-6 py-4">{applicant.specialization}</td>
                    <td className="px-6 py-4">
                      {applicant.application_status}
                    </td>

                    <td className="px-6 py-4">
                      <button
                        onClick={() => handleModalOpen(applicant)}
                        className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        type="button"
                      >
                        <FaRegEye />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="px-6 py-4 text-center">
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Job Profile
                </th>
                <th scope="col" className="px-6 py-3">
                  Application Status
                </th>
                <th scope="col" className="px-6 py-3">
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredApplicants.length > 0 ? (
                filteredApplicants.map((applicant, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {applicant.name}
                    </td>
                    <td className="px-6 py-4">{applicant.job_profile}</td>
                    <td className="px-6 py-4">
                      {applicant.application_status}
                    </td>

                    <td className="px-6 py-4">
                      <button
                        onClick={() => handleModalOpen(applicant)}
                        className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        type="button"
                      >
                        <FaRegEye />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="px-6 py-4 text-center">
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {isModalOpen && selectedApplicant && (
        <div
          id="modal-overlay"
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm "
          onClick={handleClickOutside}
        >
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-3/5 ml-[250px] max-h-full overflow-y-auto">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Applicant Details
              </h3>
              <button
                type="button"
                onClick={handleModalClose}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {activeTab === "internship" ? (
              <div className="flex flex-col w-11/12 lg:w-3/3 mx-auto">
                <div className="grid md:grid-cols-3 gap-5 mt-5">
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Name: {selectedApplicant.name}
                  </p>

                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    College Name: {selectedApplicant.college_name}
                  </p>

                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Course: {selectedApplicant.course}
                  </p>
                </div>
                <div className="grid md:grid-cols-3 gap-5 mt-5">
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Resume:{" "}
                    <a
                      href={
                        process.env.REACT_APP_BASE_URL +
                        "/uploads/internship/" +
                        selectedApplicant.created_at.substring(0, 10) +
                        "/" +
                        selectedApplicant.resume
                      }
                      target="_blank"
                      className="text-blue-600 hover:underline"
                    >
                      View Resume
                    </a>
                  </p>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Batch: {selectedApplicant.batch}
                  </p>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Specialization: {selectedApplicant.specialization}
                  </p>
                </div>

                <div
                  className="cursor-pointer flex justify-between bg-[#f5f5f5] p-[10px] border items-center mt-[15px] mb-[5px]"
                  onClick={() => setRound1Open(!round1Open)}
                >
                  <span className="text-xl font-bold ">Written Test</span>
                  <span className="text-xl font-bold">
                    {round1Open ? <HiChevronUp /> : <HiChevronDown />}
                  </span>
                </div>

                {round1Open && (
                  <div className="border rounded p-4 mb-4">
                    <div className="flex flex-col gap-2">
                      <label htmlFor="round1Status">Status*</label>
                      <select
                        id="round1Status"
                        value={selectedApplicant.round1Status || round1Status}
                        disabled={
                          selectedApplicant.round1Status != null ||
                          selectedApplicant.round1Status != ""
                        }
                        onChange={(e) => setRound1Status(e.target.value)}
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        {status.map((round1Status, index) => (
                          <option key={index} value={round1Status}>
                            {round1Status}
                          </option>
                        ))}
                      </select>
                      {/* {errors.round2 && <span className="text-red-500">{errors.round2}</span>} */}
                    </div>
                    <div className="grid md:grid-cols-3 gap-5 mt-5">
                      <div className="flex flex-col gap-2">
                        <label htmlFor="round1_marks_obtained">
                          Marks Obtained*
                        </label>
                        <input
                          type="text"
                          id="round1_marks_obtained"
                          value={
                            selectedApplicant.round1_marks_obtained ||
                            round1marksobtained
                          }
                          disabled={
                            selectedApplicant.round1_marks_obtained != null ||
                            selectedApplicant.round1_marks_obtained != ""
                          }
                          onChange={(e) =>
                            setRound1MarksObtained(e.target.value)
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label htmlFor="round1_total_marks">
                          Maximum Marks*
                        </label>
                        <input
                          type="text"
                          id="round1_total_marks"
                          value={
                            selectedApplicant.round1_total_marks ||
                            round1totalmarks
                          }
                          disabled={
                            selectedApplicant.round1_total_marks != null ||
                            selectedApplicant.round1_total_marks != ""
                          }
                          onChange={(e) => setRound1TotalMarks(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label htmlFor="round1_percentage">Percentage*</label>
                        <input
                          type="text"
                          id="round1_percentage"
                          value={
                            selectedApplicant.round1_percentage ||
                            round1percentage
                          }
                          disabled={
                            selectedApplicant.round1_percentage != null ||
                            selectedApplicant.round1_percentage != ""
                          }
                          onChange={(e) => setRound1percentage(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <div className='flex flex-col gap-2 mt-5'>
                    <label htmlFor="Round1Feedback">Feedback* </label>
                    <textarea
                      type="text"
                      maxLength={100}
                      value={selectedApplicant.round1feedback || round1Feedback}
                      id="message"
                      disabled={selectedApplicant.round1feedback != null || selectedApplicant.round1feedback != ''}
                      onChange={(e) => setRound1Feedback(e.target.value)


                      } />
                  </div> */}
                  </div>
                )}

                <div
                  className="cursor-pointer flex justify-between bg-[#f5f5f5] p-[10px] border items-center mt-[15px] mb-[5px]"
                  onClick={() => setRound2Open(!round2Open)}
                >
                  <span className="text-xl font-bold ">Technical Round</span>
                  <span className="text-xl font-bold">
                    {round2Open ? <HiChevronUp /> : <HiChevronDown />}
                  </span>
                </div>

                {round2Open && (
                  <div className="border rounded p-4 mb-4">
                    {/* Round 2 */}
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-2">
                        <label
                          htmlFor="round2Status"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Status*
                        </label>
                        <select
                          id="round2Status"
                          value={round2Status || selectedApplicant.round2status}
                          disabled={
                            selectedApplicant.round2_status === "Selected"
                          }
                          onChange={(e) => setRound2Status(e.target.value)}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                          <option value="" disabled>
                            Select Status
                          </option>
                          {status.map((round2Status, index) => (
                            <option key={index} value={round2Status}>
                              {round2Status}
                            </option>
                          ))}
                        </select>
                        {/* {errors.round2 && <span className="text-red-500">{errors.round2}</span>} */}
                      </div>

                      <div className="col-span-2">
                        <label
                          htmlFor="round2assignedpanelist"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Assigned Panelist*
                        </label>
                        <input
                          type="text"
                          id="round2assignedpanelist"
                          value={
                            panelist.find(
                              (panelist) =>
                                panelist.id ===
                                +selectedApplicant.round2_assigned_panelist
                            )?.name || "Not Assigned Yet"
                          }
                          disabled={true}
                          className="mt-1 block w-full pl-3 pr-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 mt-5">
                      <label htmlFor="round2Status">
                        Technical Parameters*
                      </label>
                      {technicalParameters.map((item) => (
                        <div
                          key={item.tpm_id}
                          className="flex flex-row items-center justify-between"
                        >
                          <label>{item.parameter}:</label>
                          <StarRating
                            totalStars={5}
                            rating={ratings[item.tpm_id]}
                            onRate={(rating) =>
                              selectedApplicant.round2_status === "Selected"
                                ? null
                                : handleRate(item.tpm_id, rating)
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-2 mt-5">
                      <label htmlFor="Round2Feedback">Feedback* </label>
                      <textarea
                        type="text"
                        maxLength={1000}
                        id="message"
                        value={round2Feedback}
                        disabled={
                          selectedApplicant.round2_status === "Selected"
                        }
                        onChange={(e) => setRound2Feedback(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                <div
                  className="cursor-pointer flex justify-between bg-[#f5f5f5] p-[10px] border items-center mt-[15px] mb-[5px]"
                  onClick={() => setRound3Open(!round3Open)}
                >
                  <span className="text-xl font-bold ">Final Round</span>
                  <span className="text-xl font-bold">
                    {round3Open ? <HiChevronUp /> : <HiChevronDown />}
                  </span>
                </div>

                {round3Open && (
                  <div className="border rounded p-4 mb-4">
                    {/* Round 3 */}
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-2">
                        <div className="col-span-2">
                          <label
                            htmlFor="round3Status"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Status*
                          </label>
                          <select
                            id="round3Status"
                            value={
                              round3Status || selectedApplicant.round3status
                            }
                            disabled={
                              selectedApplicant.round3_status === "Selected"
                            }
                            onChange={(e) => setRound3Status(e.target.value)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option value="" disabled>
                              Select Status
                            </option>
                            {status.map((round2Status, index) => (
                              <option key={index} value={round2Status}>
                                {round2Status}
                              </option>
                            ))}
                          </select>
                          {/* {errors.round2 && <span className="text-red-500">{errors.round2}</span>} */}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <label
                          htmlFor="round3assignedpanelist"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Assigned Panelist*
                        </label>
                        <input
                          type="text"
                          id="round3assignedpanelist"
                          value={
                            panelist.find(
                              (seniorpanelist) =>
                                seniorpanelist.id ===
                                +selectedApplicant.round3_assigned_panelist
                            )?.name || "Not Assigned Yet"
                          }
                          disabled={true}
                          className="mt-1 block w-full pl-3 pr-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 mt-5">
                      <label>Technical Parameters*</label>
                      {round3technicalParameters.map((items) => (
                        <div
                          key={items.round3_tpm_id}
                          className="flex flex-row items-center justify-between"
                        >
                          <label>{items.parameter}:</label>
                          <StarRating
                            totalStars={5}
                            rating={round3ratings[items.round3_tpm_id] || 0}
                            onRate={(rating) =>
                              selectedApplicant.round3_status === "Selected"
                                ? null
                                : handleround3Rate(items.round3_tpm_id, rating)
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-2 mt-5">
                      <label htmlFor="Round3Feedback">Feedback* </label>
                      <textarea
                        type="text"
                        maxLength={1000}
                        id="message"
                        value={round3Feedback}
                        disabled={
                          selectedApplicant.round3_status === "Selected"
                        }
                        onChange={(e) => setRound3Feedback(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col w-11/12 lg:w-3/3 mx-auto">
                <div className="grid md:grid-cols-3 gap-5 mt-5">
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Name: {selectedApplicant.name}
                  </p>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Job Profile: {selectedApplicant.job_profile}
                  </p>
                </div>
                <div className="grid md:grid-cols-3 gap-5 mt-5">
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Resume:{" "}
                    <a
                      href={
                        process.env.REACT_APP_BASE_URL +
                        "/uploads/job/" +
                        selectedApplicant.created_at.substring(0, 10) +
                        "/" +
                        selectedApplicant.resume
                      }
                      target="_blank"
                      className="text-blue-600 hover:underline"
                    >
                      View Resume
                    </a>
                  </p>
                </div>

                <div
                  className="cursor-pointer flex justify-between bg-[#f5f5f5] p-[10px] border items-center mt-[15px] mb-[5px]"
                  onClick={() => setRound1Open(!round1Open)}
                >
                  <span className="text-xl font-bold ">Written Test</span>
                  <span className="text-xl font-bold">
                    {round1Open ? <HiChevronUp /> : <HiChevronDown />}
                  </span>
                </div>

                {round1Open && (
                  <div className="border rounded p-4 mb-4">
                    <div className="flex flex-col gap-2">
                      <label htmlFor="round1Status">Status*</label>
                      <select
                        id="round1Status"
                        value={selectedApplicant.round1Status || round1Status}
                        disabled={
                          selectedApplicant.round1Status != null ||
                          selectedApplicant.round1Status != ""
                        }
                        onChange={(e) => setRound1Status(e.target.value)}
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        {status.map((round1Status, index) => (
                          <option key={index} value={round1Status}>
                            {round1Status}
                          </option>
                        ))}
                      </select>
                      {/* {errors.round2 && <span className="text-red-500">{errors.round2}</span>} */}
                    </div>
                    <div className="grid md:grid-cols-3 gap-5 mt-5">
                      <div className="flex flex-col gap-2">
                        <label htmlFor="round1_marks_obtained">
                          Marks Obtained*
                        </label>
                        <input
                          type="text"
                          id="round1_marks_obtained"
                          value={
                            selectedApplicant.round1_marks_obtained ||
                            round1marksobtained
                          }
                          disabled={
                            selectedApplicant.round1_marks_obtained != null ||
                            selectedApplicant.round1_marks_obtained != ""
                          }
                          onChange={(e) =>
                            setRound1MarksObtained(e.target.value)
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label htmlFor="round1_total_marks">
                          Maximum Marks*
                        </label>
                        <input
                          type="text"
                          id="round1_total_marks"
                          value={
                            selectedApplicant.round1_total_marks ||
                            round1totalmarks
                          }
                          disabled={
                            selectedApplicant.round1_total_marks !== null ||
                            selectedApplicant.round1_total_marks !== ""
                          }
                          onChange={(e) => setRound1TotalMarks(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label htmlFor="round1_percentage">Percentage*</label>
                        <input
                          type="text"
                          id="round1_percentage"
                          value={
                            selectedApplicant.round1_percentage.toFixed(2) ||
                            round1percentage
                          }
                          disabled={
                            selectedApplicant.round1_percentage !== null ||
                            selectedApplicant.round1_percentage !== ""
                          }
                          onChange={(e) => setRound1percentage(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <div className='flex flex-col gap-2 mt-5'>
                    <label htmlFor="Round1Feedback">Feedback* </label>
                    <textarea
                      type="text"
                      maxLength={100}
                      value={selectedApplicant.round1feedback || round1Feedback}
                      id="message"
                      disabled={selectedApplicant.round1feedback != null || selectedApplicant.round1feedback != ''}
                      onChange={(e) => setRound1Feedback(e.target.value)


                      } />
                  </div> */}
                  </div>
                )}

                <div
                  className="cursor-pointer flex justify-between bg-[#f5f5f5] p-[10px] border items-center mt-[15px] mb-[5px]"
                  onClick={() => setRound2Open(!round2Open)}
                >
                  <span className="text-xl font-bold ">Technical Round</span>
                  <span className="text-xl font-bold">
                    {round2Open ? <HiChevronUp /> : <HiChevronDown />}
                  </span>
                </div>

                {round2Open && (
                  <div className="border rounded p-4 mb-4">
                    {/* Round 2 */}
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-2">
                        <label
                          htmlFor="round2Status"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Status*
                        </label>
                        <select
                          id="round2Status"
                          value={round2Status || selectedApplicant.round2status}
                          disabled={
                            selectedApplicant.round2_status === "Selected"
                          }
                          onChange={(e) => setRound2Status(e.target.value)}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                          <option value="" disabled>
                            Select Status
                          </option>
                          {status.map((round2Status, index) => (
                            <option key={index} value={round2Status}>
                              {round2Status}
                            </option>
                          ))}
                        </select>
                        {/* {errors.round2 && <span className="text-red-500">{errors.round2}</span>} */}
                      </div>

                      <div className="col-span-2">
                        <label
                          htmlFor="round2assignedpanelist"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Assigned Panelist*
                        </label>
                        <input
                          type="text"
                          id="round2assignedpanelist"
                          value={
                            panelist.find(
                              (panelist) =>
                                panelist.id ===
                                +selectedApplicant.round2_assigned_panelist
                            )?.name || "Not Assigned Yet"
                          }
                          disabled={true}
                          className="mt-1 block w-full pl-3 pr-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 mt-5">
                      <label htmlFor="round2Status">
                        Technical Parameters*
                      </label>
                      {technicalParameters.map((item) => (
                        <div
                          key={item.tpm_id}
                          className="flex flex-row items-center justify-between"
                        >
                          <label>{item.parameter}:</label>
                          <StarRating
                            totalStars={5}
                            rating={ratings[item.tpm_id]}
                            onRate={(rating) =>
                              selectedApplicant.round2_status === "Selected"
                                ? null
                                : handleRate(item.tpm_id, rating)
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-2 mt-5">
                      <label htmlFor="Round2Feedback">Feedback* </label>
                      <textarea
                        type="text"
                        maxLength={1000}
                        id="message"
                        value={round2Feedback}
                        disabled={
                          selectedApplicant.round2_status === "Selected"
                        }
                        onChange={(e) => setRound2Feedback(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                <div
                  className="cursor-pointer flex justify-between bg-[#f5f5f5] p-[10px] border items-center mt-[15px] mb-[5px]"
                  onClick={() => setRound3Open(!round3Open)}
                >
                  <span className="text-xl font-bold ">Final Round</span>
                  <span className="text-xl font-bold">
                    {round3Open ? <HiChevronUp /> : <HiChevronDown />}
                  </span>
                </div>

                {round3Open && (
                  <div className="border rounded p-4 mb-4">
                    {/* Round 3 */}
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-2">
                        <div className="col-span-2">
                          <label
                            htmlFor="round3Status"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Status*
                          </label>
                          <select
                            id="round3Status"
                            value={
                              round3Status || selectedApplicant.round3status
                            }
                            disabled={
                              selectedApplicant.round3_status === "Selected"
                            }
                            onChange={(e) => setRound3Status(e.target.value)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option value="" disabled>
                              Select Status
                            </option>
                            {status.map((round2Status, index) => (
                              <option key={index} value={round2Status}>
                                {round2Status}
                              </option>
                            ))}
                          </select>
                          {/* {errors.round2 && <span className="text-red-500">{errors.round2}</span>} */}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <label
                          htmlFor="round3assignedpanelist"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Assigned Panelist*
                        </label>
                        <input
                          type="text"
                          id="round3assignedpanelist"
                          value={
                            panelist.find(
                              (seniorpanelist) =>
                                seniorpanelist.id ===
                                +selectedApplicant.round3_assigned_panelist
                            )?.name || "Not Assigned Yet"
                          }
                          disabled={true}
                          className="mt-1 block w-full pl-3 pr-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 mt-5">
                      <label>Technical Parameters*</label>
                      {round3technicalParameters.map((items) => (
                        <div
                          key={items.round3_tpm_id}
                          className="flex flex-row items-center justify-between"
                        >
                          <label>{items.parameter}:</label>
                          <StarRating
                            totalStars={5}
                            rating={round3ratings[items.round3_tpm_id] || 0}
                            onRate={(rating) =>
                              selectedApplicant.round3_status === "Selected"
                                ? null
                                : handleround3Rate(items.round3_tpm_id, rating)
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-2 mt-5">
                      <label htmlFor="Round3Feedback">Feedback* </label>
                      <textarea
                        type="text"
                        maxLength={1000}
                        id="message"
                        value={round3Feedback}
                        disabled={
                          selectedApplicant.round3_status === "Selected"
                        }
                        onChange={(e) => setRound3Feedback(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              {/* <button
                onClick={handleModalClose}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Close
              </button> */}
              <button
                onClick={handleFeedbackSubmit}
                className={`w-screen text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
              >
                {loading ? <ButtonSpinner /> : "Submit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Applicants;
