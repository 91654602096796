import CryptoJS from "crypto-js";

const encryptText = (text) => {
   
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();
  return encrypted;
};

export { encryptText };
