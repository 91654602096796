import React from 'react'
import banking_image from '../../assets/home/industries/banking.png'
import healthcare_image from '../../assets/home/industries/healthcare.png'
import insurance_image from '../../assets/home/industries/insurance.png'
import manufacturing_image from '../../assets/home/industries/manufacturing.png'
import supply_chain_image from '../../assets/home/industries/supply-chain-management.png'
import telecom_image from '../../assets/home/industries/telecom-icon.png'
import background_image from '../../assets/home/ricardo-gomez-angel.jpg'

const data = [
    { image: banking_image, alt: 'banking', title: 'Banking' },
    { image: healthcare_image, alt: 'healthcare', title: 'Healthcare' },
    { image: insurance_image, alt: 'insurance', title: 'Insurance' },
    { image: manufacturing_image, alt: 'manufacturing', title: 'Manufacturing' },
    { image: supply_chain_image, alt: 'supply-chain-management', title: 'Supply Chain' },
    { image: telecom_image, alt: 'telecom', title: 'Telecom' },
];

const IndustrySection = () => {
    return (
        <section className='relative bg-cover bg-center' style={{ backgroundImage: `url(${background_image})` }}>
            <div className='bg-black w-full h-full bg-opacity-50 flex flex-col items-center space-y-6 lg:py-24 py-10'>
                <div className='backdrop-blur-sm h-full w-11/12 mx-auto'>
                    <div className='flex flex-col items-center space-y-2 text-white'>
                        <h1 className='text-[3.2rem] font-bold text-center'>Our <span className='text-primary'>Industries</span></h1>
                        <p className='text-lg font-bold'>Industries we have mastered</p>
                        <div className='h-1 w-14 bg-primary'></div>
                        <p className='text-lg mt-2 text-center'>Delivering products by combining industry transformation with our expertise technologies</p>
                    </div>

                    <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-x-20 gap-y-10 py-6 w-full mt-4 md:mt-8'>
                        {
                            data.map(({ image, alt, title }) => {
                                return (

                                    <div key={alt} className='flex gap-4 justify-center items-center px-6 py-4 bg-white rounded-md'>
                                        <img src={image} alt={alt} loading='lazy' width={50} />
                                        <h1 className='text-2xl font-medium'>{title}</h1>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IndustrySection