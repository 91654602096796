import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  projectEndPoints,
  processEndPoints,
  taskAssignmentEndPoints,
  timesheetEndPoints,
} from "../../services/apis";
import NoTaskFound from "../taskMaster/NoTaskFound";
import { HelmetProvider, Helmet } from "react-helmet-async";
import checkRoleAccess from "../shared/CheckRoleAcess";

const TimesheetDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [projectData, setProjectData] = useState([]);
  const [processData, setProcessData] = useState([]);
  const [filteredProcessData, setFilteredProcessData] = useState([]);
  const [TaskData, setTaskData] = useState([]);
  const [filteredTaskData, setFilteredTaskData] = useState([]);
  const [timesheetData, setTimesheetData] = useState([]);
  const [userId, setUserId] = useState(null);

  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState({
    task_id: "",
    project_id: "",
    process_id: "",
    effort_time: "",
    description: "",
    status: "in process",
    date: "",
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingTask, setEditingTask] = useState(null);

  const [timesheetEntries, setTimesheetEntries] = useState([]);

  const obj = [
    {
      ProjectName: "Estee Lauder",
      ProcessName: "Onbording",
      TaskName: "Database Backup",
      EmployeeName: "Neelesh Aithani",
    },
    {
      ProjectName: "Cooper Standard",
      ProcessName: "Marketing Campaigns",
      TaskName: "Sample Task",
      EmployeeName: "Neelesh Aithani",
    },
    {
      ProjectName: "Estee Lauder",
      ProcessName: "Onbording",
      TaskName: "Database Backup",
      EmployeeName: "Kumar Aniket",
    },
  ];

  // Optimized filtering logic
  const filteredTimesheetData = timesheetData.filter(
    (timesheet) =>
      timesheet.project_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      timesheet.process_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      timesheet.task_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      timesheet.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingTask(null);
  };

  const openAddEditModal = (task) => {
    const {
      task_id,
      project_id,
      process_id,
      effort_time,
      description,
      status,
      date,
    } = task;
    setFormData({
      task_id: task_id || "",
      project_id: project_id || "",
      process_id: process_id || "",
      effort_time: effort_time || "",
      description: description || "",
      status: status || "in process",
      date: date || "",
    });
    setEditingTask(task.task_id ? true : false);

    // Filter the processes and tasks when editing
    if (project_id) {
      setFilteredProcessData(
        processData.filter((process) => process.project_id === project_id)
      );
    }
    if (process_id) {
      setFilteredTaskData(
        TaskData.filter((task) => task.process_id === process_id)
      );
    }

    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "project_id") {
      const filteredProcesses = processData.filter(
        (process) => process.project_id === value
      );
      setFilteredProcessData(filteredProcesses);
    }

    if (name === "process_id") {
      const filteredTasks = TaskData.filter(
        (task) => task.process_id === value
      );
      setFilteredTaskData(filteredTasks);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = { user_id: userId, ...formData };

    try {
      if (editingTask) {
        const response = await axios.post(
          taskAssignmentEndPoints.POST_UPDATE_TASK,
          updatedFormData
        );
        toast.success(response.data.data.messageCode);
      } else {
        const response = await axios.post(
          timesheetEndPoints.POST_TIMESHEET_DETAILS,
          updatedFormData
        );
        toast.success(response.data.data.messageCode);
      }
      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      toast.error(error.response.data.data.messageCode);
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(
        projectEndPoints.GET_ALL_ACTIVE_PROJECTS
      );
      setProjectData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchProcessData = async () => {
    try {
      const response = await axios.get(
        processEndPoints.GET_ALL_ACTIVE_PROCESSES
      );
      setProcessData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching processes:", error);
    }
  };

  const fetchTaskData = async () => {
    try {
      const response = await axios.get(
        `${taskAssignmentEndPoints.GET_TASK_ASSIGN_ASSIGNMENT_BY_ID}/${userId}`
      );
      setTaskData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchTimesheetData = async () => {
    try {
      const response = await axios.get(timesheetEndPoints.GET_ALL_TIMESHEETS);
      setTimesheetData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };
  const fetchTimesheetEntries = async () => {
    try {
      const response = await axios.get(
        taskAssignmentEndPoints.GET_ALL_TIMESHEET_ENTRIES
      );
      setTimesheetEntries(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching timesheet entries:", error);
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    setUserId(storedUserId);
    fetchProjectData();
    fetchProcessData();
    fetchTaskData();
    fetchTimesheetData();
  }, [updateUI, userId]);

  useEffect(() => {
    const role = [1, 2, 7, 8];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);
  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>MSL - Timesheet</title>
        </Helmet>
      </HelmetProvider>
      <h1 className="text-4xl font-bold mb-4">Timesheet</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for timesheet details"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a project"
        />
        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({})} // Changed to open an empty modal
            className="bg-blue-500 text-white px-8 py-2 font-bold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            aria-label="Add Project"
          >
            + Create Timesheet
          </button>
        </div>
      </div>
      {filteredTimesheetData.length === 0 ? (
        <NoTaskFound />
      ) : (
        <div className="overflow-auto max-h-[800px]">
          <table className="w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Employee Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Project Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Process Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Task Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  ETA (hour)
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Effort Time
                </th>

                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Date
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Status
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredTimesheetData.map((task, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.project_name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.process_name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.task_name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.task_eta}
                  </td>

                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.effort_time}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {new Date(task.date).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.status}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {task.description}
                  </td>
                  {/* <td className="border border-gray-300 p-4 text-gray-700"> 
                    <div className="flex gap-4">
                      <button
                        onClick={() => openAddEditModal(task)}
                        className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                      >
                        <MdEdit /> Edit
                      </button>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}{" "}
      {showAddEditModal && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto relative">
            <button
              onClick={closeModals}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            <h1 className="text-2xl text-center font-semibold text-gray-800 mb-6">
              {editingTask ? "Edit Timesheet" : "Add Timesheet"}
            </h1>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Project Name
                  </label>
                  <select
                    name="project_id"
                    value={formData.project_id}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  >
                    <option value="">Select Project</option>
                    {projectData.map((project) => (
                      <option
                        key={project.project_id}
                        value={project.project_id}
                      >
                        {project.project_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Process Name
                  </label>
                  <select
                    name="process_id"
                    value={formData.process_id}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  >
                    <option value="">Select Process</option>
                    {filteredProcessData.map((process) => (
                      <option
                        key={process.process_id}
                        value={process.process_id}
                      >
                        {process.process_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Task Name
                  </label>
                  <select
                    name="task_id"
                    value={formData.task_id}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  >
                    <option value="">Select Task</option>
                    {filteredTaskData.map((task) => (
                      <option key={task.task_id} value={task.task_id}>
                        {task.task_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Effort Time (in hours)
                </label>
                <input
                  type="number"
                  name="effort_time"
                  value={formData.effort_time}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  min="1"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Status
                </label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                >
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full py-3 px-4 bg-indigo-600 text-white rounded-lg font-semibold hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  {editingTask ? "Update Timesheet" : "Add Timesheet"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimesheetDashboard;
