import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import ButtonSpinner from "../components/common/ButtonSpinner";
import moment from "moment";

const tableHeading = [
  "fromDate",
  "toDate",
  "days",
  "reason",
  "status",
  "dateOfRequest",
];

const WorkFromHome = () => {
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRequestIndex, setSelectedRequestIndex] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchTableData = async () => {
    setLoading(true);
    try {
      const user_id = localStorage.getItem('user_id');
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/workFromHome/get`,{user_id});
      if (response.data && response.data.data) {
        const today = moment().startOf('day'); 
  
        const dataWithDays = response.data.data
          .map((item) => {
            // Parse dates with moment
            const fromDate = moment(item.from).startOf('day');
            const toDate = moment(item.to).startOf('day');
            const dateOfRequest = moment(item.dateOfRequest).startOf('day');
  
            // Skip if end date is before today
            if (toDate.isBefore(today)) {
              
              return null;
            }
  
            // Adjust fromDate if it is before today
            const validFromDate = fromDate.isBefore(today) ? today : fromDate;
  
            // Calculate total working days
            let totalDays = 0;
            let currentDate = validFromDate.clone();
  
            while (currentDate.isSameOrBefore(toDate)) {
              const dayOfWeek = currentDate.day();
              if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Exclude Saturday (6) and Sunday (0)
                totalDays++;
              }
              currentDate.add(1, 'day');
            }
  
            // Return the adjusted item
            return {
              ...item,
              fromDate: validFromDate.format('DD-MM-YYYY'),
              toDate: toDate.format('DD-MM-YYYY'),
              dateOfRequest: dateOfRequest.format('DD-MM-YYYY'),
              days: totalDays,
            };
          })
          .filter(item => item !== null) // Remove null entries
          .sort((a, b) => moment(b.fromDate, 'DD-MM-YYYY') - moment(a.fromDate, 'DD-MM-YYYY')); // Sort in descending order
  
        setTableData(dataWithDays);
      } else {
        toast.error("Unexpected data format received.");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      toast.error("Failed to fetch work-from-home requests.");
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchTableData();
  }, []);

  // Fetch data function as defined above

  const handleCancelButtonClick = (index) => {
    setSelectedRequestIndex(index);
    setShowConfirmModal(true);
  };

  const handleConfirmCancel = async () => {
    setLoading(true);
    const tempData = [...tableData];
    const selectedRequest = tempData[selectedRequestIndex];
    selectedRequest.status = "Cancelled";

    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/workFromHome//update/request`,
        {
          id: selectedRequest.id,
          status: "Cancelled",
          user_id: selectedRequest.user_id,
        }
      );      
      toast.success("Work-from-home request cancelled successfully");
      setTableData(tempData);
    } catch (error) {
      console.error("Cancel Error:", error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
      setShowConfirmModal(false);
    }
  };

  const handleCancelModalClose = () => {
    setShowConfirmModal(false);
  };

  const handleOnClose = (e) => {
    if (e.target.id === "outside") {
      setShowModal(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!fromDate || !toDate || !reason) {
      toast.error("All fields are required.");
      setLoading(false);
      return;
    }
    const user_id = localStorage.getItem("user_id");
    const data = {
      fromDate,
      toDate,
      reason,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/workFromHome/work-from-home/${user_id}`,
        data
      );
      if (response.status === 201) {
        toast.success("Work from home request submitted successfully!");
        setFromDate("");
        setToDate("");
        setReason("");
        setShowModal(false);
      } else {
        toast.error("Failed to submit the request.");
      }
    } catch (error) {
      console.error("Submit Error:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-between p-3 w-full">
        <h1 className="uppercase text-xl">Work From Home</h1>
        <button
          onClick={() => setShowModal(true)}
          className="py-1 px-2 rounded-md bg-primary text-white/80 text-xl uppercase cursor-pointer"
        >
          Add
        </button>
      </div>

      <div className="relative mt-12 overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className="text-center">
              <th scope="col" className="px-6 py-3">S. No</th>
              {tableHeading.map((item, index) => (
                <th scope="col" className="px-6 py-3" key={index}>
                  {item.split(/(?=[A-Z])/).join(" ")}
                </th>
              ))}
              <th scope="col" className="px-6 py-3">Cancel</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr className="bg-white border-b" key={index}>
                <td className="px-6 py-4 text-center w-2/12">{index + 1}.</td>
                {tableHeading.map((field, index) => (
                  <td className="px-6 py-4 text-center" key={index}>
                    <div className="whitespace-nowrap">
                      {item[field] && item[field].length > 30
                        ? item[field].slice(0, 27) + "..."
                        : item[field] || ""}
                    </div>
                  </td>
                ))}
                <td className="px-6 py-4 text-center">
                  <button
                    onClick={() => handleCancelButtonClick(index)}
                    className="py-1 px-2 rounded-md bg-primary text-white/80 cursor-pointer"
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-[40] flex justify-center items-center"
          id="outside"
          onClick={handleOnClose}
        >
          <div className="shadow-lg h-4/5 w-6/12 mx-auto bg-white rounded-xl p-8 overflow-y-scroll z-[40000]">
            <div className="text-center text-3xl font-bold mb-8">Apply Work From Home</div>

            <form onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <div className="flex justify-between flex-wrap">
                  <div className="flex flex-col gap-2 w-[49%]">
                    <label htmlFor="fromDate">From:</label>
                    <input
                      type="date"
                      id="fromDate"
                      name="fromDate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      className="border p-2 rounded"
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-[49%]">
                    <label htmlFor="toDate">To:</label>
                    <input
                      type="date"
                      id="toDate"
                      name="toDate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      className="border p-2 rounded"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2 mt-4">
                  <label htmlFor="reason">Reason:</label>
                  <textarea
                    id="reason"
                    name="reason"
                    rows="4"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="border p-2 rounded"
                  />
                </div>

                <div className="flex justify-between gap-2 mt-4">
                  <button
                    type="submit"
                    className="py-2 px-4 rounded-md bg-primary text-white cursor-pointer"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="py-2 px-4 rounded-md bg-gray-300 text-black cursor-pointer"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {showConfirmModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-[40] flex justify-center items-center"
          id="outside"
          onClick={handleOnClose}
        >
          <div className="shadow-lg w-6/12 mx-auto bg-white rounded-xl p-8">
            <div className="text-center text-xl font-bold mb-4">Are you sure you want to cancel this request?</div>
            <div className="flex justify-center gap-4">
              <button
                onClick={handleConfirmCancel}
                className="py-2 px-4 rounded-md bg-primary text-white cursor-pointer"
              >
                Yes
              </button>
              <button
                onClick={handleCancelModalClose}
                className="py-2 px-4 rounded-md bg-gray-300 text-black cursor-pointer"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {loading && <ButtonSpinner />}
    </div>
  );
};

export default WorkFromHome;
