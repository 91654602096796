import React from "react";
import TechnologyBanner from "../../components/technologies/TechnologyBanner";
import banner from "../../assets/technologies/cyberSecurity/cybersecurity_banner.jpeg"
import imgbanner from "../../assets/technologies/cybersecurity_banner.jpeg"
import process_image from "../../assets/technologies/cyber-security-content-image.jpeg"
import { Helmet } from 'react-helmet-async';

const Cyber = () => {
  const process = [
    {
      title: 'Cybersecurity',
      description: '<b>Cybersecurity</b> encompasses the measures and practices designed to protect systems, networks, and data from cyber threats. By implementing robust cybersecurity strategies, organizations can defend against cyber attacks and mitigate the risk of security breaches.'
    },
    {
      title: 'Information Security',
      description: '<b>Information Security</b> focuses on protecting sensitive data from unauthorized access and ensuring its confidentiality, integrity, and availability. Effective information security measures are critical for maintaining trust and compliance in today’s data-driven environment.'
    },
    {
      title: 'Network Security',
      description: '<b>Network Security</b> involves securing the infrastructure that connects your digital systems. By deploying firewalls, intrusion detection systems, and other network security tools, your organization can prevent unauthorized access and safeguard against <b>cyber threats</b>.'
    },
    {
      title: 'Data Protection',
      description: '<b>Data Protection</b> is crucial for maintaining the privacy and security of personal and corporate information. Techniques such as encryption and data loss prevention (DLP) ensure that sensitive data is secure, both at rest and in transit.'
    },
    {
      title: 'Cyber Threats',
      description: '<b>Cyber threats</b> are constantly evolving, with hackers employing increasingly sophisticated methods to breach defenses. Proactively identifying and addressing these cyber threats is essential for preventing cyber attacks and minimizing potential damage.'
    },
    {
      title: 'Cyber Attacks',
      description: '<b>Cyber attacks</b> can have devastating consequences, including financial loss, reputational damage, and operational disruption. Defending against these attacks requires a combination of technology, expertise, and vigilant monitoring to quickly identify and respond to threats.'
    },
    {
      title: 'Security Breaches',
      description: '<b>Security breaches</b> can compromise sensitive information and disrupt business operations. A proactive approach to cybersecurity helps detect and prevent breaches, protecting your organization from the significant impacts of data theft and system compromise.'
    },
    {
      title: 'Endpoint Security',
      description: '<b>Endpoint Security</b> focuses on securing end-user devices such as laptops, smartphones, and tablets. By employing endpoint protection solutions, your organization can safeguard against malware and other threats that target these vulnerable points of entry.'
    },
    {
      title: 'Encryption',
      description: '<b>Encryption</b> is a vital component of data protection, transforming information into a secure format that can only be accessed by authorized parties. Using strong encryption techniques ensures that your data remains confidential and protected from unauthorized access.'
    },
    {
      title: 'Malware Protection',
      description: '<b>Malware protection</b> involves defending against malicious software designed to infiltrate and damage systems. Implementing robust anti-malware solutions is essential for detecting, preventing, and removing malware, ensuring your digital assets remain secure.'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | Cyber Security</title>
      </Helmet>

      <TechnologyBanner
        image={imgbanner}
        alt={"Cyber Security"}
        title={"Cyber Security"}
        description={"Protecting your digital assets, sensitive data, and privacy is paramount to maintaining trust and continuity in business operations. MSL offers comprehensive cybersecurity solutions tailored to your specific needs, ensuring robust protection against cyber threats."}
      />

      <div className='w-full flex flex-col justify-center items-center gap-4 py-8'>
        <h1 className='text-[1.8rem] md:text-[2.4rem] lg:text-[3rem] px-2 lg:px-0 text-center font-bold text-primary'>
          Why do you need a team to protect your digital assets?
        </h1>
        <p className='md:text-xl text-center '>
          In today's interconnected world, <b>cybersecurity</b> is essential for safeguarding your digital assets. A dedicated team ensures comprehensive protection against a variety of threats, providing expertise in <b>Information Security</b>, <b>Network Security</b>, <b>Data Protection</b>, and more.
        </p>
        <div className='flex justify-between gap-8 w-11/12 mx-auto mt-8'>
          <div className='w-[50%] justify-center items-center hidden md:flex'>
            <img src={banner} alt="rpa_implementation_process" className="w-full h-full bg-cover bg-center"/>
          </div>
          <div className='md:w-[50%] space-y-8 h-full flex flex-col justify-between items-center'>
            {
              process.slice(0, 5).map(({ title, description }, index) => {
                return (
                  <div className='space-y-1 flex flex-col justify-between'>
                    <h1 className='font-bold text-primary text-xl'>{title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='flex flex-row-reverse justify-between gap-8 w-11/12 mx-auto mt-8'>
          <div className='w-[50%] justify-center items-center hidden md:flex'>
            <img src={process_image} alt="rpa_implementation_process" className="w-full bg-cover bg-center"/>
          </div>
          <div className='md:w-[50%] space-y-8 h-full flex flex-col justify-between items-center'>
            {
              process.slice(6, 11).map(({ title, description }, index) => {
                return (
                  <div className='space-y-1 flex  flex-col justify-between'>
                    <h1 className='font-bold text-primary text-xl'>{title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )

}

export default Cyber;