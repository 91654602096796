import React, { useEffect, useRef } from 'react'

const TechnologySection = ({data, title}) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        const cloneImages = () => {
            const images = container.querySelectorAll('img');
            images.forEach(img => {
                const clone = img.cloneNode(true);
                container.appendChild(clone);
            });
        };

        const scroll = () => {
            container.scrollLeft += 1;
            if (container.scrollLeft >= container.scrollWidth / 2) {
                container.scrollLeft = 0;
            }
        };

        cloneImages();

        const scrollInterval = setInterval(scroll, 50);

        return () => {
            clearInterval(scrollInterval);
        };
    }, []);

    return (
        <section className='flex flex-col items-center space-y-6 py-12 home-page'>
            <h1 className='text-[1.8rem] md:text-[3.2rem] font-bold text-center'>Our <span className='text-primary'>{title}</span></h1>

            <div ref={containerRef} className='flex overflow-hidden w-full my-12'>
                {
                    data.map(({image, alt}, index) => {
                        return (
                            <img src={image} alt={alt} key={index} loading='lazy' className="inline-block mr-8 w-[100px] h-[100px] md:w-[120px] md:h-[120px] lg:w-[150px] lg:h-[150px]" />
                        )
                    })
                }
            </div>
        </section>
    )
}

export default TechnologySection