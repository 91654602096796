import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { ImCancelCircle } from "react-icons/im";
import { toast } from "react-toastify";
import NoDeviceFound from "../assetsMaster/NoDeviceFound";
import { userEndPoints } from "../../services/apis";
import checkRoleAccess from "../shared/CheckRoleAcess";

const EmployeeDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [updateUI, setUpdateUI] = useState(false);

  const [formData, setFormData] = useState({
    employeeName: "",
    employeeEmail: "",
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios
      // .get("/api/v1/employee/getEmployee")
      .get(userEndPoints.GET_ALL_USERS)

      .then((res) => {
        setEmployeeData(res?.data?.data?.data || []);
      })
      .catch((error) => {
        console.error("Error fetching Employee Details:", error);
      });
  }, [updateUI]);

  useEffect(() => {
    const role = [1, 7];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  const filteredEmployeeData = employeeData.filter((employee) => {
    const employeeId = employee.employee_id ? employee.employee_id.toString().toLowerCase() : "";
    const employeeName = employee.name
      ? employee.name.toString().toLowerCase()
      : "";
    const employeeEmail = employee.email
      ? employee.email.toString().toLowerCase()
      : "";
    const normalizedSearchTerm = searchTerm.toLowerCase();

    return (
      employeeId.includes(normalizedSearchTerm) ||
      employeeName.includes(normalizedSearchTerm) ||
      employeeEmail.includes(normalizedSearchTerm)
    );
  });

  const navigate = useNavigate();

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // You can use formDataObj to send data to an API or handle it as needed

    try {
      const response = await axios.post(
        "/api/v1/employee/addEmployee",
        formData
      );
      // setUpdateUI((preState) => !preState);

      setUpdateUI(!updateUI);
      toast.success("Employee add successfully");
      navigate("/employeeDashboard");
      closeModal();
    } catch (error) {
      toast.error("Unable to add employee");
    }

    setFormData({
      employeeName: "",
      employeeEmail: "",
    });
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModal();
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-4">Employee Master</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search Employee"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a Employee"
        />
        {/* <button
          onClick={(e) => {
            e.stopPropagation();
            openModal();
          }}
          className="bg-blue-500 text-white px-8 py-2 font-bold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          aria-label="Add Employee"
        >
          Add Employee
        </button> */}
      </div>

      {filteredEmployeeData.length === 0 ? (
        <NoDeviceFound />
      ) : (
        <div className="overflow-auto max-h-[800px]">
          <table className="w-full table-auto border-collapse border-2 border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Employee Id
                </th>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Employee Name
                </th>
                <th className="border border-gray-300 p-3 text-left text-gray-700 font-bold">
                  Employee Email
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredEmployeeData.map((employee, index) => (
                <tr
                  key={index}
                  className={`bg-white ${
                    index % 2 === 0 ? "bg-gray-50" : ""
                  } hover:bg-gray-200`}
                >
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {employee.employee_id}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {employee.name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {employee.email}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showModal && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-center m-4">
              <h1 className="text-xl text-gray-700 font-bold">
                Employee Details
              </h1>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Employee Name
                </label>
                <input
                  name="employeeName"
                  type="text"
                  value={formData.employeeName}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter Emoloyee Name"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Employee Email
                </label>
                <input
                  name="employeeEmail"
                  type="email"
                  value={formData.employeeEmail}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter the Employee Email"
                  required
                />
              </div>

              <div className="m-20">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add Employee
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeDashboard;
