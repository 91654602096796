import React from "react";
import logo from "../../assets/logo/msl_logo.jpg";

const NoticePeriodPolicy = () => {
  return (
    <div className="container mx-auto px-14 py-14 max-w-screen-md border-4 border-blue-500">
      {/* Logo in the top-right corner */}
      <img
        src={logo}
        alt="Logo"
        className="absolute top-8 right-40 w-30 h-20"
      />

      {/* Center only the main heading */}
      <div className="text-center mt-16 mb-8">
        <h1 className="text-3xl font-bold pb-1  border-gray-300">
          Notice Period Policy
        </h1>
      </div>

      {/* Content Sections */}
      <div className="text-left font-san">
        <h2 className="text-2xl font-semibold mb-1 pb-1">Purpose</h2>
        <p className="mb-4">
          The purpose of this policy is to establish clear guidelines for notice
          periods when an employee resigns or is terminated from their
          employment at Mittal Software Labs. This policy aims to ensure a
          smooth transition for both the departing employee and the company,
          allowing for the proper handover of responsibilities and the
          recruitment of a replacement.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">Scope</h2>
        <p className="mb-4">
          This policy applies to all regular, contractors or any other
          individuals representing Mittal Software Labs.
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          Duration of a notice period
        </h2>
        <p className="font-bold mb-1 pb-1">
          Standard notice period:{" "}
          <span className="font-normal mb-1 pb-1">
            30 days’ notice is standard for all full-time employees.{" "}
          </span>
        </p>

        <p className="font-bold  mb-1 pb-1">
          Shortened notice period:{" "}
          <span className="font-normal">
            The notice period can be shortened by mutual agreement between the
            employee and the company or in cases of extreme misconduct.{" "}
          </span>
        </p>

        <p className="font-bold mb-4 pb-1">
          Employment-at-will:{" "}
          <span className="font-normal mb-4 pb-1">
            In jurisdictions with employment-at-will laws, either the employer
            or the employee can terminate the employment relationship without
            notice
          </span>
        </p>

        <h2 className="text-2xl font-semibold mb-1 pb-1">
          Responsibilities of an employee under notice period
        </h2>
        <p className="mb-2 pb-1">
          During the notice period, employees are expected to:{" "}
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            Fulfill their job duties diligently, maintain productivity and
            commitment.
          </li>
          <li className="mb-2">
            Assist in the transition process by cooperating with colleagues and
            providing necessary information for a seamless handover of tasks.
          </li>
          <li className="mb-2">
            Adhere to company policies, including punctuality, dress code, and
            workplace rules.
          </li>
          <li className="mb-2">
            Maintain a professional and respectful demeanor, avoiding negative
            comments or behaviors that could disrupt the workplace or harm their
            reputation.
          </li>
          <li className="mb-2">
            Offer to train colleagues or the incoming employee, sharing insights
            and best practices for their success.
          </li>
          <li className="mb-2">
            Fulfill any contractual obligations, including non-compete or
            non-disclosure agreements, and return company property.
          </li>
          <li className="mb-2">
            Keep open communication with their supervisor or HR department,
            addressing any questions or concerns regarding the transition
            process or their exit.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-2 pb-1">
          Responsibilities of employers towards employees serving notice period
        </h2>
        <p className="mb-2">
          At Mittal Software Labs, we believe that the departure of an employee
          should be handled with the same respect and care as during their
          tenure. To ensure this, employers and hiring managers must:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2">
            Engage in transparent discussions with the departing employee.
            Address forthcoming changes and resolve any concerns/queries that
            the employee might have.{" "}
          </li>
          <li className="mb-2">
            Ensure the work environment is conducive and encourages the
            departing employee to share their insights and learnings with their
            colleagues{" "}
          </li>
          <li className="mb-2">
            Provide healthy feedback on performance and offer guidance for
            improvement before their departure.{" "}
          </li>
          <li className="mb-2">
            Adhere to all relevant policies and the terms specified in the
            employment contract, including providing due notice period,
            compensation, and benefits.{" "}
          </li>
          <li className="mb-2">
            Recognize the good work the employee did and appreciate their
            contributions to the organization.{" "}
          </li>
          <li className="mb-2">
            By adhering to these responsibilities, Mittal Software Labs is
            committed to ensuring a respectful departure of employees and
            fostering a positive work environment for the remaining team
            members.{" "}
          </li>
        </ul>
        <h2 className="text-2xl font-semibold mb-2 pb-1">
          Termination by Employer
        </h2>
        <p className="mb-2 pb-1">
          Immediate termination without notices and offering severance
          pay/benefits in lieu of notice are two distinct actions that Mittal
          Software Labs takes when an employment relationship is ending.
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2 pb-1">
            <p className="font-bold pb-1 mb-1">
              {" "}
              Immediate Termination Without Notice:
              <span className="font-normal">
                In cases where an employee is being let go on the spot due to
                serious misconduct, like theft or harassment, a notice period
                will not be applicable.{" "}
              </span>
            </p>
          </li>

          <li className="mb-2 pb-1">
            <p className="font-bold mb-1 pb-1">
              {" "}
              Severance Pay and Benefits in Lieu of Notice:
              <span className="font-normal">
                In case of a layoff, Mittal Software Labs will offer severance
                pay and health insurance for a period of X months to help the
                employee during the job transition.
              </span>
            </p>
          </li>

          <li className="mb-2 pb-1">
            <p className="font-bold mb-1 pb-1">
              Understanding Notice Period Pay:{" "}
              <span className="font-normal">
                Terms, Conditions, and Scenarios Notice period pay is the
                compensation provided to an employee who either serves their
                notice period or resigns as per their employment agreement. It
                is essential to grasp the various terms, conditions, and
                situations related to this compensation:{" "}
              </span>
            </p>
          </li>

          <li className="mb-2 pb-1">
            <p className="font-bold mb-1 pb-1">
              {" "}
              Payment Throughout Notice Period:{" "}
              <span className="font-normal">
                As long as employees fulfill their job responsibilities and
                follow company policies, they continue to receive their regular
                pay during the notice period.{" "}
              </span>
            </p>
          </li>
          <li className="mb-2 pb-1">
            <p className="font-bold">
              {" "}
              Employee Resignation:{" "}
              <span className="font-normal">
                When employees resign and give notice, they are entitled to
                their salary for the duration they work during the notice
                period. However, there will be deductions in their pay for the
                entire period if they leave without completing full notice.{" "}
              </span>
            </p>
          </li>
          <li className="mb-2 pb-1">
            <p className="font-bold mb-1 pb-1">
              {" "}
              Employer Termination:{" "}
              <span className="font-normal">
                : If the company terminates an employee and offers payment in
                lieu of notice, the employee will receive a one-time payment
                equal to what they would have earned during the notice period.
                Garden Leave Compensation: In some instances, the company will
                place employees on garden leave. This means that they remain on
                the payroll and continue to receive their regular pay, even
                though they are not actively working.
              </span>
            </p>
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-2 pb-1">
          Notice Period Violations
        </h2>
        <p className="mb-2 pb-1">
          When an employee fails to honor their notice period, it signifies a
          breach of the terms outlined in their employment contract. The
          implications of such actions can vary, contingent upon the specific
          clauses within the agreement:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li className="mb-2 pb-1">
            <p className="font-bold pb-1 mb-1">
              Withholding the final settlement:
              <span className="font-normal">
                The company is entitled to withhold the employee’s final
                settlement, which includes unpaid salary, bonuses, and other
                benefits until the employee completes the notice period.
              </span>
            </p>
          </li>

          <li className="mb-2 pb-1">
            <p className="font-bold pb-1 mb-1">
              Taking legal action:
              <span className="font-normal">
                The company may sue the employee for breach of contract for
                damages such as the cost of hiring a temporary replacement.
              </span>
            </p>
          </li>
          <li className="mb-2 pb-1 ">
            <p className="font-bold pb-1 mb-1">
              Blacklisting the employee:
              <span className="font-normal">
                The company may also blacklist the employee.
              </span>
            </p>
          </li>
        </ul>
        <p className="mb-2 pb-1">
          This policy highlights all the crucial information that is required
          for the smooth functioning of our organization. It is mandatory for
          each employee of Mittal Software Labs to familiarize themselves with
          this policy and acknowledge its terms.
        </p>
      </div>
    </div>
  );
};

export default NoticePeriodPolicy;
