import React from "react";
// import banner from "../../assets/technologies/rpa/rpa-page-banner.png";
import banner from "../../assets/domain/bankImg.avif";
import TechnologyBanner from "../../components/technologies/TechnologyBanner";
import ProcessComponent2 from "../../components/technologies/ProcessComponent2";
import process_image from "../../assets/domain/Designer.png";
import { Helmet } from "react-helmet-async";

const Banking = () => {
  const process = [
    {
      title: "Protection Against Cyber Attacks::",
      description:
        "Banks face constant threats from cybercriminals seeking to exploit vulnerabilities in their systems. Robust <b>cyber security</b> measures, such as firewalls, intrusion detection systems (IDS), and endpoint protection, help prevent unauthorized access, malware attacks, and data breaches. This <b>protection</b> ensures that customer data, financial transactions, and sensitive information remain secure.",
    },
    {
      title: "Maintaining Customer Trust:",
      description:
        "Trust is paramount in banking. Effective <b>cyber security</b> practices assure customers that their financial information is safe and that the bank takes their privacy seriously. Banks that invest in <b>cyber security</b> demonstrate their commitment to protecting customer interests, thereby strengthening trust and loyalty.",
    },
    {
      title: "Compliance with Regulations:",
      description:
        "The banking industry is subject to stringent regulatory requirements regarding data protection and privacy, such as GDPR in Europe and various banking regulations globally. Implementing robust <b>cyber security</b> measures helps banks comply with these regulations, avoiding costly penalties and legal consequences.",
    },
    {
      title: "Preventing Financial Fraud:",
      description:
        "<b>Cyber security</b> tools like encryption, multi-factor authentication (MFA), and biometric verification help mitigate the risk of financial fraud. These technologies ensure that only authorized users can access sensitive accounts and conduct transactions, reducing the likelihood of fraudulent activities like identity theft and unauthorized fund transfers.",
    },
    {
      title: "Business Continuity and Resilience:",
      description:
        "<b>Cyber security</b> also plays a crucial role in maintaining business continuity. Banks rely heavily on their IT infrastructure to deliver essential services. By protecting systems from <b>cyber threats</b>, banks can minimize downtime due to <b>cyber attacks</b> or system failures, ensuring uninterrupted service delivery to customers.",
    },
    {
      title: "Advanced Threat Detection and Response",
      description:
        "With the rise of sophisticated <b>cyber threats</b> like ransomware and phishing attacks, banks need advanced threat detection and response capabilities. <b>Cyber security</b> technologies, such as AI-based analytics and real-time monitoring, enable banks to detect and respond to threats promptly, mitigating potential damage and limiting financial losses.",
    },
    {
      title: "Educating Employees and Customers",
      description:
        "<b>Cyber security</b> in banking extends beyond technology—it also involves educating employees and customers about best practices for staying safe online. Banks conduct regular training sessions to raise awareness about phishing scams, password hygiene, and other <b>cyber threats</b>, empowering both staff and customers to be vigilant and proactive.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Mittal Software Labs | Banking</title>
      </Helmet>
      <TechnologyBanner
        image={banner}
        alt={"banking-img"}
        title={"Banking"}
        description={
          "Enhance operational efficiency and streamline financial processes for superior customer service."
        }
      />

      <ProcessComponent2
        title={"The Role of Cyber Security in Banking System"}
        image={process_image}
        alt={"banking-image"}
        process={process}
        style={{ maxWidth: "100%", height: "auto" }}
      />
      {/* <div className="flex items-center justify-center w-full mx-auto mt-2 p-4">
        <p className="text-center">
          Leverage our expertise in <b>banking system integration</b> to
          transform your financial operations. Contact our team of experienced{" "}
          <b>system integrators</b> today to discover how our innovative
          solutions can enhance efficiency and drive growth for your
          institution.
        </p>
      </div> */}
    </>
  );
};

export default Banking;
