import React, { useState } from "react";
import Caraousel from "../components/home/Caraousel";
import ServicesSection from "../components/home/ServicesSection";
import TechnologySection from "../components/home/TechnologySection";
import IndustrySection from "../components/home/IndustrySection";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import about_image from "../assets/home/about-us.jpg";
import CTAButton from "../components/common/CTAButton";
import { Carousel } from "flowbite-react";
import { Helmet } from "react-helmet-async";

import quote1_image from "../assets/home/quote/1.jpeg";
import quote2_image from "../assets/home/quote/2.jpeg";
import background_image from "../assets/home/stairway.jpg";

import angular_image from "../assets/home/technologies/angular.png";
import rpa_image from "../assets/home/technologies/anywhere-automation.png";
import java_image from "../assets/home/technologies/java.png";
import node_image from "../assets/home/technologies/node.png";
import php_image from "../assets/home/technologies/php.png";
import react_image from "../assets/home/technologies/react.png";
import ui_image from "../assets/home/technologies/ui-path.png";
import vue_image from "../assets/home/technologies/vue.png";

import partner1_image from "../assets/home/partner/1.png";
import partner2_image from "../assets/home/partner/2.png";
import partner3_image from "../assets/home/partner/3.png";
import partner4_image from "../assets/home/partner/4.png";
import partner5_image from "../assets/home/partner/5.png";
import partner6_image from "../assets/home/partner/6.png";
import partner7_image from "../assets/home/partner/7.png";
import partner8_image from "../assets/home/partner/8.png";

const Home = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [isMission, setIsMission] = useState(true);

  const technology_data = [
    { image: angular_image, alt: "angular" },
    { image: rpa_image, alt: "anywhere-automation" },
    { image: java_image, alt: "java" },
    { image: node_image, alt: "node" },
    { image: php_image, alt: "php" },
    { image: react_image, alt: "react" },
    { image: ui_image, alt: "ui-path" },
    { image: vue_image, alt: "vue" },
  ];

  const partner_data = [
    { image: partner1_image, alt: "partner" },
    { image: partner2_image, alt: "partner" },
    { image: partner3_image, alt: "partner" },
    { image: partner4_image, alt: "partner" },
    { image: partner5_image, alt: "partner" },
    { image: partner6_image, alt: "partner" },
    { image: partner7_image, alt: "partner" },
    { image: partner8_image, alt: "partner" },
  ];

  return (
    <div>
      <Helmet>
        <title>Mittal Software Labs | Digital Transformation Consulting</title>
      </Helmet>

      <Caraousel />
      <ServicesSection />
      <IndustrySection />
      <TechnologySection data={technology_data} title={"Technologies"} />

      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <section className="bg-[#e4880f] w-full py-16">
          <div className="w-11/12 mx-auto text-white">
            <h1 className="text-[1.8rem] md:text-[3.2rem] font-bold text-center">
              What makes us different?
            </h1>
            <p className="text-lg text-center mt-4">
              Our Passionate team delivers the best you want, as we know, only
              some problems have the same solution. So we listen to our clients
              and understand their needs to deliver the solution according to
              their specific requirements.
            </p>

            <div className="flex flex-col md:flex-row  justify-between items-center mt-8 md:mt-16 lg:w-2/3 mx-auto">
              <div className="flex flex-col gap-y-2 justify-center items-center text-center">
                <p className="text-4xl lg:text-5xl font-bold flex gap-2">
                  {counterOn && (
                    <CountUp start={0} end={150} duration={2} delay={0} />
                  )}
                  <span>+</span>
                </p>
                <p>Projects Delivered Overseas</p>
              </div>

              <div className="w-8 h-1 md:w-1 md:h-8 bg-white my-8 md:my-0"></div>

              <div className="flex flex-col gap-y-2 justify-center items-center text-center">
                <p className="text-4xl lg:text-5xl font-bold flex gap-2">
                  {counterOn && (
                    <CountUp start={0} end={100} duration={2} delay={0} />
                  )}
                  <span>+</span>
                </p>
                <p>Skilled Professionals</p>
              </div>

              <div className="w-8 h-1 md:w-1 md:h-8 bg-white my-8 md:my-0"></div>

              <div className="flex flex-col gap-y-2 justify-center items-center text-center">
                <p className="text-4xl lg:text-5xl font-bold flex gap-2">
                  {counterOn && (
                    <CountUp start={0} end={97} duration={2} delay={0} />
                  )}
                  <span>%</span>
                </p>
                <p>Client Satisfaction Rate</p>
              </div>
            </div>
          </div>
        </section>
      </ScrollTrigger>

      <section className="py-16">
        <div className="w-11/12 mx-auto flex flex-col lg:flex-row justify-between">
          <div className="flex flex-col gap-y-8 lg:w-[50%]">
            <h1 className="text-primary text-[3.2rem] text-center">About Us</h1>
            <p className="text-lg font-bold text-center">
              Mittal Software Labs has gained experience in various technologies
              including RPA, Data Analytics, Cybersecurity, Full stack, Mobile
              app development, Cloud Technology, and System Integration. We are
              leading the Robotic Process Automation expertise as our team has
              delivered 150+ projects of RPA in 7 years to countries like UAE,
              USA, and Europe.
            </p>

            {
              isMission ?
                <p className="text-lg text-[#303849] text-center">
                  Our mission is to revolutionize business processes through
                  cutting-edge <b>Robotic Process Automation (RPA) solutions</b>. We strive
                  to empower organizations by <b>automating repetitive tasks</b>,<b> enhancing
                    efficiency</b>, and <b>fostering innovation</b> in the digital era. Our goal
                  is to be the trusted partner that enables businesses to achieve
                  <b>unparalleled productivity</b> and <b>growth</b>.
                </p> :
                <p className="text-lg text-[#303849] text-center">
                  Our vision is to lead the way in RPA innovation, setting new standards for <b>automation</b> excellence. We envision a future where manual tasks are a thing of the past, and businesses thrive on streamlined, intelligent processes. By leveraging advanced <b>technologies</b> and fostering a culture of continuous improvement, we aim to be the catalyst for <b>digital transformation</b> across <b>industries</b>, making work smarter, faster, and more rewarding for all.
                </p>
            }


            <div className='flex justify-center items-center gap-x-4'>
              <button disabled={isMission} onClick={() => setIsMission(!isMission)} className={`py-3 px-6 text-[13px] sm:text-[16px] rounded-md text-white font-semibold ${isMission ? 'bg-gray-500' : 'bg-primary'}`}>
                OUR MISSION
              </button>

              <button disabled={!isMission} onClick={() => setIsMission(!isMission)} className={`py-3 px-6 text-[13px] sm:text-[16px] rounded-md text-white font-semibold ${!isMission ? 'bg-gray-500' : 'bg-primary'}`}>
                OUR VISION
              </button>
            </div>
          </div>

          <div className="lg:w-[40%] hidden lg:block">
            <img src={about_image} alt="about" />
          </div>
        </div>
      </section>

      <section
        className="w-full flex flex-col items-center space-y-6 py-16"
        style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex flex-col items-center space-y-2 text-white mb-12">
          <h1 className="text-[1.6rem] md:text-[3.2rem] font-bold text-center">
            What the client says about us
          </h1>
          <div className="h-1 w-14 bg-primary"></div>
        </div>

        <div className="h-56 sm:h-64 xl:h-80 2xl:h-96 w-11/12 mx-auto">
          <Carousel pauseOnHover>
            <div className="flex flex-col md:flex-row justify-between lg:w-2/3 mx-auto items-center">
              <div className="md:w-[40%] md:block hidden">
                <img src={quote1_image} alt="quote1" />
              </div>

              <div className="flex flex-col items-start gap-y-8 justify-center w-11/12 md:w-[50%] lg:w-[40%]">
                <p className="text-white font-semibold">
                  Throughout our collaboration, MSL exhibited an unwavering
                  commitment to understanding our unique business needs. Their
                  ability to translate complex technical jargon into accessible
                  language allowed us to make informed decisions that aligned
                  perfectly with our goals. Their attention to detail, along
                  with their ability to adapt to changing requirements, ensured
                  a seamless implementation process.
                </p>
                <p className="text-white font-semibold">Nikunj Kacha</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between lg:w-2/3 mx-auto items-center">
              <div className="md:w-[40%] md:block hidden">
                <img src={quote2_image} alt="quote1" />
              </div>

              <div className="flex flex-col items-start gap-y-8 justify-center w-11/12 md:w-[50%] lg:w-[40%]">
                <p className="text-white font-semibold">
                  MSL Boasts an exceptional team of professionals who possess a
                  rare combination of technical prowess and genuine dedication.
                  Their expertise is evident in the flawless execution of
                  projects, and their commitment to customer satisfaction is
                  unmatched.
                </p>
                <p className="text-white font-semibold">Hari Naganathan</p>
              </div>
            </div>
          </Carousel>
        </div>
      </section>

      <TechnologySection data={partner_data} title={"Partners"} />

      {/* <section className='w-11/12 mx-auto flex flex-col items-center space-y-6 py-12'>
                <div className='flex flex-col items-center space-y-2'>
                    <h1 className='text-[3.2rem] font-bold text-center'>Blogs</h1>
                    <div className='h-1 w-14 bg-primary'></div>
                </div>

                <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-10 py-6 w-full md:px-14'>
                    <div>
                        <img src="" alt="" />
                        <h1></h1>
                        <div></div>
                        <p></p>
                    </div>
                </div>

            </section> */}
    </div>
  );
};


export default Home;
