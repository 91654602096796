import React from 'react';

const ServiceCard = ({ image, title, description }) => {
  return (
    <div className='flex flex-col justify-start items-center'>
      <img src={image} loading='lazy' className='mb-[5%]' width={80} alt={image}/>
      <p className='text-xl font-bold mb-[5%] text-center text-primary'>{title}</p>
      <div className='text-center' dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  );
};

export default ServiceCard;
