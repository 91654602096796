import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { technologyEndPoints } from "../../services/apis"; // Updated import
import { MdEdit } from "react-icons/md";
import NoTechnologyFound from "./NoTechnologyFound.jsx";

const TechnologyDashboard = () => {
  // Updated component name
  const [searchTerm, setSearchTerm] = useState("");
  const [technologyData, setTechnologyData] = useState([]); // Updated state variable
  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState({
    technology_id: "", // Updated state variable
    technology_name: "", // Updated state variable
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingTechnology, setEditingTechnology] = useState(null); // Updated state variable

  // Optimized filtering logic
  const filteredTechnologies = technologyData.filter(
    // Updated variable name
    (
      technology // Updated variable name
    ) =>
      technology.technology_id
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) || // Updated variable name
      technology.technology_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) // Updated variable name
  );

  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingTechnology(null); // Updated state variable
  };

  const openAddEditModal = (technology) => {
    // Updated variable name
    setFormData({
      technology_id: technology.technology_id || "", // Updated state variable
      technology_name: technology.technology_name, // Updated state variable
    });
    setEditingTechnology(technology.technology_id ? true : false); // Updated state variable
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingTechnology) {
        // Updated state variable
        
        await axios.post(technologyEndPoints.POST_UPDATE_TECHNOLOGY, {
          // Updated endpoint
          technology_id: formData.technology_id, // Updated state variable
          technology_name: formData.technology_name, // Updated state variable
        });
        toast.success("Technology updated successfully"); // Updated message
      } else {
        await axios.post(technologyEndPoints.POST_CREATE_TECHNOLOGY, {
          // Updated endpoint
          technology_name: formData.technology_name, // Updated state variable
        });
        toast.success("Technology added successfully"); // Updated message
      }
      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      toast.error("Error processing request");
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchTechnologyData = async (url) => {
    // Updated function name
    try {
      const response = await axios.get(url);
      setTechnologyData(response.data?.data?.data || []); // Updated state variable
    } catch (error) {
      console.error("Error fetching technologies:", error); // Updated message
    }
  };

  useEffect(() => {
    fetchTechnologyData(technologyEndPoints.GET_ALL_TECHNOLOGIES); // Updated endpoint
  }, [updateUI]);

  const handleStatus = async (technology_id, is_active) => {
    // Updated variable name
    try {
      if (is_active === 1) {
        await axios.post(technologyEndPoints.POST_DEACTIVATE_TECHNOLOGY, {
          // Updated endpoint
          technology_id,
        });
        toast.success("Technology deactivated successfully"); // Updated message
      } else {
        await axios.post(technologyEndPoints.POST_ACTIVATE_TECHNOLOGY, {
          // Updated endpoint
          technology_id,
        });
        toast.success("Technology activated successfully"); // Updated message
      }
      setUpdateUI((prevState) => !prevState);
    } catch (error) {
      console.error("Error updating technology status:", error); // Updated message
      toast.error("Error updating technology status"); // Updated message
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-4">Technology Master</h1>{" "}
      {/* Updated heading */}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for a technology" // Updated placeholder
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a technology" // Updated aria-label
        />

        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({ technology_name: "" })} // Updated variable name
            className="bg-blue-500 text-white px-8 py-2 font-bold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            aria-label="Add Technology" // Updated aria-label
          >
            Add Technology {/* Updated button text */}
          </button>
        </div>
      </div>
      {filteredTechnologies.length === 0 ? ( // Updated variable name
        <NoTechnologyFound /> // This component might need to be updated too
      ) : (
        <div className="overflow-auto max-h-[800px]">
          <table className="w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Serial Number
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Technology Name {/* Updated header */}
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredTechnologies.map(
                (
                  technology,
                  index // Updated variable name
                ) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    } hover:bg-gray-100`}
                  >
                    <td className="border border-gray-300 p-4 text-gray-700">
                      {technology.technology_id} {/* Updated variable name */}
                    </td>
                    <td className="border border-gray-300 p-4 text-gray-700">
                      {technology.technology_name} {/* Updated variable name */}
                    </td>
                    <td className="border border-gray-300 p-4 text-gray-700">
                      <div className="flex gap-4">
                        <button
                          onClick={() => openAddEditModal(technology)} // Updated variable name
                          className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                        >
                          <MdEdit /> Edit
                        </button>

                        <button
                          className="text-sm px-3 py-1 rounded"
                          onClick={
                            () =>
                              handleStatus(
                                technology.technology_id,
                                technology.is_active
                              ) // Updated variable name
                          }
                        >
                          {technology.is_active === 1
                            ? "🟢  Active"
                            : "🔴 Inactive"}{" "}
                          {/* Updated variable name */}
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
      {showAddEditModal && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-center m-4">
              <h1 className="text-xl text-gray-700 font-bold">
                {editingTechnology ? "Edit Technology" : "Add Technology"}{" "}
                {/* Updated heading */}
              </h1>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Technology Name {/* Updated label */}
                </label>
                <input
                  name="technology_name" // Updated name attribute
                  type="text"
                  value={formData.technology_name}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter Technology Name"
                  required
                />
              </div>
              <div className="m-20">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {editingTechnology ? "Update Technology" : "Add Technology"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TechnologyDashboard;
