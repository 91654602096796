import React, { useEffect, useState } from 'react';
import { DASHBOARD_SIDEBAR_LINKS, DASHBOARD_SIDEBAR_BOTTOM_LINKS } from '../../data/navigation';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { HiOutlineLogout } from 'react-icons/hi';
import { logout } from '../../services/operations/authAPI';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/logo/msl-logo.png';
import ConfirmationModal from "../../components/common/ConfirmationMoal";
import { VscSignOut } from "react-icons/vsc";
import { toast } from "react-hot-toast";
import { apiConnector } from '../../services/apiConnector';
import { endpoints } from '../../services/apis';
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";

const { Sidemenu } = endpoints;

const linkClass = 'flex items-center gap-2 font-light px-3 py-2 hover:bg-black/10 hover:no-underline rounded-sm text-base';

const Sidebar = () => {
    const { user, loading: profileLoading } = useSelector(
        (state) => state.profile
    );
    const [sidebar, setSidebar] = useState([]);
    const user_id = localStorage.getItem("user_id");
    const { loading: authLoading } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [confirmationModal, setConfirmationModal] = useState(null);

    useEffect(() => {
        const fetchSideMenu = async () => {
            try {
                const response = await apiConnector("POST", Sidemenu, {
                    user_id
                });
                setSidebar(response.data.data);
                

            } catch (error) {
             
                toast.error("Error");
            }
        };

        fetchSideMenu();
    }, [user_id]);

    if (profileLoading || authLoading) {
        return (
            <div className="grid h-[calc(100vh-3.5rem)] min-w-[220px] items-center border-r-[1px] border-r-richblack-700 bg-richblack-800">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className='bg-white flex flex-col p-3 h-100 fixed border h-screen w-[15rem] z-[1000] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200'>
            <div className='flex p-2 bg-black/90 rounded-md justify-center items-center'>
                <img src={logo} alt="logo" width={150} />
            </div>
            <div className='flex-1 py-5 flex flex-col gap-1'>
                {
                    sidebar.map((item) => (
                        <SidebarLink key={item.sm_id} item={item} />
                    ))
                }
            </div>
            <div className='flex flex-col gap-1 pt-2 border-t border-primary'>
                {
                    DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((item) => (
                        <SidebarLink key={item.key} item={item} />
                    ))
                }
                <div
                    onClick={() =>
                        setConfirmationModal({
                            text1: "Are you sure?",
                            text2: "You will be logged out of your account.",
                            btn1Text: "Logout",
                            btn2Text: "Cancel",
                            btn1Handler: () => dispatch(logout(navigate)),
                            btn2Handler: () => setConfirmationModal(null),
                        })}
                    className={classNames('cursor-pointer text-red-400 hover:text-red-600', linkClass)}
                >
                    <div className="flex items-center gap-x-2">
                        <VscSignOut className="text-xl" />
                        <span>Logout</span>
                    </div>
                </div>
            </div>
            {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
        </div>
    );
};

export default Sidebar;

function SidebarLink({ item }) {
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const hasSubmenu = item.submenu && item.submenu.length > 0;

    return (
        <div>
            {hasSubmenu ? (
                <div>
                    <div
                        onClick={() => setIsOpen(!isOpen)}
                        className={classNames(pathname === item.path ? 'text-light bg-black/10 font-medium' : 'text-black', linkClass)}
                    >
                        {item.label}
                        <span className='ml-auto'>{isOpen ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}</span>
                    </div>
                    {isOpen && (
                        <div className='ml-4 flex flex-col'>
                            {item.submenu.map((submenuItem, index) => (
                                <Link
                                    key={index}
                                    to={submenuItem.submenu_path}
                                    className={classNames(pathname === submenuItem.submenu_path ? 'text-light bg-black/10 font-medium' : 'text-black', linkClass)}
                                >
                                    {submenuItem.submenu_label}
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <Link
                    to={item.path}
                    className={classNames(pathname === item.path ? 'text-light bg-black/10 font-medium' : 'text-black', linkClass)}
                >
                    {item.label}
                </Link>
            )}
        </div>
    );
}
