import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaRegEye } from "react-icons/fa";
import checkRoleAccess from "../components/shared/CheckRoleAcess";

function JobApplication() {
  // For master data
  const [panelist, setPanelist] = useState([]);
  const [seniorpanelist, setSeniorPanelist] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchbyJobProfile, setSearchbyJobProfile] = useState("");
  const [searchbyApplicationDate, setSearchbyApplicationDate] = useState("");
  const [searchbyModifiedDate, setSearchbyModifiedDate] = useState("");
  const [searchbyMobile, setSearchbyMobile] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/master/getpanelist`)
      .then(function (res) {
        setPanelist(res.data.data.panelist);
        setSeniorPanelist(res.data.data.senior_panelist);
      })
      .catch(function (e) {});
  }, []);

    useEffect(() => {
      const role = [1, 3];
      if (!checkRoleAccess(role)) {
        return;
      }
    }, []);

  // for tabs data
  const [activeTab, setActiveTab] = useState("all");
  const [applicantsData, setApplicantsData] = useState([]);
  const [reload, setReload] = useState(true);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    setApplicantsData([]);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/auth/allJobData/${activeTab}`)
      .then(function (res) {
        //
        setApplicantsData(res.data.data);
      })
      .catch(function (e) {});
  }, [activeTab, reload]);

  //   for written tab
  const [editingIndex, setEditingIndex] = useState(null);
  const [marksObtained, setMarksObtained] = useState("");
  const [totalMarks, setTotalMarks] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchbyJobProfile = (e) => {
    setSearchbyJobProfile(e.target.value);
  };

  const handleSearchbyApplicationDate = (e) => {
    setSearchbyApplicationDate(e.target.value);
  };

  const handleSearchbyModifiedDate = (e) => {
    setSearchbyModifiedDate(e.target.value);
  };

  const handleSearchbyMobile = (e) => {
    setSearchbyMobile(e.target.value);
  };

  const filteredApplicants = applicantsData
    .filter((applicant) => {
      const inputDate = searchbyApplicationDate
        ? new Date(searchbyApplicationDate)
        : null;
      const applicantDate = applicant.created_at
        ? new Date(applicant.created_at)
        : null;

      const inputModifiedDate = searchbyModifiedDate
        ? new Date(searchbyModifiedDate)
        : null;
      const modifiedDate = new Date(applicant.modified_at);
      //

      const includesIgnoreCase = (field, query) => {
        if (!query) return true;
        if (!field) return false;
        return field.toLowerCase().includes(query.toLowerCase());
      };

      const datesAreEqual = (date1, date2) => {
        if (!date1 || !date2) return true;
        return date1.toDateString() === date2.toDateString();
      };

      return (
        includesIgnoreCase(applicant.name, searchQuery) &&
        includesIgnoreCase(applicant.job_profile, searchbyJobProfile) &&
        includesIgnoreCase(applicant.mobile, searchbyMobile) &&
        datesAreEqual(applicantDate, inputDate) &&
        datesAreEqual(modifiedDate, inputModifiedDate)
      );
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleEditClick = (index) => {
    setMarksObtained("");
    setTotalMarks("");
    setEditingIndex(index);
  };

  const handleSaveClick = (index) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/apply/jobDataMarks`, {
        id: index,
        marks: marksObtained,
        total_marks: totalMarks,
      })
      .then(function (res) {
        //
        setReload(!reload);
      })
      .catch(function (e) {});
    // Handle saving logic here, such as updating state or making API calls.
    // After saving, reset the editing index and input fields
    setEditingIndex(null);
    setMarksObtained("");
    setTotalMarks("");
  };

  //   For technical tab

  const [techeditingIndex, settechEditingIndex] = useState(null);
  const [assignedPanelists, setAssignedPanelists] = useState(null);

  const handleAssignClick = (index) => {
    setAssignedPanelists(null);
    settechEditingIndex(index);
  };

  const handlePanelistChange = (value) => {
    setAssignedPanelists(value);
  };

  const techhandleSaveClick = (id) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/apply/jobDataPanelist`, {
        id,
        userid: +assignedPanelists,
        round: "technical",
      })
      .then(function (res) {
        //
        settechEditingIndex(null);
        setReload(!reload);
        setAssignedPanelists(null);
      })
      .catch(function (e) {});
  };

  //   For final tab

  const [finaleditingIndex, setfinalEditingIndex] = useState(null);
  const [assignedseniorPanelists, setAssignedseniorPanelists] = useState(null);

  const handlefinalAssignClick = (index) => {
    setAssignedseniorPanelists(null);
    setfinalEditingIndex(index);
  };

  const handleseniorPanelistChange = (value) => {
    setAssignedseniorPanelists(value);
  };

  const finalhandleSaveClick = (id) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/apply/jobDataPanelist`, {
        id,
        userid: +assignedseniorPanelists,
        round: "final",
      })
      .then(function (res) {
        //
        setfinalEditingIndex(null);
        setReload(!reload);
        setAssignedseniorPanelists(null);
      })
      .catch(function (e) {});
  };
  // for new tab (post api calls)
  const handelwritten = (id) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/apply/jobDataToWritten`, {
        id: +id,
      })
      .then(function (res) {
        //
        setReload(!reload);
      })
      .catch(function (e) {});
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Job Applications</h1>

      {/* Tabs */}
      <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
        <ul
          className="flex flex-wrap -mb-px text-sm font-medium text-center"
          id="default-tab"
          role="tablist"
        >
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "all"
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("all")}
              type="button"
              role="tab"
              aria-controls="all"
              aria-selected={activeTab === "all"}
            >
              All
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "new"
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("new")}
              type="button"
              role="tab"
              aria-controls="new"
              aria-selected={activeTab === "new"}
            >
              New Application
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "written"
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("written")}
              type="button"
              role="tab"
              aria-controls="written"
              aria-selected={activeTab === "written"}
            >
              Written
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "technical"
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("technical")}
              type="button"
              role="tab"
              aria-controls="technical"
              aria-selected={activeTab === "technical"}
            >
              Technical
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "final"
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("final")}
              type="button"
              role="tab"
              aria-controls="final"
              aria-selected={activeTab === "final"}
            >
              Final
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "onhold"
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("onhold")}
              type="button"
              role="tab"
              aria-controls="onhold"
              aria-selected={activeTab === "onhold"}
            >
              On Hold
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "selected"
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("selected")}
              type="button"
              role="tab"
              aria-controls="selected"
              aria-selected={activeTab === "selected"}
            >
              Selected
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "rejected"
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent"
              }`}
              onClick={() => handleTabClick("rejected")}
              type="button"
              role="tab"
              aria-controls="rejected"
              aria-selected={activeTab === "rejected"}
            >
              Rejected
            </button>
          </li>
        </ul>
      </div>

      {/* filters */}
      <div className="grid grid-cols-4 gap-4 mb-2">
        <div className="flex flex-col">
          <label htmlFor="searchQuery" className="mb-2">
            Name
          </label>
          <input
            type="text"
            id="searchQuery"
            placeholder="Search by Name..."
            value={searchQuery}
            onChange={handleSearchInputChange}
            className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="searchbyJobProfile" className="mb-2">
            Job Profile
          </label>
          <input
            type="text"
            id="searchbyJobProfile"
            placeholder="Search by Job Profile..."
            value={searchbyJobProfile}
            onChange={handleSearchbyJobProfile}
            className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="searchbyMobile" className="mb-2">
            Mobile Number
          </label>
          <input
            type="text"
            id="searchbyMobile"
            placeholder="Search by Mobile Number..."
            value={searchbyMobile}
            onChange={handleSearchbyMobile}
            className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="searchbyApplicationDate" className="mb-2">
            Application Date
          </label>
          <input
            type="date"
            id="searchbyApplicationDate"
            placeholder="Search by Application Date..."
            value={searchbyApplicationDate}
            onChange={handleSearchbyApplicationDate}
            className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="searchbyModifiedDate" className="mb-2">
            Interview Date
          </label>
          <input
            type="date"
            id="searchbyModifiedDate"
            placeholder="Search by Modified Date..."
            value={searchbyModifiedDate}
            onChange={handleSearchbyModifiedDate}
            className="px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 text-gray-900 dark:text-white"
          />
        </div>
      </div>

      {/* table */}
      <div id="default-tab-content">
        <div
          className={`p-4 rounded-lg bg-gray-50 dark:bg-gray-800 ${
            activeTab === "all" ? "block" : "hidden"
          }`}
          id="all"
          role="tabpanel"
        >
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Job Profile
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredApplicants.length > 0 ? (
                  filteredApplicants.map((applicant, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {applicant.name}
                      </td>
                      <td className="px-6 py-4">{applicant.job_profile}</td>
                      <td className="px-6 py-4">
                        {applicant.application_status}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={`p-4 rounded-lg bg-gray-50 dark:bg-gray-800 ${
            activeTab === "new" ? "block" : "hidden"
          }`}
          id="new"
          role="tabpanel"
        >
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Job Profile
                  </th>
                  <th scope="col" className="px-6 py-3">
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredApplicants.length > 0 ? (
                  filteredApplicants.map((applicant, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {applicant.name}
                      </td>
                      <td className="px-6 py-4">{applicant.job_profile}</td>
                      <td className="px-6 py-4">
                        <button
                          onClick={() => handelwritten(applicant.id)}
                          className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          type="button"
                        >
                          Proceed To Written Round
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={`p-4 rounded-lg bg-gray-50 dark:bg-gray-800 ${
            activeTab === "written" ? "block" : "hidden"
          }`}
          id="written"
          role="tabpanel"
        >
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Job Profile
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredApplicants.length > 0 ? (
                  filteredApplicants.map((applicant, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {applicant.name}
                      </td>
                      <td className="px-6 py-4">{applicant.job_profile}</td>

                      <td className="px-6 py-4">
                        {editingIndex === index ? (
                          <div className="flex flex-col space-y-2">
                            <input
                              type="number"
                              className="p-2 border border-gray-300 rounded"
                              placeholder="Marks Obtained"
                              value={marksObtained}
                              onChange={(e) => setMarksObtained(e.target.value)}
                            />
                            <input
                              type="number"
                              className="p-2 border border-gray-300 rounded"
                              placeholder="Total Marks"
                              value={totalMarks}
                              onChange={(e) => setTotalMarks(e.target.value)}
                            />
                            <div className="flex gap-2">
                              <button
                                className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                type="button"
                                onClick={() => handleSaveClick(applicant.id)}
                              >
                                Save
                              </button>
                              <button
                                className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                type="button"
                                onClick={() => {
                                  setEditingIndex(null);
                                  setMarksObtained("");
                                  setTotalMarks("");
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <button
                            className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            type="button"
                            onClick={() => handleEditClick(index)}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={`p-4 rounded-lg bg-gray-50 dark:bg-gray-800 ${
            activeTab === "technical" ? "block" : "hidden"
          }`}
          id="technical"
          role="tabpanel"
        >
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Job Profile
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Technical Round Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Assigned Panelist
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredApplicants.length > 0 ? (
                  filteredApplicants.map((applicant, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {applicant.name}
                      </td>
                      <td className="px-6 py-4">{applicant.job_profile}</td>

                      <td className="px-6 py-4">{applicant.round2_status}</td>
                      <td className="px-6 py-4">
                        {techeditingIndex === index ? (
                          <select
                            className="p-2 border border-gray-300 rounded"
                            value={assignedPanelists}
                            onChange={(e) =>
                              handlePanelistChange(e.target.value)
                            }
                          >
                            <option value="">Select Panelist</option>
                            {panelist?.map((ele) => {
                              return (
                                <option value={ele.id}>{ele.name} </option>
                              );
                            })}
                          </select>
                        ) : (
                          panelist.find(
                            (panelist) =>
                              panelist.id ===
                              +applicant.round2_assigned_panelist
                          )?.name || "Not Assigned"
                        )}
                      </td>
                      <td className="px-6 py-4">
                        {techeditingIndex === index ? (
                          <div className="flex gap-2">
                            <button
                              className="block text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                              type="button"
                              onClick={() => {
                                techhandleSaveClick(applicant.id);
                              }}
                            >
                              Save
                            </button>
                            <button
                              className="block text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                              type="button"
                              onClick={() => {
                                settechEditingIndex(null);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            type="button"
                            onClick={() => handleAssignClick(index)}
                          >
                            Assign
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={`p-4 rounded-lg bg-gray-50 dark:bg-gray-800 ${
            activeTab === "final" ? "block" : "hidden"
          }`}
          id="final"
          role="tabpanel"
        >
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Job Profile
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Final Round Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Assigned Panelist
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredApplicants.length > 0 ? (
                  filteredApplicants.map((applicant, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {applicant.name}
                      </td>
                      <td className="px-6 py-4">{applicant.job_profile}</td>

                      <td className="px-6 py-4">{applicant.round3_status}</td>
                      <td className="px-6 py-4">
                        {finaleditingIndex === index ? (
                          <select
                            className="p-2 border border-gray-300 rounded"
                            value={assignedseniorPanelists}
                            onChange={(e) =>
                              handleseniorPanelistChange(e.target.value)
                            }
                          >
                            <option value="">Select Panelist</option>
                            {seniorpanelist?.map((ele) => {
                              return (
                                <option value={ele.id}>{ele.name} </option>
                              );
                            })}
                          </select>
                        ) : (
                          seniorpanelist.find(
                            (panelist) =>
                              panelist.id ===
                              +applicant.round3_assigned_panelist
                          )?.name || "Not Assigned"
                        )}
                      </td>
                      <td className="px-6 py-4">
                        {finaleditingIndex === index ? (
                          <div className="flex gap-2">
                            <button
                              className="block text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                              type="button"
                              onClick={() => {
                                finalhandleSaveClick(applicant.id);
                              }}
                            >
                              Save
                            </button>
                            <button
                              className="block text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                              type="button"
                              onClick={() => {
                                setfinalEditingIndex(null);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            type="button"
                            onClick={() => handlefinalAssignClick(index)}
                          >
                            Assign
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={`p-4 rounded-lg bg-gray-50 dark:bg-gray-800 ${
            activeTab === "selected" ? "block" : "hidden"
          }`}
          id="selected"
          role="tabpanel"
        >
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Job Profile
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Technical Round Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Final Round Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredApplicants.length > 0 ? (
                  filteredApplicants.map((applicant, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {applicant.name}
                      </td>
                      <td className="px-6 py-4">{applicant.job_profile}</td>

                      <td className="px-6 py-4">{applicant.round2_status}</td>
                      <td className="px-6 py-4">{applicant.round3_status}</td>
                      <td className="px-6 py-4">
                        <button
                          // onClick={() => handleModalOpen(applicant)}
                          className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          type="button"
                        >
                          <FaRegEye />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className={`p-4 rounded-lg bg-gray-50 dark:bg-gray-800 ${
            activeTab === "onhold" ? "block" : "hidden"
          }`}
          id="onhold"
          role="tabpanel"
        >
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Job Profile
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Technical Round Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Final Round Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredApplicants.length > 0 ? (
                  filteredApplicants.map((applicant, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {applicant.name}
                      </td>
                      <td className="px-6 py-4">{applicant.job_profile}</td>

                      <td className="px-6 py-4">{applicant.round2_status}</td>
                      <td className="px-6 py-4">{applicant.round3_status}</td>
                      <td className="px-6 py-4">
                        <button
                          // onClick={() => handleModalOpen(applicant)}
                          className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          type="button"
                        >
                          <FaRegEye />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className={`p-4 rounded-lg bg-gray-50 dark:bg-gray-800 ${
            activeTab === "rejected" ? "block" : "hidden"
          }`}
          id="rejected"
          role="tabpanel"
        >
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Job Profile
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Technical Round Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Final Round Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredApplicants.length > 0 ? (
                  filteredApplicants.map((applicant, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {applicant.name}
                      </td>
                      <td className="px-6 py-4">{applicant.job_profile}</td>

                      <td className="px-6 py-4">{applicant.round2_status}</td>
                      <td className="px-6 py-4">{applicant.round3_status}</td>
                      <td className="px-6 py-4">
                        <button
                          // onClick={() => handleModalOpen(applicant)}
                          className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          type="button"
                        >
                          <FaRegEye />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobApplication;
