import axios from "axios";
import React, { useEffect, useState } from "react";
import { assetAcceptEndPoints } from "../../services/apis";
import toast from "react-hot-toast";
import AssetsCard from "./AssetsCard"; // Import the AssetsCard component
import checkRoleAccess from "../shared/CheckRoleAcess";

const AssetsAcceptance = () => {
  const [userId, setUserId] = useState("");
  const [assetAcceptData, setAssetAcceptData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id") || "";
    setUserId(storedUserId);
  }, []);

  useEffect(() => {
    const fetchAssetAssignData = async () => {
      setLoading(true);
      if (!userId) {
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          assetAcceptEndPoints.GET_ALL_ACCEPTED_ASSETS,
          { userId }
        );
        const assets = response.data?.data?.data || [];
        setAssetAcceptData(assets);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchAssetAssignData();
  }, [userId]);

  const refreshAssets = async () => {
    try {
      const response = await axios.post(
        assetAcceptEndPoints.GET_ALL_ACCEPTED_ASSETS,
        { userId }
      );
      const assets = response.data?.data?.data || [];
      setAssetAcceptData(assets);
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh data. Please try again later.");
    }
  };
  useEffect(() => {
    const role = [1, 7, 10];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-6 ">Asset Acceptance</h1>
      <div className="bg-gray-100 shadow-md rounded-lg p-6">
        {loading ? (
          <div className="space-y-4">
            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className="bg-gray-300 h-24 rounded-md animate-pulse"
              ></div>
            ))}
          </div>
        ) : assetAcceptData.length > 0 ? (
          assetAcceptData.map((asset, index) => (
            <AssetsCard
              key={index}
              asset={asset}
              userId={userId}
              device_serialnumber={asset.device_serialnumber}
              refreshAssets={refreshAssets}
            />
          ))
        ) : (
          <p className="text-center text-gray-500">
            No assets found for the user.
          </p>
        )}
      </div>
    </div>
  );
};

export default AssetsAcceptance;
