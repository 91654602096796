const checkRoleAccess = (requiredRoleIds) => {
  const roleIdsString = localStorage.getItem("role_id");

  let roleIds = [];
  try {
    roleIds = JSON.parse(roleIdsString);
    if (!Array.isArray(roleIds)) {
      throw new Error("Parsed role_ids is not an array");
    }
  } catch (error) {
    console.error("Error parsing role_id from localStorage:", error);
    roleIds = [];
  }

  if (!Array.isArray(requiredRoleIds)) {
    // console.error('requiredRoleIds should be an array');
    return false;
  }

  if (roleIds.some((roleId) => requiredRoleIds.includes(roleId))) {
    return true;
  } else {
    window.location.href = "/admin/unauthorized";
    return false;
  }
};

export default checkRoleAccess;
