import { toast } from "react-hot-toast";

import { apiConnector } from "../apiConnector";
import { jobEndPoints } from "../apis";
import { formEndPoints } from "../apis";

export const submitInternshipApplication = async (formData) => {
  const toastId = toast.loading("Loading...");
  
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      jobEndPoints.SUBMIT_INTERNSHIP_APPLICATION,
      formData
    );
    
    if (!response?.data?.success) {
      throw new Error("Could Not Submit Form.");
    }
    result = response?.data;
    toast.success('Form Submitted Successfully');
  } catch (error) {
    toast.error(error.response.data.errors[0].type + error.response.data.errors[0].value + error.response.data.errors[0].msg);
    result = error.response?.data;
  }
  toast.dismiss(toastId);
  return result;
};

export const submitJobApplication = async (formData) => {
  const toastId = toast.loading("Loading...");
  
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      jobEndPoints.SUBMIT_JOB_APPLICATION,
      formData
    );
    
    if (!response?.data?.success) {
      throw new Error("Could Not Submit Form.");
    }
    result = response?.data;
    toast.success('Form Submitted Successfully');
  } catch (error) {
    
    toast.error('Error Submitting Form');
    result = error.response?.data;
  }
  toast.dismiss(toastId);
  return result;
};

export const submitSubscriptionForm = async (formData) => {
  const toastId = toast.loading("Loading...");
  
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      formEndPoints.SUBMIT_SUBSCRIPTION_API,
      formData
    );
    
    if (!response?.data?.success) {
      throw new Error("Could Not Submit Form.");
    }
    result = response?.data;
    toast.success('Form Submitted Successfully');
  } catch (error) {
    
    toast.error('Error Submitting Form');
    result = error.response?.data;
  }
  toast.dismiss(toastId);
  return result;
};

export const submitContactForm = async (formData) => {
  
  const toastId = toast.loading("Loading...");
  
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      formEndPoints.SUBMIT_CONTACT_US_API,
      formData
    );
    
    if (!response?.data?.success) {
      throw new Error("Could Not Submit Form.");
    }
    result = response?.data;
    toast.success('Form Submitted Successfully');
  } catch (error) {
    
    toast.error('Error Submitting Form');
    result = error.response?.data;
  }
  toast.dismiss(toastId);
  return result;
};