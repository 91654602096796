import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NoProjectFound from "./NoProjectFound";
import { projectEndPoints } from "../../services/apis";
import { MdEdit } from "react-icons/md";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import checkRoleAccess from "../shared/CheckRoleAcess";

const ProjectDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [projectData, setProjectData] = useState([]);
  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState({
    project_id: "",
    project_name: "",
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingProject, setEditingProject] = useState(null);

  // Optimized filtering logic
  const filteredProjects = projectData.filter(
    (project) =>
      project.project_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.project_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingProject(null);
  };

  const openAddEditModal = (project) => {
    setFormData({
      project_id: project.project_id || "",
      project_name: project.project_name,
    });
    setEditingProject(project.project_id ? true : false);
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingProject) {
        
        await axios.post(projectEndPoints.POST_UPDATE_PROJECT, {
          project_id: formData.project_id,
          project_name: formData.project_name,
        });
        toast.success("Project updated successfully");
      } else {
        await axios.post(projectEndPoints.POST_CREATE_PROJECT, {
          project_name: formData.project_name,
        });
        toast.success("Project added successfully");
      }
      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      toast.error("Error processing request");
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchProjectData = async (url) => {
    try {
      const response = await axios.get(url);
      setProjectData(response.data?.data?.data || []);
      
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjectData(projectEndPoints.GET_ALL_PROJECTS);
  }, [updateUI]);

  const handleStatus = async (project_id, is_active) => {
    try {
      if (is_active === 1) {
        await axios.post(projectEndPoints.POST_DEACTIVATE_PROJECT, {
          project_id,
        });
        toast.success("Project deactivated successfully");
      } else {
        await axios.post(projectEndPoints.POST_ACTIVATE_PROJECT, {
          project_id,
        });
        toast.success("Project activated successfully");
      }
      setUpdateUI((prevState) => !prevState);
    } catch (error) {
      console.error("Error updating project status:", error);
      toast.error("Error updating project status");
    }
  };

  useEffect(() => {
    const role = [1, 2, 7, 8];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>MSL- Projects</title>
        </Helmet>
      </HelmetProvider>
      <h1 className="text-4xl font-bold mb-4">Project Dashboard</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for a project"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a project"
        />

        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({ project_name: "" })}
            className="bg-blue-500 text-white px-8 py-2 font-bold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            aria-label="Add Project"
          >
            Add Project
          </button>
        </div>
      </div>

      {filteredProjects.length === 0 ? (
        <NoProjectFound />
      ) : (
        <div className="overflow-auto max-h-[800px]">
          <table className="w-full table-auto border-collapse border border-gray-300">
            <thead className="bg-gray-200">
              <tr>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Serial Number
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Project Name
                </th>
                <th className="border border-gray-300 p-4 text-left text-gray-700 font-bold">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredProjects.map((project, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {project.project_id}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    {project.project_name}
                  </td>
                  <td className="border border-gray-300 p-4 text-gray-700">
                    <div className="flex gap-4">
                      <button
                        onClick={() => openAddEditModal(project)}
                        className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                      >
                        <MdEdit /> Edit
                      </button>

                      <button
                        className="text-sm px-3 py-1 rounded"
                        onClick={() =>
                          handleStatus(project.project_id, project.is_active)
                        }
                      >
                        {project.is_active === 1 ? "🟢  Active" : "🔴 Inactive"}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showAddEditModal && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-center m-4">
              <h1 className="text-xl text-gray-700 font-bold">
                {editingProject ? "Edit Project" : "Add Project"}
              </h1>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Project Name
                </label>
                <input
                  name="project_name"
                  type="text"
                  value={formData.project_name}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter Project Name"
                  required
                />
              </div>
              <div className="m-20">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {editingProject ? "Update Project" : "Add Project"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDashboard;
