const BASE_URL = process.env.REACT_APP_BASE_URL;

// AUTH ENDPOINTS
export const endpoints = {
  LOGIN_API: BASE_URL + "/auth/login",
  LOGIN_MICROSOFT_API: BASE_URL + "/auth/login-microsoft",
  Sidemenu: BASE_URL + "/auth/menu",
};

// FORMS API
export const jobEndPoints = {
  SUBMIT_JOB_APPLICATION: BASE_URL + "/apply/job",
  SUBMIT_INTERNSHIP_APPLICATION: BASE_URL + "/apply/internship",
  GET_JOB_DETAILS_API: BASE_URL + "/job/job_details",
};

// CONTACT-US API
export const formEndPoints = {
  SUBMIT_CONTACT_US_API: BASE_URL + "/reach/contact",
  SUBMIT_SUBSCRIPTION_API: BASE_URL + "/reach/subscribe",
};

//Asset Api
export const assetAssignmentEndPoints = {
  POST_ASSET_DETAILS: BASE_URL + "/assets/addAssets",
  GET_ASSET_ASSIGNMENT: BASE_URL + "/assets/getAssets",
  GET_ASSIGN_ASSET_DETAILS: BASE_URL + "/assets/getAssignedAssets",
  GET_UNASSIGN_ASSET_DETAILS: BASE_URL + "/assets/getUnassignedAssets",
  POST_UPDATE_UNASIGNED_TO_ASSIGN:
    BASE_URL + "/assets/updateAssetAssignToUnassignDetail",
  POST_UPDATE_ASIGNED_TO_UNASSIGN:
    BASE_URL + "/assets/updateAssetUnassignToAssignDetail",

  POST_UPDATE_ASSET_DETAILS: BASE_URL + "/assets/updateAssetDetails",
  POST_DISTINCT_ASSET_TYPE: BASE_URL + "/assets/distinctAssetType",
  POST_DISTINCT_ASSET_BRAND: BASE_URL + "/assets/distinctAssetBrand",
  POST_ACTIVE_ASSET: BASE_URL + "/assets/activateAsset",
  POST_DEACTIVE_ASSET: BASE_URL + "/assets/deactivateAsset",
};

//Assets Allocation API methods

export const assetAllocationEndPoints = {
  GET_ALL_ASSIGN_ASSETS: BASE_URL + "/assetAllocation/getAllAssets",
  POST_ASSIGN_ASSET: BASE_URL + "/assetAllocation/addAssignAsset",

  GET_ALL_ASSIGN_CONTRACTOR_ASSETS:
    BASE_URL + "/assetAllocation/getAllContractorAssets",
  POST_ASSIGN_CONTRACTOR_ASSET:
    BASE_URL + "/assetAllocation/addAssignContractorAsset",

  // GET_ALL_EMPLOYEES: BASE_URL + "/assetAllocation/getAllEmployees",

  // UNASSIGN_ASSET: BASE_URL + "/assetAllocation/unassignAsset",
};

//Users API methods

export const userEndPoints = {
  GET_ALL_USERS: BASE_URL + "/users/getAllUser",
  // POST_CREATE_USER: BASE_URL + "/users/createUser",
  // POST_UPDATE_USER: BASE_URL + "/users/updateUser",
  // POST_DELETE_USER: BASE_URL + "/users/deleteUser",
};

//Asset Accept Methods

export const assetAcceptEndPoints = {
  GET_ALL_ACCEPTED_ASSETS: BASE_URL + "/assetAcceptance/getAllAssetAccept",
  POST_ACCEPT_ASSET: BASE_URL + "/assetAcceptance/acceptAsset",
};

//Asset Reject Methods

export const assetRejectEndPoints = {
  POST_REJECT_ASSET: BASE_URL + "/assetAcceptance/rejectAsset",
};

//Asset Return Methods

export const assetReturnEndPoints = {
  GET_ALL_RETURN_ASSETS: BASE_URL + "/assetReturn/getAllAssetReturn",
  POST_RETURN_ASSET: BASE_URL + "/assetReturn/returnAsset",
};

//Asset Deallocation Methods

export const assetDeallocationEndPoints = {
  POST_DEALLOCATE_ASSET: BASE_URL + "/assetDeallocation/deallocateAsset",
  GET_ALL_DEALLOCATE_ASSET:
    BASE_URL + "/assetDeallocation/getAlldeallocateAsset",
};

//Project API methods

export const projectEndPoints = {
  GET_ALL_PROJECTS: BASE_URL + "/projects/getAllProject",
  GET_ALL_ACTIVE_PROJECTS: BASE_URL + "/projects/getAllActiveProject",
  POST_CREATE_PROJECT: BASE_URL + "/projects/createProject",
  POST_UPDATE_PROJECT: BASE_URL + "/projects/updateProject",
  POST_DELETE_PROJECT: BASE_URL + "/projects/deleteProject",
  POST_ACTIVATE_PROJECT: BASE_URL + "/projects/activateProject",
  POST_DEACTIVATE_PROJECT: BASE_URL + "/projects/deactivateProject",
};

//Process API Methods
export const processEndPoints = {
  GET_ALL_PROCESSES: BASE_URL + "/process/getAllProcess",
  GET_ALL_ACTIVE_PROCESSES: BASE_URL + "/process/getAllActiveProcess",
  POST_CREATE_PROCESS: BASE_URL + "/process/createProcess",
  POST_UPDATE_PROCESS: BASE_URL + "/process/updateProcess",
};

//Technology API Methods
export const technologyEndPoints = {
  GET_ALL_TECHNOLOGIES: BASE_URL + "/technologies/getAllTechnology",
  GET_ALL_ACTIVE_TECHNOLOGIES:
    BASE_URL + "/technologies/getAllActiveTechnology",
  POST_CREATE_TECHNOLOGY: BASE_URL + "/technologies/createTechnology",
  POST_UPDATE_TECHNOLOGY: BASE_URL + "/technologies/updateTechnology",
  // POST_DELETE_TECHNOLOGY: BASE_URL + "/technologies/deleteTechnology",
  POST_ACTIVATE_TECHNOLOGY: BASE_URL + "/technologies/activateTechnology",
  POST_DEACTIVATE_TECHNOLOGY: BASE_URL + "/technologies/deactivateTechnology",
};

//Application API Methods
export const applicationEndPoints = {
  GET_ALL_APPLICATIONS: BASE_URL + "/applications/getAllApplications",
  GET_ALL_ACTIVE_APPLICATIONS:
    BASE_URL + "/applications/getAllActiveApplications",
  POST_CREATE_APPLICATIONS: BASE_URL + "/applications/createApplication",
  POST_UPDATE_APPLICATIONS: BASE_URL + "/applications/updateApplication",
};

//Transformationl-Lever API methods

export const transformationLeverEndPoints = {
  GET_ALL_TRANSFORMATIONS: BASE_URL + "/transformationLever/getTransformation",
  GET_ALL_ACTIVE_TRANSFORMATIONS:
    BASE_URL + "/transformationLever/getAllActiveTransformation",
  POST_CREATE_TRANSFORMATION:
    BASE_URL + "/transformationLever/createTransformation",
  POST_UPDATE_TRANSFORMATION:
    BASE_URL + "/transformationLever/updateTransformationLever",
  //   POST_DELETE_TRANSFORMATION: BASE_URL + "/transformationLever/deleteTransformationLever",
  POST_ACTIVATE_TRANSFORMATION:
    BASE_URL + "/transformationLever/ActiveTransformationLever",
  POST_DEACTIVATE_TRANSFORMATION:
    BASE_URL + "/transformationLever/DeactiveTransformationLever",
};

//Task API methods

export const taskEndPoints = {
  GET_ALL_TASKS: BASE_URL + "/tasks/getAllTasks",
  GET_ALL_ACTIVE_TASKS: BASE_URL + "/tasks/getAllActiveTasks",
  POST_CREATE_TASK: BASE_URL + "/tasks/createTask",
  POST_UPDATE_TASK: BASE_URL + "/tasks/updateTask",
};

//Asset Api
export const taskAssignmentEndPoints = {
  POST_TASK_ASSIGN_DETAILS: BASE_URL + "/tasksAssign/addAssignTask",
  GET_TASK_ASSIGN_ASSIGNMENT: BASE_URL + "/tasksAssign/getAllAssignTask",
  GET_TASK_ASSIGN_ASSIGNMENT_BY_ID: BASE_URL + "/tasksAssign/getAllAssignTask",
};

//Applications
export const timesheetEndPoints = {
  POST_TIMESHEET_DETAILS: BASE_URL + "/timesheets/createTimesheet",
  GET_ALL_TIMESHEETS: BASE_URL + "/timesheets/getAllTimesheets",
  POST_UPDATE_TIMESHEET: BASE_URL + "/timesheets/updateTimesheet",
};

//Applications
export const projectuserroleEndPoints = {
  GET_ALL_PROJECT_USER_ROLE_DATA:
    BASE_URL + "/project-user-role/getAllProjectUserRole",
};
