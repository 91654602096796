import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import OpenLayout from "./components/layout/OpenLayout";
import Error from "./pages/Error";
import PrivateRoute from "./components/layout/PrivateRoute";
import Layout from "./components/layout/Layout";
import Dashboard from "./pages/Dashboard";
import "./App.css";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import DetailBlog from "./pages/DetailBlog";

import Rpa from "./pages/technologies/Rpa";
import Cyber from "./pages/technologies/Cyber";
import DataAnalytics from "./pages/technologies/DataAnalytics";
import Applicationdev from "./pages/technologies/Applicationdev";
import CloudComputing from "./pages/technologies/CloudComputing";
import SystemIntegration from "./pages/technologies/SystemIntegration";
import Blogs from "./pages/Blogs";
import About from "./pages/About";
import axios from "axios";
import ScrollToTop from "./components/common/ScrollToTop";
import Login from "./pages/Login";
import MasterData from "./pages/MasterData";
import Applicants from "./pages/Applicants";
import Feedback from "./pages/Feedback";
import InternshipApplication from "./pages/InternshipApplication";
import AuthHandler from "./AzureAuth/auth";
import ProfileAD from "./AzureAuth/Profile";
import AddBlog from "./pages/AddBlog";
import AdvisoryBoard from "./pages/OurTeam";
import AdvisoryVinod from "./components/leaders/AdvisoryVinod";
import Advisory from "./components/leaders/Advisory";
import AdvisorySathiyavel from "./components/leaders/AdvisorySathiyel";
import AdvisoryNikhil from "./components/leaders/AdvisoryNikhil";
import AddJobOpening from "./pages/AddJobOpening";
import JobApplication from "./pages/JobApplications";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Banking from "./pages/Domains/Banking";
import HealthCare from "./pages/Domains/HealthCare";
import Insurance from "./pages/Domains/Insurance";
import Manufacturing from "./pages/Domains/Manufacturing";
import SupplyChain from "./pages/Domains/SupplyChain";
import Telecom from "./pages/Domains/Telecom";

import AssetsAllocation from "./components/assetsMaster/AssetsAllocation.jsx";
import AssetsDeallocation from "./components/assetsMaster/AssetsDeallocation.jsx";
import AssetsAcceptance from "./components/assetsMaster/AssetsAcceptance.jsx";
import AssetsReturn from "./components/assetsMaster/AssetsReturn/AssetsReturn.jsx";
import EmployeeDashboard from "./components/employeeMaster/EmployeeDashboard.jsx";
import DeviceDashboard from "./components/assetsMaster/DeviceDashboard.jsx";
import WorkFromHome from "./pages/WorkFromHome";
import WFHApplication from "./pages/WFHApplication";
import ProjectDashboard from "./components/projectMaster/ProjectDashboard.jsx";
import ProcessDashboard from "./components/processMaster/ProcessDashboard.jsx";
import TechnologyDashboard from "./components/TechnologyMaster/TechnologyDashboard.jsx";
import ApplicationDashboard from "./components/ApplicationMaster/ApplicationDashboard.jsx";
import TransformationLeverDashboard from "./components/TransformationLeverMaster/TransformationLeverDashboard.jsx";
import Unauthorized from "./pages/Unauthorized.jsx";
import TaskDashboard from "./components/taskMaster/TaskDashboard.jsx";
import TaskAssignmentDashboard from "./components/taskAssignment/TaskAssignmentDashboard.jsx";
import TimesheetDashboard from "./components/timeSheetMaster/TimesheetDashboard.jsx";

import NoticePeriodPolicy from "./pages/policies/NoticePeriodPolicy.jsx";
import LeavePolicy from "./pages/policies/LeavePolicy.jsx";
import CodeOfConduct from "./pages/policies/CodeOfConduct.jsx";
import PoshPolicy from "./pages/policies/PoshPolicy.jsx";

const App = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  return (
    <div>
      <ScrollToTop />

      <Routes>
        {/* Open Routes */}
        <Route path="/" element={<OpenLayout />}>
          <Route index element={<Home />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogdetail/:url_key" element={<DetailBlog />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/login" element={<AuthHandler />} /> */}
          <Route path="/login" element={<Login />} />

          <Route path="/profile" element={<ProfileAD />} />
          <Route path="/advisory-board" element={<AdvisoryBoard />} />
          <Route path="/advisory-board/:name" element={<Advisory />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />

          {/* technologies */}
          <Route
            path="/technology/robotic-process-automation"
            element={<Rpa />}
          />
          <Route path="/technology/cyber-security" element={<Cyber />} />
          <Route
            path="/technology/data-analytics"
            element={<DataAnalytics />}
          />
          <Route
            path="/technology/application-development"
            element={<Applicationdev />}
          />
          <Route
            path="/technology/cloud-computing"
            element={<CloudComputing />}
          />
          <Route
            path="/technology/system-integration"
            element={<SystemIntegration />}
          />
          {/* Domain */}
          <Route path="/domain/banking" element={<Banking />} />
          <Route path="/domain/health-care" element={<HealthCare />} />
          <Route path="/domain/insurance" element={<Insurance />} />
          <Route path="/domain/manufacturing" element={<Manufacturing />} />
          <Route path="/domain/supply-chain" element={<SupplyChain />} />
          <Route path="/domain/telecom" element={<Telecom />} />
          <Route path="/advisory-board/advisory" element={<Advisory />} />
        </Route>

        {/* <Route path="/login" element={<Login />} /> */}

        {/* Private Route - for Only Logged in User */}
        <Route
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path="admin/unauthorized" element={<Unauthorized />} />
          <Route path="admin/dashboard" element={<Dashboard />} />
          <Route path="admin/recruiters/applicants" element={<Applicants />} />
          <Route path="admin/feedback" element={<Feedback />} />
          <Route
            path="admin/recruiters/internshipApplications"
            element={<InternshipApplication />}
          />
          <Route
            path="admin/recruiters/jobApplications"
            element={<JobApplication />}
          />
          <Route path="admin/blogs" element={<AddBlog />} />
          <Route path="admin/addJob" element={<AddJobOpening />} />

          <Route
            path="admin/asset-master/assets"
            element={<DeviceDashboard />}
          />
          <Route
            path="admin/asset-master/asset-allocation"
            element={<AssetsAllocation />}
          />
          <Route
            path="admin/asset-master/asset-acceptance"
            element={<AssetsAcceptance />}
          />
          <Route
            path="admin/asset-master/asset-return"
            element={<AssetsReturn />}
          />
          <Route
            path="admin/asset-master/asset-deallocation"
            element={<AssetsDeallocation />}
          />
          <Route path="admin/employees" element={<EmployeeDashboard />} />
          <Route path="admin/work-from-home" element={<WorkFromHome />} />
          <Route path="admin/wfh/applications" element={<WFHApplication />} />

          <Route path="admin/projects" element={<ProjectDashboard />} />
          <Route
            path="admin/task-tracker/processes"
            element={<ProcessDashboard />}
          />

          <Route path="admin/technologies" element={<TechnologyDashboard />} />
          <Route
            path="admin/task-tracker/projects"
            element={<ProjectDashboard />}
          />
          <Route
            path="admin/task-tracker/processes"
            element={<ProcessDashboard />}
          />

          <Route
            path="admin/task-tracker/technologies"
            element={<TechnologyDashboard />}
          />
          <Route
            path="admin/task-tracker/tranformation-lever"
            element={<TransformationLeverDashboard />}
          />
          <Route
            path="admin/task-tracker/application"
            element={<ApplicationDashboard />}
          />
          <Route path="admin/task-tracker/tasks" element={<TaskDashboard />} />

          <Route path="admin/tasks" element={<TaskDashboard />} />
          <Route
            path="admin/task-tracker/task-assignment"
            element={<TaskAssignmentDashboard />}
          />
          <Route
            path="admin/task-tracker/timesheet"
            element={<TimesheetDashboard />}
          />

          <Route
            path="admin/policies/notice-period-policy"
            element={<NoticePeriodPolicy />}
          />
          <Route path="admin/policies/leave-policy" element={<LeavePolicy />} />

          <Route
            path="admin/policies/code-of-conduct-policy"
            element={<CodeOfConduct />}
          />
          <Route path="admin/policies/posh-policy" element={<PoshPolicy />} />
        </Route>

        {/* 404 Page */}
        <Route path="/404" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
};

export default App;
