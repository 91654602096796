import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import BlogComponent from "../components/blog/BlogComponent";
import Loader from "../components/common/Spinner";
import banner from '../assets/blogs/blog-page-banner.png'
import Banner from "../components/common/Banner";
import { Helmet } from 'react-helmet-async';

export default function DetailBlog() {

    const url_key = useParams();
    // 
    const [data, setData] = useState({})
    const [blogList, setBlogList] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // 
        axios.get(`${process.env.REACT_APP_BASE_URL}/blog/getDetailBlog/${url_key.url_key}`)
            .then(res => {
                // 
                setData(res.data.data[0])
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        setLoading(true)
        if (data) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/blog/getRelatedBlogs?category=${data.category}`)
                .then(res => {
                    // 
                    setBlogList(res.data.data.filter(blog => blog.id !== data.id))
                    setLoading(false)
                })
        }
    }, [data])


    return (
        <>
            <Helmet>
                <title>{`Mittal Software Labs | ${data.title}`}</title>
                <meta property="og:url" content={`https://www.mittalsoftwarelabs.com/blogdetail/${url_key.url_key}`} data-react-helmet="true"/>
                <meta property="og:type" content="website" data-react-helmet="true"/>
                <meta property="og:image" content={`https://www.mittalsoftwarelabs.com/msl/apis/blogImage/${data.image}`} data-react-helmet="true"/>
                <meta property="og:title" content={data.title} data-react-helmet="true"/>
                <meta property="og:description" content={data.description} data-react-helmet="true"/>

                <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
                <meta property="twitter:domain" content="https://www.mittalsoftwarelabs.com" data-react-helmet="true"/>
                <meta property="twitter:url" content={`https://www.mittalsoftwarelabs.com/blogdetail/${url_key.url_key}`} data-react-helmet="true"/>
                <meta property="twitter:image" content={`https://www.mittalsoftwarelabs.com/msl/apis/blogImage/${data.image}`} data-react-helmet="true"/>
                <meta property="twitter:title" content={data.title} data-react-helmet="true"/>
                <meta property="twitter:description" content={data.description} data-react-helmet="true"/>

            </Helmet>

            <Banner
                title='Blogs'
                description='Explore, Evolve, Engage'
                banner={banner}
            />

            <div className="w-11/12 mx-auto py-6 px-5">
                <div className="flex justify-between">
                    <div className={`${blogList.length > 0 ? 'md:w-[65%]' : 'w-full'} py-8`}>
                        <BlogComponent data={data} />
                    </div>
                    {
                        blogList.length > 0 && <div className="w-[25vw] py-8 hidden md:block">
                            <div className="mb-10 flex gap-4 flex-col">
                                <h2 className="font-bold text-3xl">Recent Post</h2>
                                {
                                    blogList.length > 0 ?
                                        blogList.slice(0, 3).map(blog =>
                                            <Link to={`/blogdetail/${blog.url_key}`} className="flex w-full mb-3 cursor-pointer hover:scale-105">
                                                <img src={`${process.env.REACT_APP_BASE_URL}/blogImage/${blog.image}`} width={120} height={120} className=' object-cover' alt={blog.image} />
                                                <div className="font-bold text-xl w-full px-3 py-4 flex items-center mb-0">{blog.title.length > 30 ? blog.title.slice(0, 20) + '...' : blog.title}
                                                </div>
                                            </Link>
                                        )
                                        :
                                        <div className="text-red-500 w-full text-center">No Recent Post Found</div>
                                }
                            </div>
                        </div>
                    }
                </div>
                {loading && <Loader />}
            </div>
        </>
    )
}